import { Outlet} from "react-router-dom";
import SubNav from "../components/dashboard/subnav";

const RequestLayout = ()=>{
  const links = [
    {
      id: 1,
      link: `/request`,
      label: "Demandes à traiter",
      isActive: false,
    },
    {
      id: 2,
      link: `/request/valid-requests`,
      label: "Demande traitées",
      isActive: false,
    },
  ]
  return (
    <div className="layout-dashboard-container">
    <SubNav
      links={links}
    />
    <Outlet />
  </div>
  )
}
export default RequestLayout;