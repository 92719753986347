import { Outlet} from "react-router-dom";
import SubNav from "../components/dashboard/subnav";

const WithDrawlayout = ()=>{
  const links = [
    {
      id: 1,
      link: `/dashboard/business-withdraw`,
      label: "Retraits à traiter",
      isActive: false,
    },
    {
      id: 2,
      link: `/dashboard/valid-business-withdraw`,
      label: "Retraits traités",
      isActive: false,
    },
  ]
  return (
    <div className="layout-dashboard-container">
    <SubNav
      links={links}
    />
    <Outlet />
  </div>
  )
}
export default WithDrawlayout;