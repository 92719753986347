import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { setTitle } from "../redux/store";

import FormSectionTitle from "../components/formSectionTitle";
import InputSelect from "../components/inputSelect";

import IconPlus from "../components/icons/icon-plus";

import IconArrowColored from "../assets/svg/navbar-arrow-colored";

import apiPath from "../constants/apiPaths";

class ListUsers extends Component {

  constructor(props) {
    super(props);
    this.state = {
      token: props.token,
      user: props.user,
      users: [],
      filtredUser: [],
      action: "listUser",
      filters: [
        { id: 0, label: "Tous les utilisateurs" },
        { id: 5, label: "Validés" },
        { id: 3, label: "Apporteurs d'affaires" },
        {id:6, label: "Commerciaux"},
        { id: 4, label: "Investisseurs" },
        { id: 1, label: "KYC rejetés" },
        { id: 2, label: "KYC en attente" },
      ],
      value: 0,
      // currentIndex:0,
      // userColors: {}
    };
    this.colors = ['red', 'green', 'blue', 'purple', 'orange', 'pink', 'cyan', 'magenta', 'teal', 'lime', 'olive', 'maroon', 'navy', 'brown', 'black', 'gray', 'silver', 'gold', 'tan', 'beige', 'salmon', 'plum', 'peach'];
    console.log(this.colors)
    this.currentIndex=0
    console.log(this.currentIndex)
  }

  componentDidMount() {
    const token = this.state.token;
    const headers = { headers: { "x-auth-token": token } };
    try {
      axios.get(`${apiPath}users/`, headers).then((res) => {
        this.setState({
          users: res.data,
          filtredUser: res.data,
        });
      });
    } catch (err) {
      console.log(err);
    }
  }

  handleFilterChange = (e) => {
    const value = e.target.value;
    let filtredUser = [];

    if (value === "0") filtredUser = this.state.users;
    if (value === "1")
      filtredUser = this.state.users.filter(
        (user) => user.status_kyx === "RED"
      );
    if (value === "2")
      filtredUser = this.state.users.filter((user) => !user.has_send_kyx);
    if (value === "3")
      filtredUser = this.state.users.filter((user) =>
        user.role.includes("apporteur-affaires")
      );
    if (value === "4")
      filtredUser = this.state.users.filter((user) =>
        user.role.includes("investisseur")
      );
      if (value === "6")
      filtredUser = this.state.users.filter((user) =>
        user.role.includes("commercial")
      );
    if (value === "5")
      filtredUser = this.state.users.filter(
        (user) =>
          user.has_send_kyx &&
          user.status_kyx === "GREEN" &&
          user.hasDoneQuiz &&
          user.has_valided_email
      );

    this.setState({ filtredUser, value });
  };

  checkFiscality = ({ doc, fiscalCountry }) => {
    const hasFiscalCountry = fiscalCountry
      ? fiscalCountry.Country !== ""
        ? true
        : false
      : false;
    let hasFiscalDoc = false;

    if (!doc) return hasFiscalCountry;

    doc.forEach((d) => {
      if (d.docType === "fiscale") hasFiscalDoc = true;
    });
    // console.log(hasFiscalCountry,hasFiscalCountry)
    return hasFiscalDoc || hasFiscalCountry;
  };
  //! fonction de rabah pour généré des couleurs aléatoires
  // getRandomColor = () => {
  //   const codes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, "A", "B", "C", "D", "E", "F"];
  //   let hexaCode = "";
  //   for (let i = 0; i < 6; i++)
  //     hexaCode += codes[Math.floor(Math.random() * codes.length)];
  //   return `#${hexaCode}`;
  // };
 
  getNextColor() {
    const nextColor = this.colors[this.currentIndex];
    this.currentIndex = (this.currentIndex + 1) % this.colors.length;
    return nextColor;
  }
  // renderColors = (user) => {
  //   if (!this.state.userColors[user.id]) {
  //     this.setState((prevState) => {
  //       let currentIndex = prevState.currentIndex
  //       return {
  //         userColors: { ...prevState.userColors, [user.id]: this.colors[currentIndex] },
  //         currentIndex: (currentIndex + 1) % this.colors.length
  //       };
  //     });
  //   }
    // return this.state.userColors[user.id];
  // }
  getTitile = (id) => {
    const filter = this.state.filters.find((f) => f.id === parseInt(id));
    if (filter && filter.label) return filter.label;
    return "Filtrés :";
  };

  render() {
    return (
      <div className="_listUsersContainer">
        <div className="_header">
          <div className="-input-wrapper">
            <p>Filtrer par :</p>
            <InputSelect
              classes="select-style-2"
              value={this.state.value}
              handleSelectChange={this.handleFilterChange}
              required={false}
              inputs={this.state.filters}
            />
          </div>

          <Link
            className="-button-wrapper"
            to="/dashboard/createUser"
            onClick={() => this.props.setTitle("Ajouter un utilisateur")}
          >
            <div className="-icon">
              <IconPlus />
            </div>
            <button>Ajouter un utilisateur</button>
          </Link>
          <Link
            className="-button-wrapper"
            to="/dashboard/add-doc"
            onClick={() => this.props.setTitle("Ajouter un document")}
          >
            <div className="-icon">
              <IconPlus />
            </div>
            <button>Ajouter un document</button>
          </Link>

          {/* <Link
            className="-button-wrapper"
            to="#"
            onClick={() => this.props.setTitle("verser une prime")}
          >
            <div className="-icon">
              <IconPlus />
            </div>
            <button>verser une prime</button>
          </Link> */}
        </div> 

        {this.state.filtredUser && this.state.filtredUser.length > 0 ? (
          <div className="_listUsers">
            <FormSectionTitle
              title={this.getTitile(this.state.value)}
              classes="-simple-2 _users_title"
            />

            <div className="_users_container">
              <div className="_users_header">
                <span></span>
                <span>NOM</span>
                <span>PRÉNOM</span>
                <span>TÉLÉPHONE</span>
                <span>EMAIL</span>
                <span>TYPE</span>
                <span>RÔLE</span>
                <span></span>
              </div>

              <h4 className="_users_mb_title">
                {this.getTitile(this.state.value)}
              </h4>

              <ul className="_users_list">
                {this.state.filtredUser.map((user, id) => {
                  // const color = this.renderColors(user)
                  console.log(this.currentIndex)
                  return (
                    
                    <li key={id} className="_user">
                      <div className="_picto">
                        <Link
                          to={`/dashboard/userEdit/personalInfos/${user._id}`}
                          // style={{ backgroundColor: color }}
                          style={{ backgroundColor: this.getNextColor() }}
                          onClick={() =>
                            this.props.setTitle(
                              `Utilisateur : ${user.lastname} ${user.firstname}`
                            )
                          }
                        >
                          {/* //<img src={apiPath + "users/avatar/" + user.avatar} alt="user profile image" /> Si l'utilisateur à une photo stocké sur le serveur de visio */}
                          {user.avatar ? (
                            <img
                              src={user.avatar}
                              alt={`${user.firstname} ${user.lastname} avatar`}
                            /> // Si l'image provien d'un site externe
                          ) : (
                            `${user.lastname !=null ?
                              user.lastname
                              .charAt(0)
                              .toUpperCase():"-"}${user.firstname !=null ?
                              user.firstname
                              .charAt(0)
                              .toUpperCase():"-"}`
                          )}
                        </Link>
                      </div>
                      <div data-label="Nom :">{user.lastname !=null? user.lastname:"-"}</div>
                      <div data-label="Prénom :">{user.firstname !=null?user.firstname :"-"}</div>
                      <div data-label="Numéro :">{user.phone}</div>
                      <div data-label="Email :" className="_email">
                        {user.email}
                      </div>
                      <div className="_mb_hidden">
                        {user.type === "Particulier"
                          ? "Part"
                          : user.type === "Entreprise"
                          ? "Pro"
                          : ""}
                      </div>
                      <div className="_mb_hidden">Utilisateur</div>
                      <Link
                        className="_icon"
                        to={`/dashboard/userEdit/personalInfos/${user._id}`}
                        onClick={() =>
                          this.props.setTitle(
                            `Utilisateur : ${user.lastname} ${user.firstname}`
                          )
                        }
                      >
                        <IconArrowColored />
                        <p>Voir la fiche</p>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        ) : (
          <p>Aucun utilisateur trouvé</p>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  token: state.persistedReducer.user.token,
});

const actions = {
  setTitle,
};

export default connect(mapStateToProps, actions)(ListUsers);
