const InputNumber = (props) => {

    const {
      classes,
      value,
      label,
      type,
      indication,
      handleInputChange,
      error,
      min,
      max
    } = props;
  
  
  
    const handleChange = (event) => {
  
      const value = event.target.value;
  
      if ( max && value.length > max ) return;
      if ( type && type === "phone" &&  !isPhoneNumber(event) ) return;
      handleInputChange(event)
  
    }
  
    const isPhoneNumber = (event) => {
      const value = event.target.value;
      const intredChar = event.nativeEvent.data
      if (value.length <= 0 ) return true;
      else if (intredChar === "+" && value.replace(/[^+]/g, "").length > 1) return false;
      else if (intredChar !== "+") return Number.isInteger(parseInt(intredChar));
      return true;
    }
  
    const required = props.required === false ? false : true;
  
    return (
      <label className={`input ${classes ? classes : ""} ${error ? "-error" : "" }`}>
        { required ? <span className="-required">{label}</span> : <span>{label}</span> }
        <input
          value={value ? value : ""}
          type="number"
          min = {min ? min : ""}
          max = {max ? max : ""}
          onChange={handleChange}
          autoComplete="on"
        />
        {indication && <p className={indication ? "-indication" : "" }>{indication}</p>}
        {error && <p className={error ? "-error" : ""}>{error}</p>}
      </label>
    )
  }
  
  export default InputNumber;