export default () => (
  <svg width="16px" height="20px" viewBox="0 0 16 20" >
  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1510.000000, -328.000000)" fill="#0062FF">
          <g transform="translate(280.000000, 308.000000)">
              <g  transform="translate(1230.000000, 20.000000)">
                  <path d="M10.1481731,0 C10.7514084,0 11.3224183,0.272272845 11.7021601,0.740982758 L15.553987,5.49523764 C15.8425433,5.85139856 16,6.29587149 16,6.75425488 L15.9998461,7.37493245 C15.9999486,7.3832761 16,7.39163205 16,7.4 L16,16 C16,18.209139 14.209139,20 12,20 L4,20 C1.790861,20 0,18.209139 0,16 L0,4 C0,1.790861 1.790861,0 4,0 L10.1481731,0 Z M12.902,5.4 L11.625,3.823 L11.625,5.4 L12.902,5.4 Z M4,2 C2.8954305,2 2,2.8954305 2,4 L2,16 C2,17.1045695 2.8954305,18 4,18 L12,18 C13.1045695,18 14,17.1045695 14,16 L14,7.4 L11.625,7.4 C10.5204305,7.4 9.625,6.5045695 9.625,5.4 L9.625,2 L4,2 Z M9,12.5 L9.4,12.2 C9.8418278,11.8686292 10.4686292,11.9581722 10.8,12.4 C11.1313708,12.8418278 11.0418278,13.4686292 10.6,13.8 L8.60808101,15.2939392 C8.43958638,15.4231842 8.22876383,15.5 8,15.5 C7.77123617,15.5 7.56041362,15.4231842 7.39191899,15.2939392 L5.4,13.8 C4.9581722,13.4686292 4.86862915,12.8418278 5.2,12.4 C5.53137085,11.9581722 6.1581722,11.8686292 6.6,12.2 L7,12.5 L7,8.61139444 C7,8.05910969 7.44771525,7.61139444 8,7.61139444 C8.55228475,7.61139444 9,8.05910969 9,8.61139444 L9,12.5 Z" id="Shape"></path>
              </g>
          </g>
      </g>
  </g>
</svg>
)