import IconInfosSucces from "./icons/icon-infos-succes";
import IconInfosInfo from "./icons/icon-infos-info";
import IconInfosError from "./icons/icon-infos-error";

const InfosBar = ({content, classes, style}) => {


  return (
    <div style={style ? style : {}} className={`infos-bar ${classes ? classes : ""}`}>
      <div className="-icon">
        <i>{getIcon(classes)}</i>
      </div>
      {getContent(content)}
    </div>
  )
}

const getIcon = (classes) => {
  if ( classes && classes.includes("-info") ) return <IconInfosInfo/>
  else if ( classes && classes.includes("-error") ) return <IconInfosError/>
  return <IconInfosSucces />
}

const getContent = (content) => {
  if ( Array.isArray(content) ) return <ul className="-liste">
      {content.map((message, index) => <li key={index}>{message}</li>)}
    </ul>
  else return <p className="-text">{content}</p>
}

// const getContent = (content, type) => {
//   if (type === "liste" )  return <ul className="-liste">
//     {content.map((message, index) => <li key={index}>{message}</li>)}
//   </ul>
//   else return <p className="-text">{content}</p>
// }

export default InfosBar;