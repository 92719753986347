import FormSectionTitle from "../../components/formSectionTitle"
import { useEffect, useState } from "react"
import apiPath from '../../constants/apiPaths';
import { useSelector} from "react-redux";
import axios from "axios";

import { Link } from 'react-router-dom';
import IconArrowColored from '../../assets/svg/navbar-arrow-colored';
import { roundNumber } from "../../helpers/roundNumberFunc";
const BusinessWithDrawView = ()=>{

  const { user} = useSelector(state => state.persistedReducer);
  const headers = {
    headers: {
      "x-auth-token" : user.token,
    }
  }
  useEffect(()=>{
   
    axios.get(`${apiPath}withdraw/`,headers)
    .then((res)=>{
      console.log(res.data)
      setWithDraw(res.data)
      axios.get(`${apiPath}users/`, headers)
        .then((res) => {
          console.log(res.data)
          setUsers(res.data)
        })
        .catch((err)=>{
         console.log(err)
       })
        
   })
    .catch((err)=>{
      console.log(err)
    })
  },[])

  const [withDraw, setWithDraw] = useState([])
  const [users, setUsers] = useState([])
return(
  <div className="_listUsersContainer">
    <div className="_listUsers">
      <FormSectionTitle
              title="liste des retraits à valider"
              classes="-simple-2 _users_title"
            />
    
    <div className="_users_container ">

          <div className=" -invest">
                  <span>UTILISATEUR</span>
                  <span>MONTANT</span>
                  <span>COMPTE DE RETRAIT</span>
                  <span>DATE</span>
                  <span>STATUS</span>
            </div>
             
      <ul className="_users_list">
      { withDraw && withDraw.map(withdraw=>{
       
          return (
           
            <>
             {withdraw.status ==="open"    &&
             
             <li key={withdraw.id} className=" -investement">
              
              <div data-label="UTILISATEUR :">
              {users.map((user)=>user._id === withdraw.user ? user.firstname + " " + user.lastname :"") }
              </div>
              <div data-label="MONTANT :">{roundNumber(withdraw.amount).toLocaleString('en-EU').replaceAll(',', ' ')}€</div>
              <div data-label="COMPTE DE RETRAIT :">{withdraw.compte}</div>
              <div data-label="DATE :">{new Date (withdraw.date).toLocaleDateString()}</div>
              <div className={withdraw.status === "processed" ? "-succes": "-waiting"} data-label="STATUT :">
                {withdraw.status === "processed" ? "validé": "à traiter"}
              </div>
              <Link className="_icon"
                to={`/dashboard/business-withdraw/${withdraw._id}`}
               >
                <IconArrowColored/>
                <p>Voir la fiche</p>
              </Link>

              </li>}
              
              </>
            
          ) 
        })
      }
      </ul>
      </div>
    </div>
  </div>
)
}
export default BusinessWithDrawView