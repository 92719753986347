import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';
import userSlice from "./user";
import userToEditSlice from "./userToEdit";
import paramsSlice from "./params";

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, combineReducers({
  user: userSlice.reducer,
}))

const notPersistedReducer = combineReducers({
  userToEdit: userToEditSlice.reducer,
  params: paramsSlice.reducer,
})

// Action from user slice
const {
  setUser,
  setToken,
  setHasGoogleAuth,
  editFieldProfile,
  addContactProfile,
  editContactProfile,
  deleteContactProfile,
} = userSlice.actions;

// Action from userToEdit Slice :
const {
  setUserToEdit
} = userToEditSlice.actions;

// Actions from Params
const {
  setTitle,
  setFeedback
} = paramsSlice.actions;

export {

  // User actions
  setUser,
  setToken,
  setHasGoogleAuth,
  editFieldProfile,
  addContactProfile,
  editContactProfile,
  deleteContactProfile,

  // UserToEdit actions
  setUserToEdit,

  // Params actions
  setTitle,
  setFeedback,
};

export const store = configureStore({
  reducer: { 
    persistedReducer,
    notPersistedReducer,
  },
});
