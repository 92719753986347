import { Component } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import InputText from "../../components/inputText";
import InfosBar from "../../components/infosBar";


import IconFileDownload from "../../components/icons/icon-file-download";

import apiPath from "../../constants/apiPaths";

class EditUserBanqueView extends Component{
  constructor(props){
    super(props)
    this.state = {
      token : props.token,
      ribInv : {},
      ribApp: {},
      docRibInv : {},
      docRibApp : {}
      
    }
  }

   getDoc = (name)=>{
    const { id } = this.props.params;

    if (name){
          axios({
            url: `${apiPath}users/doc/${id}/${name}`,
            method: 'GET',
            responseType: 'blob',
            headers: {
              "x-auth-token": this.props.token
            }
          })
          .then((res)=>{
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name); //or any other extension
            document.body.appendChild(link);
            link.click();
          })
          .catch((err)=>{
            console.log(err)
          })
        }
    }
    

  componentDidMount(){

    const { id } = this.props.params;
    const headers = {headers: {"x-auth-token": this.props.token}};

    axios.get(`${apiPath}users/${id}`, headers)
    .then(res => {
      const userDocRibGeneral =  res.data.doc.find(x=>x.docType ==="RIB compte général") ? res.data.doc.find(x=>x.docType ==="RIB compte général") : ""
      const userDocRibApportAffaire =  res.data.doc.find(x=>x.docType ==="RIB compte business") ? res.data.doc.find(x=>x.docType ==="RIB compte business") : ""
      const ribI = res.data.ribInvestissment
      const ribA = res.data.ribApportAffaire
      // console.log(ribI)
      // console.log(ribA)
      // console.log(userDocRibGeneral)
      // console.log(userDocRibApportAffaire)
      this.setState({
        ribInv : ribI ,
        ribApp : ribA,
        docRibInv :userDocRibGeneral ,
        docRibApp : userDocRibApportAffaire
      })


    })
    .catch(err => console.err(err));

  }

  render(){
    return(
      <>
          <div className="title -huge">
            <h3>Les différents Rib de l'utilisateur</h3>
          </div>

      { Object.keys(this.state.ribInv).length === 3 ?
        <div className="add-RIB">
      <div className="RIB-container">
        <div className="RIB-dashboard-container">
          <div className="RIB-dashboard-scontainer">
            <div className="RIB-section-title">
                <h4 className="RIB-texte">RIB compte général</h4>
            </div>

            <div className="spacing">
            <InputText
                label="IBAN compte général "
                name="IBAN"
                value ={this.state.ribInv.iban}
                placeholder="IBAN"
                />
            </div>
            <div className="spacing ">
            <InputText
                label="BIC compte général "
                name="BIC"
                value={this.state.ribInv.bic}
                placeholder="BIC"
                />
            </div>

            <div className="spacing-rib " >     
              <p className='rib-dwl'> Télécharger le {this.state.docRibInv.docType} </p>
            <a href='#' onClick={()=>this.getDoc(this.state.docRibInv.name)} ><IconFileDownload/> </a>  
            </div>
            </div>        
        </div> 
      </div>
    </div>
    
          : 
          
          <div className="information-container -apporAf">
             <div className="-title">
                  <h4 className="-texte">Informations préalables</h4>
            </div>
            <div className="-information">        
                  <InfosBar
                  classes="-info"
                  content = {"L'utilisateur n'a pas encore saisi de RIB pour le compte général"}
                  />
            </div>
        </div>

          
              
      }

{ Object.keys(this.state.ribApp).length === 3 ?
        <div className="add-RIB">
      <div className="RIB-container">
        <div className="RIB-dashboard-container">
          <div className="RIB-dashboard-scontainer">
            <div className="RIB-section-title">
                <h4 className="RIB-texte">RIB compte business</h4>
            </div>

            <div className="spacing">
            <InputText
                label="IBAN compte business "
                name="IBAN"
                value ={this.state.ribApp.iban}
                placeholder="IBAN"
                />
            </div>
            <div className="spacing ">
            <InputText
                label="BIC compte business "
                name="BIC"
                value={this.state.ribApp.bic}
                placeholder="BIC"
                />
            </div>

            <div className="spacing-rib " >     
              <p className='rib-dwl'> Télécharger le {this.state.docRibApp.docType} </p>
            <a href='#' onClick={()=>this.getDoc(this.state.docRibApp.name)} ><IconFileDownload/> </a>  
            </div>
            </div>        
        </div> 
      </div>
    </div>
    
          :
          <div className="information-container -apporAf">
             
            <div className="-information">        
                  <InfosBar
                  classes="-info"
                  content = {"L'utilisateur n'a pas encore saisi de RIB pour le compte business"}
                  />
            </div>
        </div>
          
              
      }
        

        

    </>
  )}
}

// Utilisé pour accéder aux hooks de react-rooter-v6 dans un composant type classe
export default (props) => {
  const params = useParams();
  const { token } = useSelector(state => state.persistedReducer.user);
  return <EditUserBanqueView params={params} token={token} {...props} />
}