import { useState } from "react";
import IconPasswordHidden from "./icons/icon-password-hidden";

export default (props) => {

  const [isPassword, setIsPassword] = useState(true);

  const {
    classes,
    value,
    label,
    indication,
    handleInputChange,
    error,
  } = props;

  const required = props.required === false ? false : true;

  return (
    <label className={`input password ${classes ? classes : ""} ${error ? "-error" : "" }`}>
      { required ? <span className="-required">{label}</span> : <span>{label}</span> }
      <div className="-container">
        <input
          value={value}
          type={isPassword ? "password" : "text"}
          onChange={event => handleInputChange(event)}
          autoComplete="current-password"
        />
        <i onClick={() => setIsPassword(!isPassword)} >{<IconPasswordHidden />}</i>
      </div>
      {indication && <p className={indication ? "-indication" : "" }>{indication}</p>}
      {error && <p className={error ? "-error" : ""}>{error}</p>}
    </label>
  )
}