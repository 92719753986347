import { Outlet} from "react-router-dom";
import SubNav from "../components/dashboard/subnav";

const RealEstateLayout = () => {

 const links = [
    {
      id: 1,
      link: `/dashboard/capitalisation`,
      label: "La levée Capitalisation",
      isActive: false,
    },
    {
      id: 2,
      link: `/dashboard/capital-wait-invests`,
      label: "investissements à valider",
      isActive: false,
    },
    {
      id: 3, 
      link: `/dashboard/capital-valid-invests`,
      label: "Investissements validés",
      isActive: false,
    },
  ];

  return (
    <div className="layout-dashboard-container">
      <SubNav
        links={links}
      />
      <Outlet />
    </div>
  )

}

export default RealEstateLayout;