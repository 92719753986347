const regexes = {
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  password: /^.{3,}$/,
  code2af: /^[0-9]{6}$/,
  default: /^.{}$/,
  defaults: /^.{2,}$/,
  day: /^[0-9]{1,2}$/,
  month: /^[0-9]{1,2}$/,
  year: /^[0-9]{4}$/,
  phone: /^\+?[0-9]{9,15}$/,
  postalCode: /^[a-zA-Z0-9-]{}$/,
  postalCodes: /^[a-zA-Z0-9-]{2,10}$/,
  siret: /^[a-zA-Z0-9-]{}$/,

  pseudo: /^(\w{4,20})$/,}
  


export default regexes;