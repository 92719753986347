import { Outlet} from "react-router-dom";
import SubNav from "../components/dashboard/subnav";

const RealEstateLayout = () => {

 const links = [
    {
      id: 1,
      link: `/dashboard/real-estate`,
      label: "La levée Real Estate",
      isActive: false,
    },
    {
      id: 2,
      link: `/dashboard/real-wait-invests`,
      label: "investissements à valider",
      isActive: false,
    },
    {
      id: 3, 
      link: `/dashboard/real-valid-invests`,
      label: "Investissements validés",
      isActive: false,
    },
    
  ];

  return (
    <div className="layout-dashboard-container">
      <SubNav
        links={links}
        // title={}
      />
      <Outlet />
    </div>
  )

}

export default RealEstateLayout;