import { Component } from "react";
import axios from "axios";

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setTitle} from '../../redux/store';

import apiPath from "../../constants/apiPaths";


import FormSectionTitle from "../../components/formSectionTitle";


import IconFileDownload from "../../components/icons/icon-file-download";
import { roundNumber } from "../../helpers/roundNumberFunc";

class EditUserDocumentsView extends Component{
  constructor(props){
    super(props)
    this.state = {
      token : props.token,
      documents : [],
    }
  }



  componentDidMount(){

    const { id } = this.props.params;
    const headers = {headers: {"x-auth-token": this.props.token}};

   
   

    axios.get(`${apiPath}users/${id}`, headers)
    .then(res => {
      const user = res.data;
      const docs = user.doc;
      
      this.setState({
       documents : docs.sort((a,b)=>(
        new Date(b.date) - new Date(a.date) 
     ))
      })
    })
    .catch(err => console.err(err));

  }

  getDoc = (name)=>{
    const { id } = this.props.params;
   
    if (name){
      // console.log("heyyy")
      axios({
        url: `${apiPath}users/doc/${id}/${name}`,
        method: 'GET',
        responseType: 'blob',
        headers: {
          "x-auth-token": this.props.token
        }
      })
      .then((res)=>{
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err)=>{
        console.log(err)
      })
    }
  }

  render(){
    const documents = this.state.documents
    return(
      <div className="_listUsersDocumentsContainer">
        <div className="_listUsersDocuments">
        <FormSectionTitle
            title="Documents"
            classes="-simple-2 _usersDocuments_title"
          />

        <div className="security-form-container">
          <div className="_usersDocuments_container ">
              <div className=" -invest -document">
                      <span>DATE</span>
                      <span>MONTANT</span>
                      <span>NATURE DU DOCUMENT</span>
                      {/* <span>DATE</span>
                      <span>STATUS</span> */}
                </div>

                <ul className="_usersDocuments_list">
                    {documents ? documents.map(doc=> {
                      return (
                        <li key={doc._id} className=" -investement -doc">
                          <div data-label ="DATE: " >{`${new Date (doc.date).toLocaleDateString()}` !=null ?
                                         `${new Date (doc.date).toLocaleDateString()}` : "waiting for approval ..." }</div>
                                  <div data-label ="MONTANT: " >{`${roundNumber(doc.montant)?roundNumber(doc.montant).toLocaleString("en-EU").replaceAll(",", " ")+"€" : "Pas de montant" }`} </div>
                                  <div data-label ="NATURE DU DOCUMENT: " >{doc.docType}</div>
                                  <div data-label ="" > 
                                  <a onClick={()=>this.getDoc(doc.name)}> <IconFileDownload/></a>
                                  </div>
                        </li>
                      )
                    }):"Aucun documents pour cet utilisateur"}   
                </ul>
            </div>
        </div>
        </div>
      </div>
    )
  }
}

// Utilisé pour accéder aux hooks de react-rooter-v6 dans un composant type classe
export default (props) => {
 
  const params = useParams();
  const { token } = useSelector(state => state.persistedReducer.user);
  return <EditUserDocumentsView params={params} token={token} {...props} />
}