import  {Component} from "react";
import axios from "axios";
import {connect} from 'react-redux';
import { Navigate, Outlet } from "react-router-dom";
// import LoadingTemplate from "../components/loadingTemplate";

import { setUser, setToken, setFeedback } from '../redux/store';

import Header from "../components/dashboard/header";
import Navbar from "../components/dashboard/navbar";
import FeedBack from "../components/feedBack";

import apiPath from "../constants/apiPaths";

class DashboardLayout extends Component {

  constructor (props){
    super (props)
    this.state = {
      headerTitle : "Dashboard",
      tokenTimedOut : false,
      tokenRefresh : false,
      isNavbarOpen : false,
    }
  }

  refreshToken = () =>{

    const headers = {
      headers:{"x-auth-token" :this.props.token}
    }
    axios.get(`${apiPath}auth/refresh`,headers)
      .then((res)=>{
        this.props.setToken(res.headers["x-auth-token"])
      })
  }

  componentDidMount(){
    // this.context.onSetInstantNotif(true,"error","test","test",[])
    // this.props.setFeedback({
    //   show:true,
    //   type:"error",
    //   title:"test",
    //   message:"test message",
    //   messages:[],
    // })

    const headers = {headers:{"x-auth-token":this.props.token}}
    if(this.props.user.token_exp_date < Date.now()) this.setState({tokenTimedOut : true})
    else {
      axios.get(`${apiPath}auth/refresh`,headers)
      .then((res)=>{
        this.props.setToken(res.headers["x-auth-token"])
        this.props.setUser(res.data)
      })
      .finally(setInterval(this.refreshToken, 25*60*1000))
    }
  }

  setTitle = (headerTitle)=> this.setState({headerTitle})

  handleOpenNavbar = () => this.setState({isNavbarOpen: true})
  
  handleCloseNavbar = () => this.setState({isNavbarOpen: false})

 render(){

  const { user } = this.props;
  const {feedback} = this.props.params
  
  return <>
  
    {this.state.tokenTimedOut && <Navigate to="/" replace={true}/>}
    {(this.props.user === false || this.props.token ===false) && <Navigate to="/" replace={true} />}
    {(this.props.user && !this.props.user.role.includes("Administrator")) && <Navigate to="/" replace={true} />}
    {apiPath===`https://stgapi.viziogroup.com/immo/` && <div className="ruban">STAGING</div>}
    <div className="container-big">

      {/* Navbar */}
      <Navbar 
        parentCallback={this.setTitle}
        handleCloseNavbar={this.handleCloseNavbar}
        isNavbarOpen={this.state.isNavbarOpen}
      />

      <div className="container-small">
      {/* {loader.isLoading &&
        <LoadingTemplate />
      } */}

        {/* Header */}
        <Header
          title={this.state.headerTitle}
          user={user}
          handleOpenNavbar={this.handleOpenNavbar}
        />

        {/* Childs */}
        <Outlet/>

      {feedback.show &&
        <FeedBack />
      } 
      </div>

    </div>

  </>

 }

}

const mapStateToProps = (state) => ({
  user: state.persistedReducer.user.user,
  token: state.persistedReducer.user.token,
  params: state.notPersistedReducer.params,
});

const actions = {
  setUser,
  setToken,
  setFeedback
}

export default connect(mapStateToProps, actions)(DashboardLayout);