// import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { useEffect, useState } from "react";

import AuthenticationView from "./views/authentification";

// import EditUserInfoPerso from "./views/editUser/EditUserInfoPersoView";
import EditUserInvestorProfile from "./views/editUser/EditUserInvestorProfileView";
import DashboardLayout from "./layout/DashboardLayout";
import EditUserLayout from "./layout/EditUserLayout";
import RealEstateLayout from "./layout/RealEstateLayout";
import CapitalisationLayout from "./layout/CapitalisationLayout"
import WithDrawlayout from "./layout/WithDrawLayout";
import RequestLayout from "./layout/RequestLayout";

import DashboardView from "./views/DashboardView";
import NoMatchView from "./views/NoMatchView";
import UsersListView from "./views/UsersListView";
import CreateUserView from "./views/CreateUser";

import EditUserInfoPersoView from "./views/editUser/EditUserInfoPersoView";
import EditUserSecurityView from "./views/editUser/EditUserSecurityView";
import EditUserFiscalityView from "./views/editUser/EditUserFiscalityView";
import EditUserDocumentsView from "./views/editUser/EditUserDocumentsView";
import EditUserTransactionsView from "./views/editUser/EditUserTransactionsView";
import EditUserBanqueView from "./views/editUser/EditUserBanqueView";

import InvestmentsListView from "./views/InvestmentsListView";

import ContactLayout from "./layout/ContactLayout"

import "./App.css";
import "./scss/style.scss";
import InvestementView from "./views/realEstate/InvestmentView";

import RealEstateView from "./views/realEstate/RealEstateView";
import WaitInvestView from "./views/realEstate/WaitInvestView";
import CapiWaitInvestView from "./views/capitalisation/CapiWaitInvestView";
import CapiInvestementView from "./views/capitalisation/CapiInvestementView";
import ValidInvestView from "./views/realEstate/ValidInvestView";
import AddHugeInvestor from "./views/AddHugeInvestor";
import CapitalisationView from "./views/capitalisation/CapitalisationView";
import CapiValidInvestView from "./views/capitalisation/CapiValidInvest";
import EditUserInvestmentView from "./views/editUser/EditUserInvestmentView";
import BusinessWithDrawView from "./views/retraits/BusinessWithDrawView";
import BusinessTreatedWithDrawView from "./views/retraits/BusinessTreatedWithDrawView";
import WithDrawValidationView from "./views/retraits/WithDrawValidationView";
import EditUserFilleulView from "./views/editUser/EditUserFilleulView";
import EditUserApportAffaire from "./views/editUser/ApportAffaire/EditUserApportAffaire";
import EditUserApportAffaireDetails from "./views/editUser/ApportAffaire/EditUserApportAffaireDetails";
import RequestListView from "./views/demandes/RequestListView";
import RequestView from "./views/demandes/RequestView";
import ValidRequestView from "./views/demandes/ValidRequestList";
import TreeThreeView from "./views/tree/TreeThree"
import ListContact from "./views/Contact/ListContact";
import ContactPersonnalInfoView from "./views/Contact/ContactPersonnalInfoView";
import ContactSocialNetwork from "./views/Contact/ContactSocialNetwork";
import ContactInvestInterest from "./views/Contact/ContactInvestInterest";
import ContactNotes from "./views/Contact/ContactNotes";

import AddDocumentView from "./views/addDocumentView";
// import {InstantNotifContext} from "./context/Context";

function App() {

  // const [userLogedIn, setUserLogedIn] = useState(false);
  // const [isLoading, setIsLoading] = useState(true)
  // const [showInstantNotif, setShowInstantNotif] = useState(false)
  // const [typeInstantNotif, setTypeInstantNotif] = useState("")
  // const [titleInstantNotif, setTitleInstantNotif] = useState("")
  // const [messageInstantNotif, setMessageInstantNotif] = useState("")
  // const [messagesInstantNotif, setMessagesInstantNotif] = useState([])
  // const setInstantNotif = (show,type,title,message,messages)=>{
  //   setShowInstantNotif(show)
  //   setTypeInstantNotif(type)
  //   setTitleInstantNotif(title)
  //   setMessageInstantNotif(message)
  //   setMessagesInstantNotif(messages)
  // }
  // const loaderContext = {isLoading, onSetIsLoading:setIsLoading}
  // const instantNotifContext = {
  //   showInstantNotif,
  //   typeInstantNotif,
  //   titleInstantNotif,
  //   messageInstantNotif,
  //   messagesInstantNotif,
  //   onSetShowInstantNotif:setShowInstantNotif,
  //   onSetTypeInstantNotif:setTypeInstantNotif,
  //   onSetTitleInstantNotif:setTitleInstantNotif,
  //   onSetMessageInstantNotif:setMessageInstantNotif,
  //   onSetMessagesInstantNotif:setMessagesInstantNotif,
  //   onSetInstantNotif:setInstantNotif
  // }


  // const isLoged = (data) => {
  //   setUserLogedIn(data);
  // };

  return (
    // <InstantNotifContext.Provider value={instantNotifContext}>
    <Router>
      <Routes>
    
        <Route path="/" element={<AuthenticationView />} />

        <Route element={<DashboardLayout />}>

          <Route path="/dashboard" element={<DashboardView />} />

          <Route path="/dashboard/usersList" element={<UsersListView />} />
          <Route path="/dashboard/createUser" element={<CreateUserView />} />
          <Route path="/dashboard/addHugeInvestor" element={<AddHugeInvestor />} />
          <Route path="/treethree" element = { <TreeThreeView />} />
          <Route path="/dashboard/list-contact" element={<ListContact/>} />
          <Route path="/dashboard/add-doc" element={<AddDocumentView />} />

          
          <Route element={<EditUserLayout />}>
            
            <Route path="/dashboard/userEdit/investorProfile/:id" element={<EditUserInvestorProfile />} />
            <Route path="/dashboard/userEdit/personalInfos/:id" element={<EditUserInfoPersoView />} />
            <Route path="/dashboard/userEdit/security/:id" element={<EditUserSecurityView />} />
            <Route path="/dashboard/userEdit/fiscality/:id" element={<EditUserFiscalityView />} />
            <Route path="/dashboard/userEdit/documents/:id" element={<EditUserDocumentsView />} />
            <Route path="/dashboard/userEdit/transaction/:id" element={<EditUserTransactionsView />} />
            <Route path="/dashboard/userEdit/investments/:id" element={<EditUserInvestmentView />} />
            <Route path="/dashboard/userEdit/banque/:id" element={<EditUserBanqueView />} />
            <Route path="/dashboard/userEdit/filleul/:id" element={<EditUserFilleulView />} />
            <Route path="/dashboard/userEdit/apport-affaire/:id" element={<EditUserApportAffaire />} />
            <Route path="/dashboard/userEdit/detail-apport-affaire/:id" element={<EditUserApportAffaireDetails />} />
            

           

          </Route>
          <Route element={<ContactLayout/>}>
            
              <Route path="/dashboard/list-contact/contact-personal-info/:id" element={<ContactPersonnalInfoView/>} />
              <Route path="/dashboard/list-contact/contact-social-network/:id" element={<ContactSocialNetwork/>} />
              <Route path="/dashboard/list-contact/contact-investment-interest/:id" element={<ContactInvestInterest/>} />
              <Route path="/dashboard/list-contact/contact-notes/:id" element={<ContactNotes/>} />
           </Route>

          <Route element={<RealEstateLayout />}>
            <Route path="/dashboard/real-estate" element={<RealEstateView />} />
            <Route path="/dashboard/real-wait-invests" element={<WaitInvestView />}/>
            <Route path ="/dashboard/real-wait-invests/:id" element={<InvestementView />} />
            <Route path="/dashboard/real-wait-invests" element={<WaitInvestView />}/>
            <Route path="/dashboard/real-wait-invests/success" element={<WaitInvestView />}/>
            <Route path="/dashboard/real-valid-invests" element={<ValidInvestView />}/>
          </Route>

          <Route element={<CapitalisationLayout/>}>
            <Route path="/dashboard/capitalisation" element={<CapitalisationView />} />
            <Route path="/dashboard/capital-wait-invests" element={<CapiWaitInvestView/>}/>
            <Route path="/dashboard/capital-wait-invests=success" element={<CapiWaitInvestView/>}/>
            <Route path = "/dashboard/capital-wait-invests/:id" element={<CapiInvestementView />} />
            <Route path="/dashboard/capital-valid-invests" element={<CapiValidInvestView/>}/>
          </Route>
          


          <Route path = "/dashboard/investments" element={<InvestmentsListView />} />
         
          <Route element={<WithDrawlayout/>}>
          <Route path = "/dashboard/business-withdraw" element={<BusinessWithDrawView/>} />
          <Route path = "/dashboard/valid-business-withdraw" element={<BusinessTreatedWithDrawView/>} />
          <Route path = "/dashboard/business-withdraw/:id" element={<WithDrawValidationView />} />
          </Route>

          <Route element={<RequestLayout/>}>
          <Route path = "/request" element={<RequestListView/>} />
          <Route path = "/request/:id" element={<RequestView/>} />
          <Route path = "/request/valid-requests" element={<ValidRequestView />} />
          </Route>
        </Route>
       
        

        <Route path="/*" element={<NoMatchView />} />

      </Routes>
    </Router>
    // </InstantNotifContext.Provider>
  );
}

export default App;
