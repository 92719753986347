import React, { Component } from "react";
import axios from "axios";

import InputText from "../../components/inputText";
import apiPath from "../../constants/apiPaths";
import Button from '../../components/button'
import { useParams } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { setFeedback } from '../../redux/store';


class EditUserFiscalityView extends Component{
  constructor(props){
    super(props);
    this.state={
      token: props.token,
      userToEdit: {},
      bodyToSend: {},
    }
  }

  componentDidMount() {

    const { id } = this.props.params;
    const headers = {headers: {"x-auth-token": this.props.token}};

    axios.get(`${apiPath}users/${id}`, headers)
    .then(res => {
      const user = res.data;
      this.setState({
        userToEdit: user,
        bodyToSend: {
          haveDiferentFiscalCountry: user.haveDiferentFiscalCountry,
          fiscalCountry: user.fiscalCountry && user.fiscalCountry.Country ? user.fiscalCountry.Country : ""
        }
      })
    })
    .catch(err => console.err(err));

  }

  handleCountryChange=(e)=>{
    this.setState({ bodyToSend: { ...this.state.bodyToSend, fiscalCountry:  e.target.value  } })
  }

  handleFiscalUpload=(e)=>{
    this.setState({ fiscale : e.target.files[0] })
  }

  handleFiscaliteChangeTrue=(e)=>{
    this.setState({bodyToSend: {...this.state.bodyToSend, haveDiferentFiscalCountry: false}})
  }

  handleFiscaliteChangeFalse=(e)=>{
    this.setState({bodyToSend: {...this.state.bodyToSend, haveDiferentFiscalCountry: true}})
  }

  handleSubmit=(e)=>{
    e.preventDefault()
    var headers = {
      headers: {
        "x-auth-token": this.state.token,
        // "Content-Type":'multipart/form-data'
      }
    }
    const formData = new FormData()
    if(this.state.fiscale){ //si une fiche fiscale est upload : 
      headers = {...headers, "Content-type":"multipart/form-data"}
      formData.append('fiscale',this.state.fiscale)
      formData.append('body',JSON.stringify(this.state.bodyToSend))
      axios.patch(`${apiPath}users/${this.state.userToEdit._id}/fiscale`,formData,headers)
      .then(res=>{
        this.props.setFeedback({
          show:true,
          type:"",
          title:res.data,
          message:"Votre demande a bien été traitée",
          messages:[]
        })
        console.log(res)
      })
      .catch(err=>{
        this.props.setFeedback({
          show:true,
          type:"",
          title:!err.response?.data?.message.includes("<!DOCTYPE html>")?err.response.data.message:err.message,
          message:"Une erreur est survenu, merci de réesayer",
          messages:[]
        })
        console.log(err)
      })
    }
    if(this.state.bodyToSend.haveDiferentFiscalCountry !== this.state.userToEdit.haveDiferentFiscalCountry){
      axios.patch(`${apiPath}users/${this.state.userToEdit._id}`,this.state.bodyToSend,headers)
      .then(this.setState({userToEdit: {...this.state.userToEdit, haveDiferentFiscalCountry: this.state.bodyToSend.haveDiferentFiscalCountry}}))
    }
    if(this.state.bodyToSend.haveDiferentFiscalCountry === true && //si pays fiscale diff &&
      this.state.bodyToSend.fiscalCountry)
    {
      axios.patch(`${apiPath}users/${this.state.userToEdit._id}`,this.state.bodyToSend,headers)
    }
  }

  render(){

    // return (
    //   <h1>Fiscalité</h1>
    // )

    return (
      
      <div className="dashboard-container">

        <div className="dashboard-scontainer">

          <div className="section-title">
            <h4 className="-texte">Ma Fiscalité</h4>
          </div>

          <div>
            <label>Votre adresse fiscale est-elle identique à votre adresse postale?</label><br/>
            <input type={"radio"} checked={this.state.bodyToSend.haveDiferentFiscalCountry === false} onChange={this.handleFiscaliteChangeTrue}/>Oui
            <input type={"radio"} checked={this.state.bodyToSend.haveDiferentFiscalCountry === true} onChange={this.handleFiscaliteChangeFalse}/>Non
          </div>

          <form onSubmit={this.handleSubmit} encType="multipart/form-data">
            {
              this.state.bodyToSend.haveDiferentFiscalCountry === true
              &&  <>
                    <InputText
                      type={"text"}
                      label={"Pays de votre adresse fiscale: "}
                      value={(this.state.bodyToSend.fiscalCountry)?this.state.bodyToSend.fiscalCountry:(this.state.userToEdit.fiscalCountry?this.state.userToEdit.fiscalCountry:"")}
                      placeholder={"Pays"}
                      handleInputChange={this.handleCountryChange}
                    />
                    <span>Fiche fiscale : </span>
                    <input type={"file"} onChange={this.handleFiscalUpload}/><br/>
                  </>
            }
            <Button type='submit' style={{marginTop:"10px"}} classes="-small" label="Valider" />
          </form>

        </div>

      </div>
    )
  }
}

// Utilisé pour accéder aux hooks de react-rooter-v6 dans un composant type classe
// export default (props) => {
//   const params = useParams();
//   const { token } = useSelector(state => state.persistedReducer.user);
//   return <EditUserFiscalityView params={params} token={token} {...props} />
// }

const mapStateToProps = state => ({
  reduxParams: state.notPersistedReducer.params,
});
const actions = {
  setFeedback,
};

// Utilisé pour accéder aux hooks de react-rooter-v6 dans un composant type classe

export default connect(mapStateToProps,actions)(props => {
  const params = useParams();
  const { token } = useSelector(state => state.persistedReducer.user);
  return <EditUserFiscalityView params={params} token={token} {...props} />
})