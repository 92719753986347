import FormSectionTitle from "../../components/formSectionTitle"
import { useEffect, useState } from "react"
import apiPath from '../../constants/apiPaths';
import { useSelector} from "react-redux";
import axios from "axios";
import { roundNumber } from "../../helpers/roundNumberFunc";

const BusinessTreatedWithDrawView = () => {

  const [retraits,setRetraits] = useState([])
  const [users,setUsers]= useState([])
  const {user} = useSelector(state => state.persistedReducer);
  const headers = {
    headers: {
      "x-auth-token" : user.token,
    }
  }
  useEffect(()=>{
   
    axios.get(`${apiPath}withdraw/`,headers)
    .then((res)=>{
      setRetraits(res.data)
      console.log(res.data)
      axios.get(`${apiPath}users/`,headers)
      .then((res)=>{
        
        setUsers(res.data)
      })
      
   
     
    })
    .catch((err)=>{
      console.log(err)
    })
  },[])
return(
  <div className="_listUsersContainer">
    <div className="_listUsers">
      <FormSectionTitle
              title="liste des retraits traités"
              classes="-simple-2 _users_title"
            />
    
    <div className="_users_container ">

          <div className=" -invest">
                  <span>UTILISATEUR</span>
                  <span>MONTANT</span>
                  <span>COMPTE DE RETRAIT</span>
                  <span>DATE</span>
                  <span>STATUS</span>
            </div>
             
      <ul className="_users_list">
        { retraits && retraits.map(retrait=>{
       
          return (
           
            retrait.status === "processed" &&<>
             
             <li key={retrait.id} className=" -investement">
              
              <div data-label="UTILISATEUR :">
              {users.map((user)=>user._id === retrait.user ? user.firstname + " " + user.lastname :"") }
              </div>
              <div data-label="MONTANT :">{roundNumber(retrait.amount).toLocaleString('en-EU').replaceAll(',', ' ')}€</div>
              <div data-label="COMPTE DE RETRAIT :">{retrait.compte}</div>
              <div data-label="DATE :">{new Date (retrait.dateRetrait).toLocaleDateString()}</div>
              <div className={retrait.status === "processed" ? "-succes": "-waiting"} data-label="STATUT :">
                {retrait.status === "processed" ? "validé": "à traiter"}
              </div>

              </li>
              
              </>
            
          ) 
        })
      }
       
       
      </ul>
      </div>
    </div>
  </div>
)
};

export default BusinessTreatedWithDrawView;