import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, NavLink, useLocation } from "react-router-dom";

import { setTitle } from "../../redux/store";

import logo from "../../assets/img/logo/logo-vizio-blue.svg";


import IconCommunaute from '../../assets/svg/navbar-icon-communaute';
import IconUsers from '../../assets/svg/navbar-icon-users';
import IconNetwork from '../../assets/svg/navbar-icon-network';

import IconArrow from '../../assets/svg/navbar-arrow';


import IconCommunauteColored from '../../assets/svg/navbar-icon-communaute-colored';
import IconUsersColored from '../../assets/svg/navbar-icon-users-colored';
import IconNetworkColored from '../../assets/svg/navbar-icon-network-colored';

import IconArrowColored from '../../assets/svg/navbar-arrow-colored';

import IconClose from '../../assets/svg/navbar-close';

const Navbar = ({handleCloseNavbar, isNavbarOpen}) => {

  
  const dispatch = useDispatch();

  const [items, setItems] = useState([
    // {
    //   id: 0,
    //   itemLink: "/dashboard",
    //   itemTitle: "Tableau de bord",
    //   isActive: false,
    //   class: "",
    //   icons: [
    //     <IconDashboard/>,
    //     <IconDashboardColored/>,
    //   ],
    //   internItems: false,
    //   child: false,
    // },
    {
      id: 1,
      itemLink: "/dashboard/usersList",
      itemTitle: "Compte Utilisateurs",
      class: "",
      icons: [
        <IconUsers/>,
        <IconUsersColored/>
      ],
      isActive: false,
      internItems: false,
      child: false,
    },
    // {
    //   id: 2,
    //   itemLink: "/dashboard/investments",
    //   itemTitle: "Investissement",
    //   class: "",
    //   icons: [
    //     <IconCommunaute/>,
    //     <IconCommunauteColored/>
    //   ],
    //   isActive: false,
    //   child: false,
    // },
    {
      id: 3,
      itemLink: "/dashboard/real-estate",
      itemTitle: "Real-Estate",
      class: "",
      icons: [
        <IconCommunaute/>,
        <IconCommunauteColored/>
      ],
      isActive: false,
      child: false,
    },
    {
      id: 4,
      itemLink: "/dashboard/capitalisation",
      itemTitle: "Capitalisation",
      class: "",
      icons: [
        <IconCommunaute/>,
        <IconCommunauteColored/>
      ],
      isActive: false,
      child: false,
    },
    {
      id: 5,
      itemLink: "/dashboard/business-withdraw",
      itemTitle: "Retraits Affaires",
      class: "",
      icons: [
        <IconCommunaute/>,
        <IconCommunauteColored/>
      ],
      isActive: false,
      child: false,
    },
    {
      id: 6,
      itemLink: "/request",
      itemTitle: "Demandes",
      class: "",
      icons: [
        <IconCommunaute/>,
        <IconCommunauteColored/>
      ],
      isActive: false,
      child: false,
    },

    {
      id: 7,
      itemLink: "/treethree",
      itemTitle: "Réseau d'apport d'affaires",
      class: "",
      icons: [
        <IconNetwork/>,
        <IconNetworkColored/>
      ],
      isActive: false,
      child: false,
    },
    {
      id: 8,
      itemLink: "/dashboard/list-contact",
      itemTitle: "Liste de contact",
      class: "",
      icons: [
        <IconNetwork/>,
        <IconNetworkColored/>
      ],
      isActive: false,
      child: false,
   
    },
    //!dernier element de la nav
    {
      id: 100,
      itemLink: "///152-228-211-95.sslip.io/wp-admin/",
      itemTitle: "Gestion de contenus",
      class: "",
      target:true,
      icons: [
        <IconNetwork/>,
        <IconNetworkColored/>
      ],
      isActive: false,
      child: false,
    },
   
    
    // {
    //   id: 8,
    //   itemTitle: "HELP",
    // },
    // {
    //   id: 9,
    //   itemLink: "/dashboard/tutoriels",
    //   itemTitle: "Tuto",
    //   class: "",
    //   icons: [
    //     <IconTutoriels/>,
    //     <IconTutorielsColored/>
    //   ],
    //   isActive: false,
    //   internItems: false,
    //   child: false,
    // },
    // {
    //   id: 10,
    //   itemLink: "/dashboard/faq",
    //   itemTitle: "FAQ",
    //   class: "",
    //   icons: [
    //     <IconFaq/>,
    //     <IconFaqColored/>,
    //   ],
    //   isActive: false,
    //   internItems: false,
    //   child: false,
    // },
    // {
    //   id: 11,
    //   itemLink: "/dashboard/settings",
    //   itemTitle: "Settings",
    //   class: "",
    //   icons: [
    //     <IconParametres/>,
    //     <IconParametresColored/>,
    //   ],
    //   isActive: false,
    //   internItems: false,
    //   child: false,
    // },
  ]);

  const location = useLocation();

  useEffect(() => {
    const newItems = items.map(item => {
      if ( item.itemLink === location.pathname ) {
        dispatch(setTitle(item.itemTitle));
        return {...item, class:'-active'};
      }else return {...item, class:''}
    });
    setItems(newItems);
  }, [location])

  const handleItemClick = (id) => {
    const newItems = items.map(item => item.id === id ? {...item, class:'-active'} : {...item, class:''})
    setItems(newItems);
  }

  return (
    <nav className={`navbar ${isNavbarOpen ? "-active" : ""}`}>

      <div className="-header">
        <div className="-logoNav">
          <Link to="/dashboard/usersList">
          <img src={logo} alt=""/>
          </Link>
        </div>
        <button className="-icon" onClick={handleCloseNavbar}>
          <IconClose />
        </button>
      </div> 

      <div className="-links">
        {
          items.map((obj) => {

            if ( obj.itemLink ) return (
              <NavLink
                key={obj.id}
                to={obj.itemLink}
                target={obj.target?'_blank':''}
                className={`-link ${obj.class}`}
                onClick={() => {
                  dispatch(setTitle(obj.itemTitle));
                  !obj.target && handleItemClick(obj.id);
                }}
              >

                <div className='-icon'>{obj.icons[0]}{obj.icons[1]}</div>
                <div className='-label'>{obj.itemTitle}</div>
                <div className='-arrow'><IconArrow/><IconArrowColored/></div>
                
              </NavLink>
            )
            else return <h1 className='-title' key={obj.id} >{obj.itemTitle}</h1>

          })
          
        }
      
      </div>
    </nav>
  )
}
 
export default Navbar ;