import React, {useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import apiPath from '../../constants/apiPaths';
import FormRow from '../../components/formRow';
import InputText from '../../components/inputText';
import FormRowCheckbox from '../../components/formRowCheckbox';
import InputSelect from '../../components/inputSelect';
import Button from '../../components/button';
import { setFeedback } from '../../redux/store';

const ContactInvestInterest = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.persistedReducer);
  const headers = {
    headers: {
      "x-auth-token": user.token,
    },
  };
  const { id } = useParams()

  useEffect(() => {
    axios.get(`${apiPath}prospect/${id}`, headers).then(res => {
      console.log(res.data)
      setInterestedValue(res.data.interest)
      setInterestedToInvest(res.data.investor.interestStatus)
      setInvestorStatus(res.data.investor.investStatus)
      setAmount(res.data.investor.investAmount)
      setInterestedToAA(res.data.business.interestStatus)
      setAAStatut(res.data.business.businessStatus)
    }).catch(err => {
      console.log(err)
    })

  }, [])
  const [investorStatus, setInvestorStatus] = useState('')
  const [amount, setAmount] = useState('')
  const [AAStatut, setAAStatut] = useState('')
  const [interestedToInvest, setInterestedToInvest] = useState('non')
  const [interestedToAA, setInterestedToAA] = useState('non')

  const [interestedValue, setInterestedValue] = useState([])
  const [interest, setInterest] = useState([
    {
      id: 1,
      label: "Investissement immobilier",
      value: false,
    },
    {
      id: 2,
      label: "Investissement start-up",
      value: false,
    },
    {
      id: 3,
      label: "Investissement finance",
      value: false,
    },
    {
      id: 4,
      label: "Apports d'affaires",
      value: false,
    },
  ])

  const investValidation = [
    {
      id: "Aucun",
      label: "Aucun",
      value: "Aucun",
    },
   
    {
      id: "Validé",
      label: "Validé",
      value: "Validé",
    },
    {
      id: "A refusé",
      label: "A refusé",
      value: "A refusé",
    },
    {
      id: "En attente",
      label: "En attente",
      value: "En attente",
    },]

  const AAValidation = [
    {
      id: "Aucun",
      label: "Aucun",
      value: "Aucun",
    },
    {
        id: "Validé",
        label: "Validé",
        value: "Validé",
      },
      {
        id: "A refusé",
        label: "A refusé",
        value: "A refusé",
      },
      {
        id: "En attente",
        label: "En attente",
        value: "En attente",
      },
  ]

  const handleInvestorStatusChange = (e) => {
    setInvestorStatus(e.target.value)
  }
  const handleInvestAmountChange = (e) => {
    setAmount(e.target.value)
  }
  const handleAAStatutChange = (e) => {
    setAAStatut(e.target.value)
  }

  const handleMyLangueChange = (e, id) => {
    // console.log(e.target.checked)
    console.log(id)
    const interested = interest;
    interested[id - 1].value = e.target.checked;
    var checkedValues = interested.filter((o) =>
      (o.value === true)
    )
    const checkedValue = checkedValues.map((c) => c.label)
    setInterestedValue(checkedValue)
    setInterest([...interested]);
    console.log(interested)

  };
  const handleClick = (e) => {
    e.preventDefault()
  }

  const onChangeInterestedInvest = (e) => {
    setInterestedToInvest(e.target.value)
  }

  const onChangeInterestedAA = (e) => {
    setInterestedToAA(e.target.value)
  }

  const HandleIntrestSubmit = (e) => {
    e.preventDefault()
    const body = {

      interest: interestedValue,
      investor: {
        interestStatus: interestedToInvest,
        investStatus: investorStatus,
        investAmount: amount
      },
      business: {
        interestStatus: interestedToAA,
        businessStatus: AAStatut
      }


    }
    axios.patch(`${apiPath}prospect/investInterest/${id}`, body, headers)
    .then((response) => {
      dispatch(setFeedback({show: true, type: 'success', title: "Confirmation des modifications", message: 'Vos données ont bien été mises à jour'}));
      console.log(response);
      setTimeout(() => {
        dispatch(setFeedback({ show: false }));
      }, 5000);
    }
      )
      .catch((error) => {
        console.log(error);
        dispatch(setFeedback({ show: true, type: 'error', title: `Error`, message: `Message d'erreur` }));
        setTimeout(() => {
          dispatch(setFeedback({ show: false }));
        }, 5000);

      }
      )

  }

  return (
    <div>
      <div className="layout-AA-container">
        <div className="-add-contact-container">
          {/* <div className="contact-info-container -apporAf">
            <div className="-small-title">
              <h4 className="-texte">{texts[lang].infoPrealableContactForm}</h4>
            </div>
            <div className="-information">

              <InfosBar
                classes="-error"
                content={
                  texts[lang].infoFormContactContentOne
                }
              />
              <InfosBar
                classes="-info"
                content={
                  texts[lang].infoFormContactContentTwo
                }
              />
            </div>
          </div> */}
          <form onSubmit={HandleIntrestSubmit}>
            <div className="contact-info-container -apporAf">
              <div className="-small-title">
                <h4 className="-texte">{"Renseigner les intérêts du contact pour le projet Vizio"}</h4>
              </div>

              <div className='contact-scontainer'>

                <FormRow classes={"-two"}>

                  <div className="-checkbox">
                    <p className='-separator'>{"Il est interessé par :"}  </p>
                    {interest.map((n) => {
                      return (
                        <FormRowCheckbox
                          isActive={interestedValue.includes(n.label)}
                          label={n.label}
                          handleCheckboxChange={(event) =>
                            handleMyLangueChange(event, n.id)
                          }
                        />
                      );
                    })}
                  </div>

                </FormRow>



              </div>

              <div className="-small-title">
                <h4 className="-texte">{"L'investissement"}</h4>
              </div>
              <div className='contact-scontainer'>

                <FormRow classes={"-one"}>


                  <div onChange={onChangeInterestedInvest} >
                    <label>{"Interessé pour investir"}</label>
                    <div className="radio-container">
                      <div className='radio-input'>
                      <input id="rad1" type="radio" value={`Oui`} name="interestedToInvest" checked={interestedToInvest === `Oui`} /> <label for="rad1">{"Oui"}</label>
                      </div>
                      <div className='radio-input'>
                      <input id="rad2" type="radio" value={`Non`} name="interestedToInvest" checked={interestedToInvest === `Non`} /> <label for="rad2"> {"Non"}</label>
                      </div>
                      <div className='radio-input'>
                      <input id="rad3" type="radio" value={`En attente`} name="interestedToInvest" checked={interestedToInvest === `En attente`} /><label for="rad3">{"En attente"}</label> 
                      </div>
                    </div>
                  </div>

                </FormRow>
                <FormRow classes={"-two"}>


                  <InputSelect
                  required = {false}
                    label={"Statut d'investisseur"}
                    inputs={investValidation.map((n) => {
                      return { id: n.id, label: n.label };
                    })}
                    value={investorStatus}
                    handleSelectChange={(e) => {
                      handleInvestorStatusChange(e);
                    }}

                  />
                  <InputText
                  required = {false}
                    type="number"
                    label={"Montant d'investissement"}
                    min="0"
                    value={amount}
                    handleInputChange={(e) => {
                      handleInvestAmountChange(e);
                    }}


                  />

                </FormRow>



              </div>
              <div className="-small-title">
                <h4 className="-texte">{"L'apporteur d'affaires"}</h4>
              </div>
              <div className='contact-scontainer'>

                <FormRow classes={"-one"}>

                   <div onChange={onChangeInterestedAA} >
                    <label>{"Intéressé pour faire des affaires"}</label>
                    <div className="radio-container">
                      <div className='radio-input'>
                        <input id="rad4" type="radio" value={`Oui`} name="interestedToAA" checked={interestedToAA === `Oui`} /> <label for="rad4" >{"Oui"}</label>
                      </div>
                      <div className='radio-input'>
                        <input id="rad5" type="radio" value={`Non`} name="interestedToAA" checked={interestedToAA === `Non`} /> <label for="rad5" >{"Non"}</label>
                      </div>
                      <div className='radio-input'>
                        <input id="rad6" type="radio" value={`En attente`} name="interestedToAA" checked={interestedToAA === `En attente`} /> <label for="rad6" >{"En attente"}</label>
                      </div>
                  


                      {/* <input id="rad4" type="radio" value={`${texts[lang].AAinterestContent[0]}`} name="interestedToAA" checked={interestedToAA === `${texts[lang].AAinterestContent[0]}`} /> <label for="rad4" >{texts[lang].AAinterestContent[0]}</label>
                      <input id="rad5" type="radio" value={`${texts[lang].AAinterestContent[1]}`} name="interestedToAA" checked={interestedToAA === `${texts[lang].AAinterestContent[1]}`} /> <label for="rad5" >{texts[lang].AAinterestContent[1]}</label>
                      <input id="rad6" type="radio" value={`${texts[lang].AAinterestContent[2]}`} name="interestedToAA" checked={interestedToAA === `${texts[lang].AAinterestContent[2]}`} /> <label for="rad6" >{texts[lang].AAinterestContent[2]}</label>
                      <input id="rad7" type="radio" value={`${texts[lang].AAinterestContent[3]}`} name="interestedToAA" checked={interestedToAA === `${texts[lang].AAinterestContent[3]}`} /><label for="rad7" > {texts[lang].AAinterestContent[3]}</label> */}
                    </div>
                  </div>

                </FormRow>
                <FormRow classes={"-two"}>

                  <InputSelect
                  required = {false}
                    label={"Statut d'apporteur d'affaires"}
                    inputs={AAValidation.map((n) => {
                      return { id: n.id, label: n.label };
                    })}
                    value={AAStatut}
                    handleSelectChange={(e) => {
                      handleAAStatutChange(e);
                    }}
                  />


                </FormRow>




              </div>

            </div>
            <div className="-add-contact-button">
              <Button
                classes={"-add-contact"}
                label={"Confirmer les modifications"}
                type="submit"
              />
            </div>   
          </form>
        </div>

      </div>
    </div>
  );
};

export default ContactInvestInterest;   