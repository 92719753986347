import React, { Component } from "react";
import QRCode from "qrcode";

class QRCodeRender extends Component {
  constructor(props) {
    super(props)
    this.state = {
      key: props.keyProp,
      email: props.email
    }
  }
  componentDidMount() {
    this.setState({ load: 'ok ' })
  }
  render() {
    return (
      <>
        <canvas id="QRCode"></canvas>
        {
          QRCode.toCanvas(document.getElementById("QRCode"), `otpauth://toptp/Vizio:${this.state.email}?secret=${this.state.key}`, { toSJISFunc: QRCode.toSJIS }, function (error) {
            if (error) console.error(error)
            return
          })
        }
      </>
    )
  }
}
export default QRCodeRender