import axios from "axios";
import apiPath from "../../constants/apiPaths";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import Card from "../../components/card";
// import InputSelect from "../../components/inputSelect"
import { Link } from "react-router-dom";
import IconPlus from "../../components/icons/icon-plus";
// import IconArrowColored from '../../assets/svg/navbar-arrow-colored';
// import InputText from '../../components/inputDate';
import { roundMoney } from "../../helpers/dataParse";
import { roundNumber } from "../../helpers/roundNumberFunc";

const RealEstateView = () => {
  const { user } = useSelector((state) => state.persistedReducer);
  const headers = {
    headers: {
      "x-auth-token": user.token,
    },
  };
  const [nPInvestments, setNPInvestments] = useState(null);

  useEffect(() => {
    axios
      .get(`${apiPath}investment/`, headers)
      .then((res) => {
        // console.log(res.data)
        //  setInvestments(res.data)
        let investmentss = res.data;
        // console.log(investmentss);
        axios
          .get(`${apiPath}fonds/`, headers)

          .then((res) => {
            // console.log(res.data)
            let fond = res.data.find((fond) => fond.name === "Real-Estate");
            setMontant(
              investmentss
                .map(
                  (i) =>
                    i.status === "processed" &&
                    i.fond === fond._id &&
                    parseInt(i.montant)
                )
                .reduce((prev, next) => prev + next)
            );
            setNPInvestments(
              investmentss
                .map(
                  (i) =>
                    i.status === "not processed" &&
                    i.fond === fond._id &&
                    parseInt(i.montant)
                )
                .reduce((prev, next) => prev + next)
            );
            setNumber(
              investmentss.filter(
                (i) => i.fond === fond._id && i.status === "not processed"
              ).length
            );
            //  setFondRealEstate(fond)
          })

          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // const [investments, setInvestments] = useState(null)
  const [redirect, setRedirect] = useState(false);
  const [redirectValidatedInvest,setRedirectValidatedInvest]= useState(false);
  // const [fondRealEstate, setFondRealEstate] = useState([])
  const [number, setNumber] = useState(0);
  const [montant, setMontant] = useState(0);
  // const [montantCinq , setMontantCinq] = useState(0)
  // const [montantQuinze, setMontantQuize] = useState(0)
  // const [montantSoianteQuinz, setMontantSoianteQuinz] = useState(0)

  // const [month, setMonth] = useState(null);

  // const years = [
  //   {
  //     id:0,
  //     label:2022
  //   },
  //   {
  //     id:1,
  //     label:2023
  //   },
  //   {
  //     id:2,
  //     label:2024
  //   },
  //   {
  //     id:3,
  //     label:2025
  //   },
  // ]

  // const handleDate = (e)=>{
  //   console.log(e.target.value)
  //   setMonth(e.target.value)
  // }

  // console.log(invalid_invests)
  const handleHistory = () => {
    setRedirectValidatedInvest(true);
  };
  if (redirectValidatedInvest === true)
    return <Navigate to="/dashboard/real-valid-invests" />;

  const handleChange = () => {
    setRedirect(true);
  };
  if (redirect === true) return <Navigate to="/dashboard/real-wait-invests" />;
  return (
    <>
      <div className="filter">
        {/* <div className="-input-wrapper">
          <p>Filtrer par :</p>
          
          <InputText //!VUE DE FILTRE MISE EN STAND BY      
            classes = "select-style-2"
            value = {month}
            type="month"
            handleDateChange = {(e)=>handleDate(e)}
          
            />
            </div> */}
        {/* <label className='date-style'>
          <div className="-month-wrapper">
            <div className="-icon">
              
              <input className='date-style-2' type="month" min="2022-08"  value={month} onChange={(e)=>handleDate(e)}/>

          </div>
          </div>
          </label> */}

        {/* <div className="-input-wrapper">
          <p>Filtrer par :</p>
          <InputSelect
              classes="select-style-2"
             inputs={years}
            />
        </div> */}

        <Link
          className="-button-wrapper"
          to="/dashboard/addHugeInvestor"
          onClick={() => this.props.setTitle("Ajouter un investisseur")}
        >
          <div className="-icon">
            <IconPlus />
          </div>
          <button>Ajouter un investisseur à + de 50K</button>
        </Link>
        {/* DIVS DES FILTRES*/}
        <div className="div1"></div>
        <div className="div2"></div>
      </div>
      <div className="container-rows">
        <div className="-card-container -row">
          <Card
            header={"Levée du fond"}
            amount={
              roundNumber(montant)
                ? roundNumber(montant)
                    .toLocaleString("en-EU")
                    .replaceAll(",", " ") + " €"
                : "0€"
            }
            text={"Montant levé dans la société Vizio Real Estate"}
            btnlabel={"Historique"}
            action={handleHistory}

          />

          <Card
            classes={"-red"}
            header={"Investissements à valider"}
            amount={number ? number : 0}
            text={"À Valider et uploader les investissements"}
            secondAmount={
              roundNumber(nPInvestments)
              ? roundNumber(nPInvestments)
                  .toLocaleString("en-EU")
                  .replaceAll(",", " ") + " €"
              : "0 €"}
            secondText={"Montant à valider" }
            btnlabel={"Valider les investissements"}
            action={handleChange}
          />
        </div>
        <div className="specialcard">
          <div className="-header">
            <label>Dispatch des fonds</label>
          </div>
          <div className="-body">
            <div className="-texts">
              <p className="montant">
                {roundNumber(montant * 0.15)
                  .toLocaleString("en-EU")
                  .replaceAll(",", " ")}{" "}
                €
              </p>
              <p className="label">Compte Marketing (15%)</p>
            </div>
            <div className="-texts">
              <p className="montant">
                {roundNumber(montant * 0.05)
                  .toLocaleString("en-EU")
                  .replaceAll(",", " ")}{" "}
                €
              </p>
              <p className="label">Compte Projet (5%)</p>
            </div>
            <div className="-texts">
              <p className="montant">
                {roundNumber(montant * 0.05)
                  .toLocaleString("en-EU")
                  .replaceAll(",", " ")}{" "}
                €
              </p>
              <p className="label">Compte de protection (5%)</p>
            </div>
            <div className="-texts">
              <p className="montant">
                {roundNumber(montant * 0.75)
                  .toLocaleString("en-EU")
                  .replaceAll(",", " ")}{" "}
                €
              </p>
              <p className="label">Compte immobilier (75%)</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default RealEstateView;
