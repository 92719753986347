import React, { Component } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { connect,useSelector } from "react-redux";

import FormRow from "../../components/formRow";
import FormRowCheckbox from "../../components/formRowCheckbox";
import InputText from "../../components/inputText";
import InputDate from "../../components/inputDate";
import Button from "../../components/button";
import FiscalitePercentage from "../../components/fiscalitePercentage";

import apiPath from "../../constants/apiPaths";

import { setFeedback } from '../../redux/store';

import { useContext } from "react";
import { InstantNotifContext } from "../../context/Context";

class EditUserInfoPersoView extends Component {
  // static contextType = InstantNotifContext
  constructor(props) {
    super(props);
    this.state = {
      parrainLastName:"",
      parrainFirstName:"",
      parrainEmail:'',
      token: props.token,
      userToEdit: {},
      links:{},
      contactToEdit: [],
      curEmail: "",
      birthday: "",
      contactAdd: false,
      hadContact: false,
    };
  }
  
  componentDidMount() {
    console.log(this.props)
    const { id } = this.props.params;
    const headers = {headers: {"x-auth-token": this.props.token}};
    axios.get(`${apiPath}users/parain/${id}`, headers)
    .then((res)=>{
      const pF = res.data?.parain?.firstname
      const pL = res.data?.parain?.lastname
      const pE = res.data?.parain?.email
      this.setState({
        parrainLastName:pL,
        parrainFirstName:pF,
        parrainEmail:pE,
      })

     }).catch(err => console.log(err));

    axios.get(`${apiPath}users/${id}`, headers)
    .then(res => {
      const user = res.data;
      this.setState({
        userToEdit: user,
        contactToEdit: user.personeToContact && user.personeToContact !== "" ? user.personeToContact[0] : false,
        contactAdd: user.personeToContact && user.personeToContact.length > 0 ? true : false,
        hadContact: user.personeToContact && user.personeToContact.length > 0 ? true : false,
        curEmail: user.email,
        birthday: user.dateOfBirth ? this.generateDate(user.dateOfBirth) : this.generateDate(),
        bodyToSend: {},
      })
    })
    .catch(err => console.log(err));

    axios.get(`${apiPath}users/links/${id}`,headers)
    .then((res)=>{
      // console.log(res.data)
      this.setState({links:res.data})
    })
    .catch((err) => {console.log(err)});
  }

  handleSubmit = (e) => {

    e.preventDefault();
    const headers = {headers: {"x-auth-token": this.props.token}};
    const modificationsObj = this.state.bodyToSend;

    if (modificationsObj && Object.keys(modificationsObj).length > 0) {

      axios.patch(`${apiPath}users/${this.state.userToEdit.id}`, modificationsObj, headers)
      .then((res) => {
        this.props.setFeedback({
          show:true,
          type:"",
          title:res.data,
          message:"Votre demande a bien été traitée",
          messages:[]
        })
        // console.log(res)
      })
      .catch((err) => {
        this.props.setFeedback({
          show:true,
          type:"",
          title:!err.response?.data.includes("<!DOCTYPE html>")?err.response.data:err.message,
          message:"Une erreur est survenu, merci de réesayer",
          messages:[]
        })
        // console.log(err)
      });

    } else console.log("pas de modification ... ")

  };

  generateDate(date) {

    if (date) {
      

      if (typeof date === "string") {

        const formatedDate = new Date(date);
         console.log(formatedDate);

        if (formatedDate.getFullYear) return formatedDate;
        else {
          const formatedDate = new Date();
          const day = date.slice(8, 10);
          const month = date.slice(5, 7);
          const year = date.slice(0, 4);
          formatedDate.setDate(day);
          formatedDate.setMonth(month);
          formatedDate.setMonth(month);
          formatedDate.setFullYear(year);
          return formatedDate;
        }

      } else if (typeof date === "object") return date

    } else return Date.now();
  }

  handleResetPassword = (e) => {
    axios.put(`${apiPath}resetPassword/email/` + this.state.userToEdit.email)
    .then((res) => this.setState({
      resMessage: "Demande traités, un email de réinitialisation de mot de passe a été envoyé a l'utilisateur."
    }))
    .catch((err) => console.log(err));
  };

  handleEmaiChange = (e) => {
    const value = e.target.value;
    if (value) {
      this.setState({ userToEdit: { ...this.state.userToEdit, email: value } });
      this.setState({ bodyToSend: { ...this.state.bodyToSend, email: value } });
    }
  };

  handlePasswordReset = (e) => {
    const value = e.target.checked;
    if (value) this.setState({ resetPassword: true });
    else this.setState({ resetPassword: false })
  };

  //Champs utilisateur :
  handleFirstnameChange = (e) => {
    this.setState({
      userToEdit: { ...this.state.userToEdit, firstname: e.target.value },
    });
    this.setState({
      bodyToSend: { ...this.state.bodyToSend, firstname: e.target.value },
    });
  };

  handleLastnameChange = (e) => {
    this.setState({
      userToEdit: { ...this.state.userToEdit, lastname: e.target.value },
    });
    this.setState({
      bodyToSend: { ...this.state.bodyToSend, lastname: e.target.value },
    });
  };

  handlePseudoChange = (e) => {
    this.setState({
      userToEdit: { ...this.state.userToEdit, pseudo: e.target.value },
    });
    this.setState({
      bodyToSend: { ...this.state.bodyToSend, pseudo: e.target.value },
    });
  };

  handleAddressChange = (e) => {
    this.setState({
      userToEdit: { ...this.state.userToEdit, address: e.target.value },
    });
    this.setState({
      bodyToSend: { ...this.state.bodyToSend, address: e.target.value },
    });
  };

  handleCodePostalChange = (e) => {
    this.setState({
      userToEdit: { ...this.state.userToEdit, zipCode: e.target.value },
    });
    this.setState({
      bodyToSend: { ...this.state.bodyToSend, zipCode: e.target.value },
    });
  };

  handleCountryChange = (e) => {
    this.setState({
      userToEdit: { ...this.state.userToEdit, country: e.target.value },
    });
    this.setState({
      bodyToSend: { ...this.state.bodyToSend, country: e.target.value },
    });
  };

  handlePhoneChange = (e) => {
    this.setState({
      userToEdit: { ...this.state.userToEdit, phone: e.target.value },
    });
    this.setState({
      bodyToSend: { ...this.state.bodyToSend, phone: e.target.value },
    });
  };

  handleCityChange = (e) => {
    this.setState({
      userToEdit: { ...this.state.userToEdit, city: e.target.value },
    });
    this.setState({
      bodyToSend: { ...this.state.bodyToSend, city: e.target.value },
    });
  };
  handleResCountryChange = (e) => {
    this.setState({
      userToEdit: { ...this.state.userToEdit, fiscalCountry: e.target.value },

    });
    this.setState({
      bodyToSend: { ...this.state.bodyToSend, fiscalCountry: e.target.value },
    });
  }
  handleDateOfBirthChange = (e) => {
    this.setState({
      userToEdit: { ...this.state.userToEdit, dateOfBirth: e.target.value },
    });
    this.setState({
      bodyToSend: { ...this.state.bodyToSend, dateOfBirth: e.target.value },
    });
  };

  //champs lier a l'entreprise :
  handleEntNameChange = (e) => {
    this.setState({
      userToEdit: {
        ...this.state.userToEdit,
        entreprise: {
          ...this.state.userToEdit.entreprise,
          name: e.target.value,
        },
      },
    });
    this.setState({
      bodyToSend: {
        entreprise: {
          ...this.state.userToEdit.entreprise,
          name: e.target.value,
        },
      },
    });
  };

  handleEntFormChange = (e) => {
    this.setState({
      userToEdit: {
        ...this.state.userToEdit,
        entreprise: {
          ...this.state.userToEdit.entreprise,
          jurForm: e.target.value,
        },
      },
    });
    this.setState({
      bodyToSend: {
        entreprise: {
          ...this.state.userToEdit.entreprise,
          jurForm: e.target.value,
        },
      },
    });
  };

  handleEntSiretChange = (e) => {
    this.setState({
      userToEdit: {
        ...this.state.userToEdit,
        entreprise: {
          ...this.state.userToEdit.entreprise,
          siret: e.target.value,
        },
      },
    });
    this.setState({
      bodyToSend: {
        entreprise: {
          ...this.state.userToEdit.entreprise,
          siret: e.target.value,
        },
      },
    });
  };

  handleRcsCityChange = (e) => {
    this.setState({
      userToEdit: {
        ...this.state.userToEdit,
        entreprise: {
          ...this.state.userToEdit.entreprise,
          rcsCity: e.target.value,
        },
      },
    });
    this.setState({
      bodyToSend: {
        entreprise: {
          ...this.state.userToEdit.entreprise,
          rcsCity: e.target.value,
        },
      },
    });
  };

  handleEntAddressChange = (e) => {
    this.setState({
      userToEdit: {
        ...this.state.userToEdit,
        entreprise: {
          ...this.state.userToEdit.entreprise,
          address: e.target.value,
        },
      },
    });
    this.setState({
      bodyToSend: {
        entreprise: {
          ...this.state.userToEdit.entreprise,
          address: e.target.value,
        },
      },
    });
  };

  handleEntCodePostalChange = (e) => {
    this.setState({
      userToEdit: {
        ...this.state.userToEdit,
        entreprise: {
          ...this.state.userToEdit.entreprise,
          zipCode: e.target.value,
        },
      },
    });
    this.setState({
      bodyToSend: {
        entreprise: {
          ...this.state.userToEdit.entreprise,
          zipCode: e.target.value,
        },
      },
    });
  };

  handleEntCityChange = (e) => {
    this.setState({
      userToEdit: {
        ...this.state.userToEdit,
        entreprise: {
          ...this.state.userToEdit.entreprise,
          city: e.target.value,
        },
      },
    });
    this.setState({
      bodyToSend: {
        entreprise: {
          ...this.state.userToEdit.entreprise,
          city: e.target.value,
        },
      },
    });
  };

  handleEntCountryChange = (e) => {
    this.setState({
      userToEdit: {
        ...this.state.userToEdit,
        entreprise: {
          ...this.state.userToEdit.entreprise,
          country: e.target.value,
        },
      },
    });
    this.setState({
      bodyToSend: {
        entreprise: {
          ...this.state.userToEdit.entreprise,
          country: e.target.value,
        },
      },
    });
  };

  // champ Personne a contacter :
  setContactToEdit = (id) => {
    this.setState({
      contactToEdit: this.state.userToEdit.personeToContact[id],
    });
  };

  addContacToEdit= (e) =>{
    if(e.target.checked){
      this.setState({contactAdd:true})
      this.setState({contactToEdit : {city : ""}})
    }else{
      this.setState({contactAdd:false})
    }
  };

  deleteContact = (id) => {
    //!ajouter confirmation ... 
    const oldContact = [...this.state.userToEdit.personeToContact]
    oldContact.splice(id,1)
    this.setState({userToEdit: {...this.state.userToEdit, personeToContact : oldContact}})
    this.setState({bodyToSend: {...this.state.bodyToSend, personeToContact : oldContact}})
    this.setState({contactToEdit: false})
  };

  saveContactToEdit =()=>{
    //! regex here : (if (isValid)...)
    if(this.state.userToEdit.personeToContact[0] !== undefined){
      var oldContact = [...this.state.userToEdit.personeToContact]
      console.log(oldContact.indexOf(this.state.contactToEdit  ) !== -1)
      if(oldContact.indexOf(this.state.contactToEdit  ) !== -1) oldContact.splice(oldContact.indexOf(this.state.contactToEdit.email),1)
    }
    else{
      var oldContact = []
    }
    oldContact.push(this.state.contactToEdit)
    this.setState({userToEdit: {...this.state.userToEdit, personeToContact: oldContact}})
    this.setState({bodyToSend: {...this.state.bodyToSend, personeToContact : oldContact}})
    this.setState({contactToEdit: false})
  }

  handleContactLastnameChange =(e)=>{
    this.setState({contactToEdit: {
      ...this.state.contactToEdit, lastname : e.target.value
    }})
  }

  handleContactFirstnameChange =(e)=>{
    this.setState({contactToEdit: {
      ...this.state.contactToEdit, firstname : e.target.value
    }})
  }

  handleContactEmailChange =(e)=>{
    this.setState({contactToEdit: {
      ...this.state.contactToEdit, email : e.target.value
    }})
  }

  handleContactAdressChange =(e)=>{
    this.setState({contactToEdit: {
      ...this.state.contactToEdit, address : e.target.value
    }})
  }

  handleContactZipCodeChange =(e)=>{
    this.setState({contactToEdit: {
      ...this.state.contactToEdit, zipCode : e.target.value
    }})
  }

  handleContactCityChange =(e)=>{
    this.setState({contactToEdit: {
      ...this.state.contactToEdit, city : e.target.value
    }})
  }

  handleContactCountryChange =(e)=>{
    this.setState({contactToEdit: {
      ...this.state.contactToEdit, country : e.target.value
    }})
  }

  handleContactphoneChange =(e)=>{
    this.setState({contactToEdit: {
      ...this.state.contactToEdit, phone : e.target.value
    }})
  }

  //!try date :
  handleDateChange = (e) => {
    const date = new Date(e.target.value);
    const day = e.target.value.slice(8, 10);
    const month = e.target.value.slice(5, 7);
    const year = e.target.value.slice(0, 4);
    date.setDate(day);
    date.setMonth(month);
    date.setFullYear(year);
    this.setState({ birthday: date });
    this.setState({
      bodyToSend: { ...this.state.bodyToSend, dateOfBirth: date },
    });
    this.setState({
      userToEdit: { ...this.state.userToEdit, dateOfBirth: date },
    });
  };

  getMaxDate = () => {
    const date = new Date();
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const month =
      date.getMonth() < 10 ? `0${date.getMonth()+1}` : date.getMonth()+1;
    const year = parseInt(date.getFullYear() - 18);
    return `${year}-${month}-${day}`;
  };

  getBirthDate = (date) => {
   
    if (typeof date === "number") {

      const dates = new Date(date);
      const day = dates.getDate().toString().length === 1 ? "0" + dates.getDate().toString() : dates.getDate().toString();
      const month = dates.getMonth().toString().length === 1 ? "0" + dates.getMonth().toString() : dates.getMonth().toString();
      const year = dates.getFullYear();
      const fulldate = year + "-" + month + "-" + day;
      return fulldate;

    } else if (typeof date === "object") {

      const day = date.getDate().toString().length === 1 ? "0" + date.getDate().toString() : date.getDate().toString();
      const month = (date.getMonth()+1).toString().length === 1 ? "0" + (date.getMonth()+1).toString() : (date.getMonth()+1).toString();

      const year = date.getFullYear();
      const fulldate = year + "-" + month + "-" + day;
      return fulldate;

    }else return "2000-01-01";

  };

  retourListe = (e) => {
    this.props.action("listUser");
  };

  handleCopytoClipBoard = (e)=>{
    var input = document.body.appendChild(document.createElement("input"));
    input.value = e.target.value;
    input.focus();
    input.select();
    document.execCommand('copy');
    input.parentNode.removeChild(input);

    alert("lien copié")

   
  }


  render() {

    const personnes = this.state.userToEdit.personeToContact;

    return (
      <>
        {this.state.resMessage && <p>{this.state.resMessage}</p>}

        <div className="dashboard-container">
          <div className="dashboard-scontainer">

            <div className="section-title">
              <h4 className="-texte">Informations personnelles</h4>
            </div>

            <form onSubmit={this.handleSubmit}>
            <FormRow classes={"-two"}>
                <InputText
                  classes={"-readonly"}
                  // type="text"
                  label="Email : "
                  placeholder={this.state.userToEdit.email}
                  value={this.state.userToEdit.email}
                  
                />
                
                <InputText
                  // classes={"-readonly"}
                  // type="text"
                  label="Pseudo : "
                  placeholder={this.state.userToEdit.pseudo}
                  value={this.state.userToEdit.pseudo}
                  handleInputChange={this.handlePseudoChange}
                />
              </FormRow>
              <FormRow classes={"-two"}>
                <InputText
                  // type="text"
                  label="Nom : "
                  placeholder={this.state.userToEdit.lastname}
                  value={this.state.userToEdit.lastname}
                  handleInputChange={this.handleLastnameChange}
                />
                <InputText
                  type="text"
                  label="Prénom : "
                  placeholder={this.state.userToEdit.firstname}
                  value={this.state.userToEdit.firstname}
                  handleInputChange={this.handleFirstnameChange}
                />
              </FormRow>

              <FormRow classes={"-one"}>
                <InputDate
                  label={"Date de naissance :"}
                  value={this.getBirthDate(this.state.birthday)}
                  min="1900-01-01"
                  max={this.getMaxDate()}
                  handleDateChange={this.handleDateChange}
                />
              </FormRow>

              <FormRow classes={"-two"}>
                <div>
                  <InputText
                    label="Pays de naissance : "
                    name="address"
                    placeholder={this.state.userToEdit.country}
                    value={this.state.userToEdit.country}
                    errorMessage="Adresse invalide"
                    handleInputChange={this.handleCountryChange}
                    indication=  {this.state.userToEdit.haveDiferentFiscalCountry === false && (
                      <>
                        <FiscalitePercentage
                          country={this.state.userToEdit.country}
                        />
                      </>
                    )}
                    {...this.state.userToEdit.haveDiferentFiscalCountry === true &&
                      this.state.userToEdit.fiscalCountry && (
                        <>
                          <FiscalitePercentage
                            country={this.state.userToEdit.fiscalCountry.Country}
                          />
                        </>
                      )}
                  />
                
                </div>

                <InputText
                  label="Numéro de téléphone : "
                  type="tel"
                  placeholder={this.state.userToEdit.phone}
                  value={this.state.userToEdit.phone}
                  errorMessage="Numéro de téléphone invalide"
                  handleInputChange={this.handlePhoneChange}
                />
              </FormRow>
              <FormRow classes={"-two"}>
                <InputText
                  label="Adresse : "
                  placeholder={this.state.userToEdit.address}
                  value={this.state.userToEdit.address}
                  errorMessage="Pays invalide"
                  handleInputChange={this.handleAddressChange}
                />
                <InputText
                  label="Ville : "
                  placeholder={this.state.userToEdit.city}
                  value={this.state.userToEdit.city}
                  errorMessage="Pays invalide"
                  handleInputChange={this.handleCityChange}
                />
              </FormRow>
              <FormRow classes={"-two"}>
                <InputText
                  label="Code Postal : "
                  name="zipCode"
                  type="number"
                  placeholder={this.state.userToEdit.zipCode}
                  value={this.state.userToEdit.zipCode}
                  errorMessage="Code postal invalide"
                  handleInputChange={this.handleCodePostalChange}
                />
                <InputText
                  label="Pays de résidence : "
                  name="address"
                  placeholder={this.state.userToEdit.fiscalCountry}
                  value={this.state.userToEdit.fiscalCountry}
                  errorMessage="Adresse invalide"
                  handleInputChange={this.handleResCountryChange}
                  
                />
              </FormRow>
              <FormRow >
              {this.state.links.emailValidationLink && <InputText
                  classes={"-readonly"}
                  label={"Lien de validation d'email"}
                  placeholder={this.state.links.emailValidationLink?this.state.links.emailValidationLink:""}
                  value={this.state.links.emailValidationLink?this.state.links.emailValidationLink:""}
                  handleInputClick={this.handleCopytoClipBoard}
                  readonly={true}
                  
                />}
              { this.state.links.resetPasswordLink && <InputText
                  classes={"-readonly"}
                  label={"Lien de reinitialisation du mot de passe"}
                  placeholder={this.state.links.resetPasswordLink ?this.state.links.resetPasswordLink :""}
                  value={this.state.links.resetPasswordLink ?this.state.links.resetPasswordLink :""}
                  handleInputClick={this.handleCopytoClipBoard}
                  indication={"Ce lien est valable 15min. Pensez à demander à l'utilisateur de renouveler sa demande."}
                  readonly={true}
                  
                />}
                {this.state.links.aaSignLink && <InputText
                  classes={"-readonly"}
                  label={"Lien de signature du contrat d'apport d'affaires"}
                  placeholder={this.state.links.aaSignLink?this.state.links.aaSignLink:""}
                  value={this.state.links.aaSignLink?this.state.links.aaSignLink:""}
                  handleInputClick={this.handleCopytoClipBoard}
                  readonly={true}
                  
                />}
                {this.state.links.comSignLink && <InputText
                  classes={"-readonly"}
                  label={"Lien de signature du contrat de collaboration"}
                  placeholder={this.state.links.comSignLink ?this.state.links.comSignLink :""}
                  value={this.state.links.comSignLink ?this.state.links.comSignLink :""}
                  handleInputClick={this.handleCopytoClipBoard}
                  readonly={true}
                  
                />}
              </FormRow>
              <FormRow classes={"-two"}>
              
                
              </FormRow>
              <FormRowCheckbox 
                label="Ajouter des personnes a contacter en cas d'indisponibilité"
                handleCheckboxChange={(e)=>this.addContacToEdit(e)}
                isActive={this.state.contactAdd===true?true:false}
              />
              <br />
              {this.state.contactAdd && (
                <>
                  <div className="section-title">
                    <h4 className="-texte">Personnes a contacter en cas d'indisponibilité </h4>
                  </div>
                  <ul className="contact-list">
                    {personnes.map((contact, id) => {
                      return (
                        <li key={id} className="contact">
                          <button
                            type="button"
                            className="-name"
                            label={(contact.firstname?contact.firstname:"nouveau")+(contact.lastname?contact.lastname:"contact")}
                            onClick={() => {
                              this.setContactToEdit(id);
                            }}
                          >
                            {contact.firstname?contact.firstname:"nouveau"} {contact.lastname?contact.lastname:"contact"}
                          </button>
                          
                          <button
                            type="button"
                            // className="-small"
                            className="-delete"
                            onClick={() => this.deleteContact(id)}
                          >
                            X
                          </button>
                        </li>
                      );
                    })}
                    {/* {!this.state.contactToEdit &&
                    <Button 
                      classes={"-small -mid"}
                      type="button"
                      label="Ajouter une personne"
                      handleClick={this.addContacToEdit}
                    />} */}
                  </ul>
                  <br />
                  {this.state.contactToEdit && (
                    <>
                    <FormRow classes={"-two"}>
                      <InputText
                        type="text"
                        label="Nom :  "
                        placeholder={this.state.contactToEdit.lastname}
                        value={this.state.contactToEdit.lastname}
                        handleInputChange={this.handleContactLastnameChange}
                      />
                      <InputText
                        type="text"
                        label="prénom :  "
                        placeholder={this.state.contactToEdit.firstname}
                        value={this.state.contactToEdit.firstname}
                        handleInputChange={this.handleContactFirstnameChange}
                      />
                      </FormRow>
                      <FormRow classes={"-two"} >
                      <InputText
                        type="email"
                        label="email :  "
                        placeholder={this.state.contactToEdit.email}
                        value={this.state.contactToEdit.email}
                        handleInputChange={this.handleContactEmailChange}
                      /> 
                      <InputText
                        type="text"
                        label="address :  "
                        placeholder={this.state.contactToEdit.address}
                        value={this.state.contactToEdit.address}
                        handleInputChange={this.handleContactAdressChange}
                      />
                      </FormRow>
                      <FormRow classes={"-two"} >
                      <InputText
                        type="text"
                        label="zipCode :  "
                        placeholder={this.state.contactToEdit.zipCode}
                        value={this.state.contactToEdit.zipCode}
                        handleInputChange={this.handleContactZipCodeChange}
                      />
                      <InputText
                        type="text"
                        label="city :  "
                        placeholder={this.state.contactToEdit.city}
                        value={this.state.contactToEdit.city}
                        handleInputChange={this.handleContactCityChange}
                      />
                      </FormRow>
                      <FormRow classes={"-two"} >
                      <InputText
                        type="text"
                        label="country :  "
                        placeholder={this.state.contactToEdit.country}
                        value={this.state.contactToEdit.country}
                        handleInputChange={this.handleContactCountryChange}
                      />
                      <InputText
                        type="phone"
                        label="phone :  "
                        placeholder={this.state.contactToEdit.phone}
                        value={this.state.contactToEdit.phone}
                        handleInputChange={this.handleContactphoneChange}
                      /></FormRow>
                      <Button type="button" classes={"-small -sub"} style={{width:"180px"}}  handleClick={this.saveContactToEdit} label="Enregister ce contact" />
                      <br />
                    </>
                  )}
                </>
              )}
              {this.state.userToEdit.type === "Entreprise" && (
                <>
                  <div className="section-title">
                    <h4 className="-texte">Information de votre Entreprise :</h4>
                  </div>
                  <FormRow classes={"-two"}>
                    <InputText
                      label="Dénomination sociale : "
                      name="name"
                      placeholder={this.state.userToEdit.entreprise.name}
                      value={this.state.userToEdit.entreprise.name}
                      errorMessage="Nom Invalide"
                      handleInputChange={this.handleEntNameChange}
                    />
                    <InputText
                      label="Siret : "
                      name="siret"
                      placeholder={this.state.userToEdit.entreprise.companyNumber}
                      value={this.state.userToEdit.entreprise.companyNumber}
                      errorMessage="siret invalide"
                      handleInputChange={this.handleEntSiretChange}
                    />
                  </FormRow>
                  <FormRow classes={"-two"}>
                    <InputText
                      label="Adresse du siège : "
                      name="address"
                      placeholder={this.state.userToEdit.entreprise.address}
                      value={this.state.userToEdit.entreprise.address}
                      errorMessage="Adresse invalide"
                      handleInputChange={this.handleEntAddressChange}
                    />
                    <InputText
                      label="Code postal : "
                      name="zipCode"
                      type="number"
                      placeholder={this.state.userToEdit.entreprise.zipCode}
                      value={this.state.userToEdit.entreprise.zipCode}
                      errorMessage="Code postal invalide"
                      handleInputChange={this.handleEntCodePostalChange}
                    />
                  </FormRow>
                  <FormRow classes={"-two"}>
                    <InputText
                      label="Commune : "
                      name="city"
                      placeholder={this.state.userToEdit.entreprise.city}
                      value={this.state.userToEdit.entreprise.city}
                      errorMessage="Commune invalide"
                      handleInputChange={this.handleEntCityChange}
                    />
                    <InputText
                      label="Pays : "
                      placeholder={this.state.userToEdit.entreprise.country}
                      value={this.state.userToEdit.entreprise.country}
                      errorMessage="Pays invalide"
                      handleInputChange={this.handleEntCountryChange}
                    />
                  </FormRow>
                  
                </>
              )}
               <div className="section-title">
              <h4 className="-texte">INFORMATIONS PERSONNELLES DE MON PARRAIN</h4>
              </div>
              <FormRow classes={"-two"}>
                <InputText
                  // type="text"
                  label="Nom de mon parrain"
                  placeholder={this.state.parrainLastName?this.state.parrainLastName:""}
                  value={this.state.parrainLastName?this.state.parrainLastName:""}
                />
                <InputText
                  label="Prénom de mon parrain: "
                  placeholder={this.state.parrainFirstName?this.state.parrainFirstName:""}
                  value={this.state.parrainFirstName?this.state.parrainFirstName:""}
                />
              </FormRow>

              <FormRow classes={"-one"}>
              <InputText
                  label={"Adresse mail de mon parrain"}
                  placeholder={this.state.parrainEmail?this.state.parrainEmail:""}
                  value={this.state.parrainEmail?this.state.parrainEmail:""}
                  // error={email.error}
                  readonly={true}
                  
                />
              </FormRow>
        

              <hr/>
              <br />
              <Button type="submit" classes="-small" label="Enregister les modificiations" />
            </form>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => ({
  reduxParams: state.notPersistedReducer.params,
});
const actions = {
  setFeedback,
};

// Utilisé pour accéder aux hooks de react-rooter-v6 dans un composant type classe

export default connect(mapStateToProps,actions)(props => {
  const params = useParams();
  const { token } = useSelector(state => state.persistedReducer.user);
  return <EditUserInfoPersoView params={params} token={token} {...props} />
})