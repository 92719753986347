
import axios from 'axios'
import apiPath from '../../constants/apiPaths';
import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { setTitle } from '../../redux/store';
import FormSectionTitle from '../../components/formSectionTitle';
import InvestementView from './InvestmentView';
import { roundNumber } from '../../helpers/roundNumberFunc';
// import {  Navigate } from 'react-router-dom';
import Button from "../../components/button";
import InfosBar from '../../components/infosBar';

const WaitInvestView = () => {

  const { user } = useSelector(state => state.persistedReducer);
  const headers = {
    headers: {
      "x-auth-token": user.token,
    }
  }
  const dispatch = useDispatch()

  const resetDate = useRef()
  const resetFile = useRef()
  const [investments, setInvestments] = useState([])
  const [users, setUsers] = useState([])
  const [fonds, setFond] = useState([])
  const [fondRealEstate, setFondRealEstate] = useState([])
  const [redirect, setRedirect] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);

  useEffect(() => {
    dispatch(setTitle("Real-Estate"))//!Fonctionne mais.... le Title en Dur ? 
    axios.get(`${apiPath}investment/`, headers)
      .then((res) => {
        // console.log(res.data);
        setInvestments(res.data)
        axios.get(`${apiPath}fonds/`, headers)
          .then((res) => {
            setFond(res.data)
            let fond = res.data.find(fond => fond.name === "Real-Estate")
            setFondRealEstate(fond)
            axios.get(`${apiPath}users/`, headers)
              .then((res) => {
                setUsers(res.data)
              })
              .catch((err) => {
                console.log(err)
              })
          })
          .catch((err) => {
            console.log(err)
          })

      })
      .catch((err) => {
        console.log(err)
      })
  }, [])
  
  const [bodyToSend, setBodyToSend] = useState({
    dispatch: {
      status: false
    },
    docUpload: false,
    investment: [],
    dateContrat: null
  })
  const [file, setFile] = useState(null)
  // const [redirect,setRedirect] = useState(false)
  const [error, setError] = useState(false)

  const handleDispatchChange = (e) => {
    setBodyToSend({ ...bodyToSend, dateContrat: e.target.value })
    if ((e.target.value !=="")  && bodyToSend.investment.length > 0 && file !==null) {
      setDisableBtn(false)
    } else if (e.target.value === null || e.target.value === "" || bodyToSend.investment.length === 0 || file === null) {
      setDisableBtn(true)
    }
    console.log(bodyToSend.investment); 
    console.log(e.target.value)
  }

  const handleObligationChange = (e) => {
    if (!e.target.files[0]) {
      setFile(null)
      setBodyToSend({ ...bodyToSend, docUpload: false })
      return
    }
    if (e.target.files[0] && bodyToSend.investment.length > 0 && bodyToSend.dateContrat !== null) {
      setDisableBtn(false)
    } else if (e.target.files[0] === null || e.target.files[0] === [] || e.target.files[0] === "" )setDisableBtn(true)
    console.log(bodyToSend)
    setFile(e.target.files[0])
    setBodyToSend({ ...bodyToSend, docUpload: true })
    
  }

  const handleInvestChecked = (e, investment) => {
    let investArr = bodyToSend.investment
    if (investArr.includes(investment)) {
      investArr.splice(investArr.indexOf(investment), 1)
    } else {
      investArr.push(investment)
    }
    if (investArr.length > 0 && bodyToSend.dateContrat !== null && file !== null) {
      setDisableBtn(false)
    }
    else if (investArr.length === 0 || bodyToSend.dateContrat  === "" || file === null) {
      setDisableBtn(true)
    }

    setBodyToSend({ ...bodyToSend, investment: investArr })
  }
  
  const hideInvestments=()=>{
    let investArr = bodyToSend.investment
    let newInvestments = investments.filter(investment => !investArr.includes(investment._id))
    setInvestments(newInvestments)
    setDisableBtn(true)
  }

  const handleFormSubmit = (e) => {
    if (bodyToSend && file) {
      e.preventDefault()
      let confirm = window.confirm("Êtes vous sûre de vouloir valider cet investissement ? ")
      resetDate.current.value = "";
      resetFile.current.value = null ;
      const formData = new FormData()
       
      if (file !== null) {
        formData.append("file", file)
      }
      formData.append("body", JSON.stringify(bodyToSend))
      if (confirm) {
        // Display the key/value pairs
        // for (var pair of formData.entries()) {
        //   console.log(pair[0] + ', ' + pair[1]);
        // }
        axios.patch(`${apiPath}investment/batch`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-auth-token': user.token
          }
        }).then((res) => {
                   //? we should generate the csv here ... 
          //  setCsvLink(`${apiPath}${res.data}`)
          hideInvestments()
          setBodyToSend({...bodyToSend, investment: [], dateContrat: null})
          setRedirect(true)

        }).catch((err) => {
          setError(true)
        })
        return;
      }
    } else {
      e.preventDefault()
      setError(true)
    }
  }
  // console.log(investments.filter(investment => investment.status === "not processed" && investment.fond === fondRealEstate._id).length !== 0)

// const handleFileDelete = (e) => {
//   e.preventDefault()
//   resetFile.current.value = null;
//   setBodyToSend({ ...bodyToSend, docUpload: false })
//   setFile(null)
//   console.log(bodyToSend)
//   }
  //! fonction pour générer une couleur aléatoire de Rabah
  // const getRandomColor = () => {
  //   const codes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, "A", "B", "C", "D", "E", "F"];
  //   let hexaCode = "";
  //   for (let i = 0; i < 6; i++)
  //     hexaCode += codes[Math.floor(Math.random() * codes.length)];
  //   return `#${hexaCode}`;
  // };

  const colors = ['red', 'green', 'blue', 'purple', 'orange', 'pink', 'cyan', 'magenta', 'teal', 'lime', 'olive', 'maroon', 'navy', 'brown', 'black', 'gray', 'silver', 'gold', 'tan', 'beige', 'salmon', 'plum', 'peach'];
    let currentIndex = 0;

  const getNextColor = () => {
      const nextColor = colors[currentIndex];
      currentIndex = (currentIndex + 1) % colors.length;
      return nextColor;
    }

  return (
    <>
    {/* {console.log(file)} */}
     
      
      <form onSubmit={handleFormSubmit}>

        <div className="_wait-investement-view">
          <div className="_wait-list-Users">

            <FormSectionTitle
              title="liste des investissements à valider"
              classes="-simple-2 _users_title"
            />
            {redirect &&
            <div className="_info-bar-ontainer">
            <InfosBar
            content = {`Le traitement des investissements a bien été pris en compte. Vous allez recevoir par email le fichier correspondant au registre.`}
            />    
            </div>}
                   
            <div className="_wait-invest-users_container ">
              <div className=" -wait-invest">
                <span>INVESTISSEURS</span>
                <span>MONTANT</span>
                <span>SOCIÉTÉ</span>
                <span>DATE</span>
                <span>STATUT</span>
              </div>
              <ul className="_wait-invest-users_list">

                { (investments.filter(investment => investment.status === "not processed" && investment.fond === fondRealEstate._id).length !== 0) ?
                
                

                (investments.map(investment => {
                  return (
                    <> 
                      {  investment.status === "not processed" && investment.fond === fondRealEstate._id &&
                        <li key={investment._id} className="-wait-validation-list">
                          {/* {console.log((investment.status === "not processed").length)} */}
                          <div data-label="checkbox :" className="-checkbox"><label htmlFor={`chk-${investment._id}`}><input type="checkbox" id={`chk-${investment._id}`} onChange={(e) => handleInvestChecked(e, investment._id)} /></label></div>
                          <div className="_picto">
                          {users.map (user => user._id === investment.user ? <a style={{ backgroundColor: getNextColor() }}>
                              {`${
                                user != null
                                  ? user.lastname.charAt(0).toUpperCase()
                                  : "-"
                              }${
                                user != null
                                  ? user.firstname
                                      .charAt(0)
                                      .toUpperCase()
                                  : "-"
                              }`}
                            </a> :"")}
                           
                          </div>
                          <div data-label="UTILISATEUR :">
                         
                            {users.map((user) => user._id === investment.user ? user.firstname + " " + user.lastname : "")}
                          </div>
                          <div data-label="MONTANT :">{roundNumber(investment.montant).toLocaleString('en-EU').replaceAll(',', ' ')}€</div>
                          <div data-label="SOCIÉTÉ :">{fonds.map((fond) => fond._id === investment.fond && fond.name === "Real-Estate" && fond.name)}</div>
                          <div data-label="DATE :">{new Date(investment.datePaiement).toLocaleDateString()}</div>
                          <div className={investment.status === "processed" ? "-succes" : "-waiting"} data-label="STATUT :">
                            {investment.status === "processed" ? "validé" : "à traiter"}
                          </div>
                          {/* <Link className="_icon"
                      to={`/dashboard/real-wait-invests/${investment._id}`}
                    >
                      <IconArrowColored/>
                      <p>Voir la fiche</p>
                    </Link> */}
                        </li>
                      }
                    </> 

                  )
                })):<p className="_no-invest">Aucun investissement à valider</p> 
               
                }
              </ul>
            </div>
            <div className="_validation-container">
              <div className="contract-validation-date">
                <p> Renseigner la date de validation du contrat </p>
                <input 
                type="date" 
                onChange={(e) => handleDispatchChange(e)}
                value={bodyToSend.dateContrat || ''} 
                ref={resetDate} 
                />
              </div>
              <div className="contract-validation-doc">
                <p > Renseingez Le document notariale :</p>
                <input
                  className="doc-input"
                  type='file'
                  ref={resetFile}
                  onChange={handleObligationChange}  />

                {/* <button onClick={(e)=>handleFileDelete(e)}>delete</button> */}
                  
                <span>{`formats acceptés : pdf, jpg, png < 2 Mo`} </span>

              </div>
             
              
              <Button
              classes={"btn-validation"}
               type="submit"
               label={"Valider les investissements"}
                // iconPlus
                disable = {disableBtn? true : false}
                 />
               
               
            </div>
          </div>
        </div>
      </form>
      
    </>
  )
}
export default WaitInvestView
