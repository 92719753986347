import FormSectionTitle from "../../components/formSectionTitle"
import IconArrowColored from '../../assets/svg/navbar-arrow-colored';
import { useEffect, useState } from "react"
import apiPath from '../../constants/apiPaths';
import { useSelector} from "react-redux";
import axios from "axios";
import { Link } from 'react-router-dom';
const RequestListView = ()=>{

  const { user} = useSelector(state => state.persistedReducer);
  const headers = {
    headers: {
      "x-auth-token" : user.token,
    }
  }

  useEffect(()=>{
   
    axios.get(`${apiPath}request/`,headers)
    .then((res)=>{
      console.log(res.data)
      setRequest(res.data)

      
    })

    .catch((err)=>{

    })
  },[])

  const [request, setRequest] = useState([])

  return(
    <div className="_listUsersContainer">
      <div className="_listUsers">
        <FormSectionTitle
                title="liste des demandes à valider"
                classes="-simple-2 _users_title"
              />
      
      <div className="_users_container ">
  
            <div className="-invest">
                    <span>NOM</span>
                    <span>PRÉNOM</span>
                    <span>MONTANT LEVÉ DIRECT</span>
                    <span>DATE DE DEMANDE</span>
                    <span>STATUT</span>
              </div>
               
        <ul className="_users_list">
        { request && request.map(request=>{
       
       return (
        
          request.status === "open" &&<>
          {
          
          <li key={request.id} className=" -investement">
           
           <div data-label="NOM :">
           {request.user.firstname }
           </div>
           <div data-label="PRÉNOM :">{ request.user.lastname }</div>
           <div data-label="MONTANT LEVÉ DIRECT :">{request.montantLeve} €</div>
           <div data-label="DATE DE DEMANDE :">{new Date (request.date).toLocaleDateString()}</div>
           <div className={request.status === "processed" ? "-succes": "-waiting"} data-label="STATUT :">
             {request.status === "processed" ? "validé": "à traiter"}
           </div>
           <Link className="_icon"
             to={`/request/${request._id}`}
            >
             <IconArrowColored/>
             <p>Voir la fiche</p>
           </Link>

           </li>}
           
           </>
         
       ) 
     })
   }
        </ul>
        </div>
      </div>
    </div>
  )
}
export default RequestListView