import { Component } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import apiPath from "../../constants/apiPaths";






import Card from "../../components/card";
import { roundNumber } from "../../helpers/roundNumberFunc";

class EditUserInvestmentView extends Component{
  constructor(props){
    super(props)
    this.state = {
      token : props.token,
      montantEstate:0,
      montantCapitalisation:0,
      montantPrelever:0,
    }
  }



  componentDidMount(){

    const { id } = this.props.params;
    const headers = {headers: {"x-auth-token": this.props.token}};

    axios.get(`${apiPath}investment/user/all/${id}`, headers)
    .then((res) => {
      let investmentss =res.data
     
      axios.get(`${apiPath}fonds/display`, headers)
      .then((res)=>{
        let fonds = res.data
        let fondRealEstate = (fonds.filter(i=>i.name==="Real-Estate"))
      //  console.log(fondRealEstate)
       let fondCapitalisation = fonds.filter(i=>i.name==="Capitalisation")
       const fondPrelever = fonds.filter(i=>i.name==="Vizio-group")
      //  console.log(fonds);
       const montantEstate = investmentss.map((i)=>i.fond === fondRealEstate[0]._id && (roundNumber (i.montant))).reduce((prev,next)=> prev+next,0)
       const montantCapitalisation = investmentss.map((i)=>i.fond === fondCapitalisation[0]._id && (roundNumber (i.montant))).reduce((prev,next)=> prev+next,0)
       const montantPrelever = investmentss.map((i)=>i.fond === fondPrelever[0]._id && (roundNumber (i.montant))).reduce((prev,next)=> prev+next, 0)
      //  console.log(montantPrelever);
       this.setState({
         montantEstate : montantEstate,
         montantCapitalisation: montantCapitalisation,
         montantPrelever:montantPrelever
       })
      })
      
    
    })
    .catch(err => console.err(err));

  }



  render(){
    const MontantEstate = this.state.montantEstate
    const MontantCapitalisation = this.state.montantCapitalisation
    const montantPrelever = this.state.montantPrelever
    return(
   
      <>
  <div className='-big-card-container'>
    <h3 className="title">Vizio real estate</h3>
    <div className="dashboard-container -card-container">
      <Card
      classes={"first-elmt"}
      header={"Mes investissements"}
      amount={roundNumber(MontantEstate) ? roundNumber(MontantEstate).toLocaleString('en-EU').replace(',', ' ') + "€" : "0€"}
   
      text={"Montant des investissements dans Vizio Real estate"}
      />

      <Card
      header={"Mes retours sur investissement"}
      amount={"0€"}

      text={"Mes retours sur investissements dans Vizio Real Estate"}
      />

      <Card
      header={"Total des retours sur investissement"}
      amount={"0€"}
      text={"Total de mes retours sur investissement dans Vizio Real Estate"}
      />
     
      
      </div>
      </div>
    {/*  */}
    <div className='-big-card-container'>
    <h3 className="title">Vizio Capitalisation</h3>
    <div className="dashboard-container -card-container">
      <Card
      classes={"first-elmt"}
        header={"Mes investissements"}
        amount={roundNumber(MontantCapitalisation) ? roundNumber(MontantCapitalisation).toLocaleString('en-EU').replace(',', ' ') + "€" : "0€"}

        text={"Montant des investissements dans dans Vizio Capitalisation"}
        />

        <Card
        header={"Mes retours sur investissement"}
        amount={"0€"}

        text={"Mes retours sur investissements dans Capitalisation"}

        />

        <Card
        header={"Total des retours sur investissement"}
        amount={"0€"}
        text={"Total de mes retours sur investissement dans Vizio Capitalisation"}
        />
    </div>

    <div className='-big-card-container'>
    <h3 className="title">Vizio Group</h3>
    <div className="dashboard-container -card-container">
      <Card
      classes={"first-elmt"}
        header={"Mon capital"}
        amount={roundNumber(montantPrelever) ? roundNumber(montantPrelever).toLocaleString('en-EU').replaceAll(',', ' ') + "€" : "0€"}
        
        text={"Mon capital investi dans Vizio Group"}
        // btnlabel={"Investir"}
        // btnLink="/invest"
        />

        <Card
        header={"Mes plus values disponibles"}
        amount={"0€"}
        btnDisable={true}
        text={"Mes plus values disponibles dans Vizio Group"}
        // btnlabel={"Retirer"}
        // btnLink ="/investissements/withdraw"
        />

        <Card
        header={"Total plus value"}

        amount={"0€"}
        text={"Total plus value dans  Vizio Group"}
        />
    </div>
    </div>
    </div>
  </>
   
    )
  }
}

// Utilisé pour accéder aux hooks de react-rooter-v6 dans un composant type classe
export default (props) => {
  const params = useParams();
  const { token } = useSelector(state => state.persistedReducer.user);
  return <EditUserInvestmentView params={params} token={token} {...props} />
}