import React, { Component } from "react";
import axios from "axios";

import FormRow from "../components/formRow";
import FormRowCheckbox from "../components/formRowCheckbox";
import InputText from "../components/inputText";
import InputPassword from "../components/inputPassword";
import InputSelect from "../components/inputSelect"
import InputDate from "../components/inputDate";
import Button from "../components/button";

import apiPath from "../constants/apiPaths";
import { Navigate } from "react-router-dom";

class CreateUser extends Component {

  constructor(props) {
    super(props);
    this.state = {
      bodyToSend: { type: "Particulier", haveDiferentFiscalCountry:false , entreprise :{}, parrain_link : "false" },
      personneToContact:false,
      redirect:false,
    }
  }

  handleTypeChange = (e) => {
    this.setState({ bodyToSend: { ...this.state.bodyToSend, type: e.target.value } })
  }
  handleEmailChange = (e) => {
    this.setState({ bodyToSend: { ...this.state.bodyToSend, email: e.target.value } })
  }
  handlePasswordChange = (e) => {
    this.setState({ bodyToSend: { ...this.state.bodyToSend, password: e.target.value } })
  }
  handleFirstnameChange = (e) => {
    this.setState({ bodyToSend: { ...this.state.bodyToSend, firstname: e.target.value } })
  }
  handleLastnameChange = (e) => {
    this.setState({ bodyToSend: { ...this.state.bodyToSend, lastname: e.target.value } })
  }
  handleDateOfBirthChange = (e) => {
    this.setState({ bodyToSend: { ...this.state.bodyToSend, dateOfBirth: e.target.value } })
  }
  handleAddressChange = (e) => {
    this.setState({ bodyToSend: { ...this.state.bodyToSend, address: e.target.value } })
  }
  handleCityChange = (e) => {
    this.setState({ bodyToSend: { ...this.state.bodyToSend, city: e.target.value } })
  }
  handleCodePostalChange = (e) => {
    this.setState({ bodyToSend: { ...this.state.bodyToSend, zipCode: e.target.value } })
  }
  handleCountryChange = (e) => {
    this.setState({ bodyToSend: { ...this.state.bodyToSend, country: e.target.value } })
  }
  handlePhoneChange = (e) => {
    this.setState({ bodyToSend: { ...this.state.bodyToSend, phone: e.target.value } })
  }
  handleAvatarChange= (e)=>{
    this.setState({file: e.target.files[0]})
  }
  handleEntNameChange = (e) => {
    this.setState({ bodyToSend: { ...this.state.bodyToSend, entreprise: { ...this.state.bodyToSend.entreprise, name: e.target.value } } })
  }
  handleEntFormChange = (e) => {
    this.setState({ bodyToSend: { ...this.state.bodyToSend, entreprise: { ...this.state.bodyToSend.entreprise, jurForm: e.target.value } } })
  }
  handleSiretChange = (e) => {
    this.setState({ bodyToSend: { ...this.state.bodyToSend, entreprise: { ...this.state.bodyToSend.entreprise, siret: e.target.value } } })
  }
  handleRcsCityChange = (e) => {
    this.setState({ bodyToSend: { ...this.state.bodyToSend, entreprise: { ...this.state.bodyToSend.entreprise, rcsCity: e.target.value } } })
  }
  handleEntAddressChange = (e) => {
    this.setState({ bodyToSend: { ...this.state.bodyToSend, entreprise: { ...this.state.bodyToSend.entreprise, address: e.target.value } } })
  }
  handleEntCodePostalChange = (e) => {
    this.setState({ bodyToSend: { ...this.state.bodyToSend, entreprise: { ...this.state.bodyToSend.entreprise, zipCode: e.target.value } } })
  }
  handleEntCityChange = (e) => {
    this.setState({ bodyToSend: { ...this.state.bodyToSend, entreprise: { ...this.state.bodyToSend.entreprise, city: e.target.value } } })
  }
  handleEntCountryChange = (e) => {
    this.setState({ bodyToSend: { ...this.state.bodyToSend, entreprise: { ...this.state.bodyToSend.entreprise, country: e.target.value } } })
  }
  handleFiscaliteChangeTrue=(e)=>{
    this.setState({ bodyToSend: {...this.state.bodyToSend, haveDiferentFiscalCountry: false}})
  }
  handleFiscaliteChangeFalse=(e)=>{
    this.setState({bodyToSend: {...this.state.bodyToSend, haveDiferentFiscalCountry: true}})
  }

  handleContactAdd=()=>{
    if(this.state.bodyToSend.personneToContact){
      var contacts = [...this.state.bodyToSend.personneToContact].filter(
        (contact)=> contact !==this.state.contactToEdit
      )
    }else{
      var contacts = [];
    }
    contacts.push(this.state.contactToEdit);
    this.setState({bodyToSend: {...this.state.bodyToSend, personneToContact: contacts}})
  }

  handleAddPersonnesToContact=(e)=>{
    this.setState({personneToContact: !this.state.personneToContact})
    if(e.target.checked === false){
      this.setState({contactToEdit : null})
      this.setState({bodyToSend: {... this.state.bodyToSend, personneToContact: undefined}})
    }
    else this.setState({contactToEdit: {
      firstname:"",
      lastname:"",
      email:"",
      address:"",
      zipCode:"",
      city:"",
      country:"",
      phone:""}})
  }
  handleContactSet=(id)=>{
    this.setState({contactToEdit: this.state.bodyToSend.personneToContact[id]?this.state.bodyToSend.personneToContact[id]:{
      firstname:"",
      lastname:"",
      email:"",
      address:"",
      zipCode:"",
      city:"",
      country:"",
      phone:""
    }})
  }

  handleContactDel=(id)=>{
    var contacts = this.state.bodyToSend.personneToContact.filter(
      (contact) => contact !== this.state.bodyToSend.personneToContact[id]
    )
    this.setState({bodyToSend: {...this.state.bodyToSend, personneToContact: contacts}})
  }
  handleContactFirstname=(e)=>{
    this.setState({contactToEdit: {...this.state.contactToEdit, firstname : e.target.value}})
  }
  handleContactLastname=(e)=>{
    this.setState({contactToEdit: {...this.state.contactToEdit,  lastname: e.target.value}})
  }
  handleContactEmail=(e)=>{
    this.setState({contactToEdit: {...this.state.contactToEdit,  email: e.target.value}})
  }
  handleContactAddresse=(e)=>{
    this.setState({contactToEdit: {...this.state.contactToEdit,  address: e.target.value}})
  }
  handleContactZipCode=(e)=>{
    this.setState({contactToEdit: {...this.state.contactToEdit,  zipCode: e.target.value}})
  }
  handleContactCity=(e)=>{
    this.setState({contactToEdit: {...this.state.contactToEdit,  city: e.target.value}})
  }
  handleContactCountry=(e)=>{
    this.setState({contactToEdit: {...this.state.contactToEdit,  country: e.target.value}})
  }
  handleContactPhone=(e)=>{
    this.setState({contactToEdit: {...this.state.contactToEdit,  phone: e.target.value}})
  }

  handleSubmit = (e) => {
    e.preventDefault()
    console.log(this.state.bodyToSend)
    const formData= new FormData()
    if(this.state.file){
      formData.append("file",this.state.file)
    }
    formData.append("body",JSON.stringify(this.state.bodyToSend))
    this.setState({ error: null })
    if ( //si un champ est manquant :
      this.state.bodyToSend.type === '' ||
      this.state.bodyToSend.type == null ||
      this.state.bodyToSend.email == null || //si champ manquant dans le this.state.bodyToSend
      this.state.bodyToSend.email === '' || //champ vide dans le this.state.bodyToSend
      this.state.bodyToSend.password == null ||
      this.state.bodyToSend.password === '' ||
      this.state.bodyToSend.lastname == null ||
      this.state.bodyToSend.lastname === '' ||
      this.state.bodyToSend.firstname == null ||
      this.state.bodyToSend.firstname === '' ||
      this.state.bodyToSend.dateOfBirth == null ||
      this.state.bodyToSend.dateOfBirth === '' ||
      this.state.bodyToSend.address == null ||
      this.state.bodyToSend.address === '' ||
      this.state.bodyToSend.city == null ||
      this.state.bodyToSend.city === '' ||
      this.state.bodyToSend.zipCode == null ||
      this.state.bodyToSend.zipCode === '' ||
      this.state.bodyToSend.country == null ||
      this.state.bodyToSend.country === '' ||
      this.state.bodyToSend.phone == null ||
      this.state.bodyToSend.phone === '' ||
      (this.state.bodyToSend.type === "Entreprise" && ( // si c'est une entreprese, on vérif que les champ soit bien rempli... 
        this.state.bodyToSend.entreprise.name == null ||
        this.state.bodyToSend.entreprise.name === '' ||
        // this.state.bodyToSend.entreprise.jurForm == null ||
        // this.state.bodyToSend.entreprise.jurForm === '' ||
        this.state.bodyToSend.entreprise.siret == null ||
        this.state.bodyToSend.entreprise.siret === '' ||
        // this.state.bodyToSend.entreprise.rcsCity == null ||
        // this.state.bodyToSend.entreprise.rcsCity === '' ||
        this.state.bodyToSend.entreprise.address == null ||
        this.state.bodyToSend.entreprise.address === '' ||
        this.state.bodyToSend.entreprise.zipCode == null ||
        this.state.bodyToSend.entreprise.zipCode === '' ||
        this.state.bodyToSend.entreprise.city == null ||
        this.state.bodyToSend.entreprise.city === '' ||
        this.state.bodyToSend.entreprise.country == null ||
        this.state.bodyToSend.entreprise.country === ''
      ))
    ) {
      this.setState({ donneManque: true })
    } else {
      this.setState({ donneManque: false })
      // axios.put(`${apiPath}users/`, this.state.bodyToSend)
      axios.put(`${apiPath}users/`, formData, {
        headers: {'Content-Type': 'multipart/form-data'}
      })
        .then(res => {
          console.log(res)
          if (res.status === 200) {
           this.setState({redirect: true})
          }
        })
        .catch(err => {
          console.log(err)
          this.setState({ error: err.response.data })
        })
    }
  }

  render() {

    if(this.state.redirect) return <Navigate to={"/dashboard/usersList"} replace={true}/>

    const personnes = this.state.bodyToSend.personneToContact ? this.state.bodyToSend.personneToContact : [];

    return (
      <div className="layout-dashboard-container">
        <div className="dashboard-container">
          <div className="dashboard-scontainer">
          
            {
              this.state.donneManque === true
              && <p>Des Données sont manquantes </p>
            }

            {
              this.state.error
              && <p>{this.state.error}</p>
            }

            <form onSubmit={this.handleSubmit} encType="multipart/form-data">
              <div className="section-title">
                <h4 className="-texte">
                  Informations personnelles
                </h4>
              </div>
              <FormRow classes={"One"}>
              <InputSelect 
                label="type"
                inputs= {
                  [
                    {
                      id: "Particulier",
                      label: "Particulier",
                      value: "Particulier"
                    },
                    {
                      id:"Entreprise",
                      label: "Entreprise",
                      value: "Entreprise"
                    }
                  ]
                }
                value={this.state.bodyToSend.type}
                handleSelectChange={this.handleTypeChange}
              />
              </FormRow>
              <FormRow classes={"-two"}>
              <InputText
                label="Email : "
                name="email"
                placeholder="email@email.fr"
                errorMessage="email Invalide"
                value={this.state.bodyToSend.email}
                handleInputChange={this.handleEmailChange}
              />
              <InputPassword 
                label="Mot de passe : "
                placeholder="Mot de passe"
                value={this.state.handlePasswordChange}
                handleInputChange={this.handlePasswordChange}
              />
              </FormRow>
              <FormRow classes={"-two"}>
              <InputText
                label="Nom : "
                name="lastname"
                placeholder="Nom"
                errorMessage="Nom Invalide"
                value={this.state.bodyToSend.lastname}
                handleInputChange={this.handleLastnameChange}
              />
              <InputText
                label="Prénom : "
                name="firstname"
                placeholder="Prénom"
                errorMessage="Prénom invalide"
                value={this.state.bodyToSend.firstname}
                handleInputChange={this.handleFirstnameChange}
              />
              </FormRow>
              <FormRow classes={"-two"}>
              <InputDate
                label="Date de naissance : "
                // name="dateOfBirth"
                placeholder="JJ/MM/AAAA"
                errorMessage="date de naissance Invalide"
                value={this.state.bodyToSend.dateOfBirth}
                handleDateChange={this.handleDateOfBirthChange}
              />
              <InputText
                label="Pays : "
                placeholder="Pays"
                errorMessage="Pays invalide"
                value={this.state.bodyToSend.country}
                handleInputChange={this.handleCountryChange}
              />
              </FormRow>
              <FormRow classes="-two">
              <InputText
                label="Numéro de téléphone : "
                type="tel"
                placeholder="0123456789"
                errorMessage="Numéro de téléphone invalide"
                value={this.state.bodyToSend.phone}
                handleInputChange={this.handlePhoneChange}
              />
              <InputText
                label="Adresse postal : "
                name="address"
                placeholder="adresse"
                errorMessage="Adresse invalide"
                value={this.state.bodyToSend.address}
                handleInputChange={this.handleAddressChange}
              />
              </FormRow>
              <FormRow classes="-two">          
              <InputText
                label="Ville : "
                type="tel"
                placeholder="Ville"
                errorMessage="ville invalide"
                value={this.state.bodyToSend.city}
                handleInputChange={this.handleCityChange}
              />
              <InputText
                label="Code Postal : "
                name="zipCode"
                type="number"
                placeholder="Code Postal"
                errorMessage="Code postal invalide"
                value={this.state.bodyToSend.zipCode}
                handleInputChange={this.handleCodePostalChange}
              />
              </FormRow>
              {/* <span>Avatar : </span>
              <input 
                type='file'
                lable='Avatar'
                onChange={this.handleAvatarChange}
              /> */}
              <FormRowCheckbox 
                // classes
                isActive ={this.state.personneToContact}
                label = "Ajouter des personnes a contacter"
                // style
                handleCheckboxChange={this.handleAddPersonnesToContact}
                // error
              />
              <br/>
              {
                this.state.personneToContact &&
                <>
                  <div className="section-title">
                    <h4 className="-texte">
                      Personnes a contacter en cas d'indisponibilité
                    </h4>
                  </div>
                  <ul className="contact-list">
                    {personnes.map((contact, id) => {
                      return (
                        <li key={id} className="contact">
                          <button
                            type="button"
                            className="-name"
                            label={(contact.firstname?contact.firstname:"nouveau")+(contact.lastname?contact.lastname:"contact")}
                            onClick={() => this.handleContactSet(id)}
                          >
                            {contact.firstname?contact.firstname:"nouveau"} {contact.lastname?contact.lastname:"contact"}
                          </button>
                          
                          <button
                            type="button"
                            // className="-small"
                            className="-delete"
                            onClick={()=>this.handleContactDel(id)}
                          >
                            X
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                  <FormRow classes={"-two"}>
                    <InputText
                      label="Nom"
                      name="Nom"
                      placeholder="Nom"
                      errorMessage="Nom invalide"
                      value={this.state.contactToEdit.lastname?this.state.contactToEdit.lastname:""}
                      handleInputChange={this.handleContactLastname}
                    />
                    <InputText
                      label="Prenom"
                      name="Prenom"
                      placeholder="Prenom"
                      errorMessage="Prenom invalide"
                      value={this.state.contactToEdit.firstname?this.state.contactToEdit.firstname:""}
                      handleInputChange={this.handleContactFirstname}
                    />
                  </FormRow>
                  <FormRow classes={"-two"}>
                    <InputText
                      label="email"
                      name="email"
                      placeholder="email"
                      errorMessage="email invalide"
                      value={this.state.contactToEdit.email?this.state.contactToEdit.email:""}
                      handleInputChange={this.handleContactEmail}
                    />
                    <InputText
                      label="adresse"
                      name="adresse"
                      placeholder="adresse"
                      errorMessage="adresse invalide"
                      value={this.state.contactToEdit.address?this.state.contactToEdit.address:""}
                      handleInputChange={this.handleContactAddresse}
                    />
                  </FormRow><FormRow classes={"-two"}>
                    <InputText
                      label="Code postal"
                      name="Code postal"
                      placeholder="Code postal"
                      errorMessage="Code postal invalide"
                      value={this.state.contactToEdit.zipCode?this.state.contactToEdit.zipCode:""}
                      handleInputChange={this.handleContactZipCode}
                    />
                    <InputText
                      label="Ville"
                      name="Ville"
                      placeholder="Ville"
                      errorMessage="Ville invalide"
                      value={this.state.contactToEdit.city?this.state.contactToEdit.city:""}
                      handleInputChange={this.handleContactCity}
                    />
                  </FormRow>
                  <FormRow classes={"-two"}>
                    <InputText
                      label="Pays"
                      name="Pays"
                      placeholder="Pays"
                      errorMessage="Pays invalide"
                      value={this.state.contactToEdit.country?this.state.contactToEdit.country:""}
                      handleInputChange={this.handleContactCountry}
                    />
                    <InputText
                      label="Téléphone"
                      name="Téléphone"
                      placeholder="Téléphone"
                      errorMessage="Téléphone invalide"
                      value={this.state.contactToEdit.phone?this.state.contactToEdit.phone:""}
                      handleInputChange={this.handleContactPhone}
                    />
                  </FormRow>
                  <Button 
                    label={"Ajouter le contact"}
                    type="button"
                    handleClick={this.handleContactAdd}
                    classes="-small -sub"
                  />
                  <br />
                </>
              }
              {
                this.state.bodyToSend.type === "Entreprise" &&
                <>
                  <div className="section-title">
                    <h4 className="-texte">
                      Informations de votre entreprise
                    </h4>
                  </div>
                  <FormRow classes={"-two"}>
                  <InputText
                    label="Dénomination sociale : "
                    name="name"
                    placeholder="Nom de l'entreprise"
                    errorMessage="Nom Invalide"
                    value={this.state.bodyToSend.entreprise.name}
                    handleInputChange={this.handleEntNameChange}
                  />
                  <InputText
                    label="Siret : "
                    name="siret"
                    placeholder="Numéro siret"
                    errorMessage="siret invalide"
                    value={this.state.bodyToSend.entreprise.siret}
                    handleInputChange={this.handleSiretChange}
                  />
                  </FormRow>
                  <FormRow classes="-two">
                  <InputText
                    label="Adresse du siège : "
                    name="address"
                    placeholder="Adresse du siège"
                    errorMessage="Adresse invalide"
                    value={this.state.bodyToSend.entreprise.address}
                    handleInputChange={this.handleEntAddressChange}
                  />
                  <InputText
                    label="Code postal : "
                    name="zipCode"
                    type="number"
                    placeholder="Code Postal"
                    errorMessage="Code postal invalide"
                    value={this.state.bodyToSend.entreprise.zipCode}
                    handleInputChange={this.handleEntCodePostalChange}
                  />
                  </FormRow>
                  <FormRow classes={"-two"}>
                  <InputText
                    label="Commune : "
                    name="city"
                    placeholder="Commune"
                    errorMessage="Commune invalide"
                    value={this.state.bodyToSend.entreprise.city}
                    handleInputChange={this.handleEntCityChange}
                  />
                  <InputText
                    label="Pays : "
                    placeholder="Pays"
                    errorMessage="Pays invalide"
                    value={this.state.bodyToSend.entreprise.country}
                    handleInputChange={this.handleEntCountryChange}
                  />
                  </FormRow>
                </>
              }
              <div className="section-title">
                <h4 className="-texte">
                  Ma fiscalité
                </h4>
              </div>
              <div>
                <label>Votre adresse fiscale est-elle identique à votre adresse postale?</label><br/>
                <input type={"radio"} checked={this.state.bodyToSend.haveDiferentFiscalCountry === false} onChange={this.handleFiscaliteChangeTrue}/>Oui
                <input type={"radio"} checked={this.state.bodyToSend.haveDiferentFiscalCountry === true} onChange={this.handleFiscaliteChangeFalse}/>Non
              </div>
              {
                this.state.bodyToSend.haveDiferentFiscalCountry === true &&
                <>
                <div>
                  <p>Vous pourrez téléverser votre fiche fiscal de référence dans votre compte utilisateur. <br/>
                    Cette action peut vous permettre de faire des économies d'impôts.
                  </p>
                </div>
                </>
              }
              <br />
              <Button type="submit" classes={"-small"} label="Ajouter l'utilisateur"/>
            </form>

          </div>
        </div>
      </div>
    )
  }
}
export default CreateUser