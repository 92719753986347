export default () => (
    <svg viewBox="0 0 32 30" >
        <g id="-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Inscription2-particulier1" transform="translate(-250.000000, -442.000000)" fill="#FFC542" fillRule="nonzero" stroke="#FFC542">
                <g id="noun-light-3725467" transform="translate(250.563559, 443.161017)">
                    <path d="M18.7023183,14.1225129 C18.5668917,14.1220105 18.436851,14.0598398 18.3401487,13.9490409 C17.6969689,13.1903987 16.8197995,12.765621 15.9059997,12.7697084 C15.6239664,12.7697084 15.3953574,12.5028769 15.3953574,12.1733909 C15.3953574,11.8439048 15.6239664,11.5768239 15.9059997,11.5768239 C17.0906846,11.5744127 18.2272887,12.1235558 19.064488,13.1028483 C19.2636919,13.3367764 19.2636919,13.7148898 19.064488,13.9490594 C18.9677857,14.0598583 18.837745,14.1220298 18.7023183,14.1225129 L18.7023183,14.1225129 Z" id="Path"></path>
                    <polygon id="Path" points="11.4556006 21.6383567 17.8198231 21.6383567 17.8198231 22.9112012 11.4556006 22.9112012"></polygon>
                    <path d="M15.2740713,28.0025792 C14.2377046,28.0016305 13.244275,27.6010299 12.5119059,26.889003 C11.7794743,26.1767756 11.3681025,25.2111703 11.3681025,24.204337 L11.3681025,23.2081903 C11.3681025,22.2594646 10.8537004,21.2801368 9.79317766,20.2227859 C8.39206388,18.8198863 7.61744509,16.9364002 7.63744493,14.9818128 C7.65770852,13.0269825 8.47118825,11.1589189 9.9011143,9.7837676 C11.3310403,8.40861631 13.2618999,7.63706706 15.2741338,7.63706706 C17.2863676,7.63706706 19.2172272,8.40861631 20.6471532,9.7837676 C22.0770793,11.1589189 22.8905728,13.0269825 22.9108226,14.9818128 C22.9308418,16.9364002 22.1562036,18.8198863 20.7550899,20.2227859 C19.7050358,21.2801368 19.1835399,22.263047 19.1835399,23.2081903 L19.1835399,24.204337 C19.1828075,25.2114131 18.7704558,26.1769578 18.0375491,26.889003 C17.3046175,27.6012303 16.3107505,28.0016077 15.2741338,28.0025792 L15.2740713,28.0025792 Z M15.2740713,8.96127019 C13.620522,8.9591409 12.0331597,9.59195804 10.8573566,10.7216247 C9.68180355,11.8512913 9.01337141,13.3866178 8.99749654,14.9930457 C8.98187166,16.5992306 9.6200478,18.1466402 10.7731073,19.2981047 C12.0926592,20.6173343 12.7330291,21.8959433 12.7330291,23.208251 L12.7330291,24.2043978 C12.7330291,25.0864546 13.2174002,25.9014175 14.0035189,26.3423548 C14.7896376,26.7832922 15.7583799,26.783274 16.5444986,26.3423548 C17.3306173,25.9014357 17.8149884,25.0864546 17.8149884,24.2043978 L17.8149884,23.208251 C17.8149884,21.8957004 18.4553583,20.6172736 19.7749102,19.2981047 C20.9233385,18.1460937 21.5575834,16.6006272 21.5402711,14.9970531 C21.5226931,13.393479 20.8549641,11.8612491 19.681661,10.732979 C18.5085454,9.60495179 16.9246205,8.9719013 15.2740088,8.97141556 L15.2740713,8.96127019 Z" id="Shape"></path>
                    <path d="M4.41367155,25.4568902 C4.25366318,25.4568902 4.10053304,25.3925404 3.98863384,25.2784442 C3.76183343,25.0410277 3.76183343,24.6674161 3.98863384,24.4300106 L6.50103257,21.9241312 C6.60133058,21.7662818 6.76756841,21.6626942 6.95356124,21.6421051 C7.13933964,21.6215161 7.32447476,21.6865001 7.45655782,21.8183968 C7.58885531,21.9505071 7.65393191,22.1353803 7.63331369,22.3208959 C7.61269547,22.5064115 7.50874666,22.6726227 7.35088807,22.7725702 L4.83848934,25.2784496 C4.72681007,25.3929742 4.57346,25.4573163 4.41366605,25.4568902 L4.41367155,25.4568902 Z" id="Path"></path>
                    <path d="M4.29003592,15.2582055 L0.509984373,15.2582055 C0.211536757,15.1922744 0,14.9347785 0,14.6377649 C0,14.3407513 0.211542611,14.0832554 0.509984373,14.0170971 L4.29003592,14.0170971 C4.48465301,13.9742544 4.6886491,14.0195389 4.84462028,14.1402987 C5.00059147,14.2610567 5.09137804,14.4441941 5.09137804,14.6377593 C5.09137804,14.8313244 5.00058561,15.0144618 4.84462028,15.1352198 C4.6886491,15.2559779 4.48465887,15.3012635 4.29003592,15.2581998 L4.29003592,15.2582055 Z" id="Path"></path>
                    <path d="M7.02264001,7.63706706 C6.85474638,7.63548704 6.69388075,7.56957627 6.57363229,7.45265771 L3.91954686,4.81225378 C3.75664253,4.55651684 3.79385025,4.22222939 4.00894182,4.00802702 C4.2242541,3.79382465 4.56027369,3.7570283 4.81733576,3.91909288 L7.4714212,6.55949681 C7.63818221,6.74503293 7.68310331,7.00889419 7.58758644,7.2386807 C7.49184305,7.46846142 7.27221523,7.62329732 7.02264001,7.63706706 Z" id="Path"></path>
                    <path d="M14.6539721,5.09137804 C14.2997378,5.09137804 14.0101482,4.7986721 14.0012896,4.43137262 L14.0012896,0.526390885 C14.0689066,0.218342025 14.3329868,0 14.6375953,0 C14.9422038,0 15.2065113,0.218348068 15.2741341,0.526390885 L15.2741341,4.44509 C15.2593361,4.7942064 14.9906989,5.07415578 14.6539721,5.09137804 Z" id="Path"></path>
                    <path d="M22.2527839,7.63706706 C22.0032085,7.62329848 21.7835864,7.46845572 21.6878372,7.23868088 C21.5923203,7.00890026 21.637242,6.74503912 21.8040025,6.5594973 L24.4580892,3.91909459 C24.7151514,3.75703008 25.0511712,3.79382064 25.2664836,4.00802869 C25.4815694,4.22223096 25.518783,4.55651825 25.3558786,4.81225508 L22.7017918,7.4526578 C22.5815433,7.5695763 22.4206834,7.63548958 22.2527839,7.63706706 L22.2527839,7.63706706 Z" id="Path"></path>
                    <path d="M29.8679521,15.2582055 L26.0880744,15.2582055 C25.7896405,15.1922744 25.5781135,14.9347785 25.5781135,14.6377649 C25.5781135,14.3407513 25.7896464,14.0832554 26.0880744,14.0170971 L29.8679521,14.0170971 C30.0625602,13.9742544 30.2665469,14.0195389 30.4225109,14.1402987 C30.578475,14.2610567 30.6694915,14.4441941 30.6694915,14.6377593 C30.6694915,14.8313244 30.578475,15.0144618 30.4225109,15.1352198 C30.2665469,15.2559779 30.0625661,15.3012635 29.8679521,15.2581998 L29.8679521,15.2582055 Z" id="Path"></path>
                    <path d="M26.2558255,25.4568902 C26.096037,25.457317 25.942687,25.3929742 25.8310022,25.2784496 L23.3186035,22.7725702 C23.1607449,22.6726282 23.0567961,22.5064114 23.0361779,22.3208959 C23.0155596,22.1353803 23.0806357,21.9505071 23.2129337,21.8183968 C23.3450168,21.6865006 23.5301519,21.6215161 23.7159303,21.6421051 C23.9019231,21.6626942 24.0681555,21.7662818 24.168459,21.9241312 L26.6808577,24.4300106 C26.9076581,24.6674271 26.9076581,25.0410387 26.6808577,25.2784442 C26.5689585,25.3925404 26.4158284,25.4568902 26.25582,25.4568902 L26.2558255,25.4568902 Z" id="Path"></path>
                </g>
            </g>
        </g>
    </svg>
)