const InputTextArea = (props) => {

    const {
      classes,
      value,
      label,
      indication,
      max,
      handleOnChange,
      error,
      style,
      readonly,
    } = props;
  
  
  
    const handleChange = (event) => {
      const value = event.target.value;
      if ( max && value.length > max ) return;
      handleOnChange(event);
    }
  
  
    const required = props.required === false ? false : true;
    // if(readonly !== true ||readonly !==false) readonly = false
  
    return (
      <label
        className={`input ${classes ? classes : ""} ${error ? "-error" : "" }`}
        style={style ? style : {}}
      >
        { required ? <span className="-required">{label}</span> : <span>{label}</span> }
        < textarea className="-textarea"
          value={value ? value : ""}
          onChange={handleChange}
          readOnly={readonly}
        />
        {indication && <p className={indication ? "-indication" : "" }>{indication}</p>}
        {error && <p className={error ? "-error" : ""}>{error}</p>}
      </label>
    )
  }
  
  export default InputTextArea;