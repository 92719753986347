import FormRow from "../components/formRow";

import {useSelector,connect,useDispatch} from 'react-redux';

import InputSelect from "../components/inputSelect";
import InputDate from "../components/inputDate";
import InputNumber from "../components/InputNumber";
import Button from "../components/button"
import axios from 'axios';
import React,{ useState,useEffect } from "react";
import apiPath from "../constants/apiPaths";
import { useNavigate } from "react-router-dom";
import { setTitle} from '../redux/store';


const AddHugeInvestor = () =>{
    const { user} = useSelector(state => state.persistedReducer);

    var headers = {headers: {"x-auth-token" : user.token}}
    
    const [users,setUsers] = useState([])
    const [userId,setUserId] = useState(null)
    const [fonds,setFonds] = useState([])
    const [fondId,setFondId] = useState(null)
    const [montant, setMontant] = useState(null)
    const [dateVirement,setDateVirement] = useState("")
    const [file, setFile] = useState(null)
    const [donneManquant,setDonneManquant] = useState(false)
   


    const [redirect, setRedirect] = useState({
      active:false,
      direction:"",
    });
        // const [bodyPay,setBodyPay] = useState({})

    const handleMontantValue = (e)=>{
    setMontant(e.target.value);
    }

    const handleDateChange = (e)=>{
        setDateVirement(e.target.value)
    
    }
    const getUsers = (e)=>{
        setUserId(e.target.value)
    }
    
    const getFonds =(e)=>{
        setFondId(e.target.value)
    }
const dispatch = useDispatch()
  
    useEffect (()=>{
      
        dispatch(setTitle("Ajouter un investisseur"))
        axios.get(`${apiPath}fonds/`, headers)
        .then((res) => {
            setFonds(res.data)
            setFondId(res.data[0]._id)
            axios.get(`${apiPath}users/`, headers)
            .then((res)=>{
                setUsers(res.data)
                setUserId(res.data[0]._id)

            })
        })
        .catch (err => {
                 console.log(err);
        })  
    },[])

    // const fondTitle = (e)=> {
    //   if (fonds[0].name === "Real-Estate"){
    //     this.props.setTitle("Real-Estate")
    //   }else{
    //     this.props.setTitle("Capitalisation")
    //   }
    // }
    
    const handleFileToUpload = (e) =>{
        if(!e.target.files[0]){
          setFile(null)
          return
        }
        setFile(e.target.files[0])
      }

    let navigate = useNavigate()

    const handleFormSubmit = (e)=>{
        e.preventDefault()
        const formData = new FormData()

        //setting des headers : 
        headers ={...headers, "x-auth-token" : user.token}
        headers ={...headers, "userid":userId ? userId : users[0]._id}
        headers ={...headers, "Content-type":"multipart/form-data"}

        //preparation du body : 
        formData.append('body', JSON.stringify(
          {
            user: userId ? userId : users[0]._id,
            date : dateVirement,
            status : "paid",
            value : montant,
            currency : "EUR",
            fond: fondId ? fondId : fonds[0]._id          
          }
        ))
        //si fichier, on l'ajoute ... 
        if(file){    
          formData.append('file',file)
        }
         
        if (fondId === null ||
            userId === null||
            dateVirement === ''||
            montant === null||
            file === null){
          setDonneManquant (true)
        }else{
          let result =  window.confirm("êtes vous sur de vouloir continuer")
          if(result === true){

          setDonneManquant(false)

          axios.post(`${apiPath}payment/mollie/addpay`, formData, {headers})
        .then(res=>{
          //on setup la redirection. si fondId n'est pas definie, c'est que l'utisateur n'as pas changer la valeur, on redirige donc vers le 1er fond.
          var direction = "";
          switch (fondId) {
            case fonds[0]._id:
              direction = "/dashboard/real-wait-invests"

              break;
            case fonds[1]._id:
              direction = "/dashboard/capital-wait-invests"

              break;
            default:
              direction = "/dashboard/real-wait-invests"
              break;
          }
          setRedirect({active:true, direction: direction})
        })
        .catch(err=>{
          console.log(err)
        })
        
          }
        
        
    }}


return(
        <>
        {
          redirect.active === true &&
          navigate(redirect.direction)
        }
       <div className="add-investor" > 

       <div className="investor-container">
           <div className="investor-dashboard-container">
               <div className="investor-dashboard-scontainer">
                  <div className="invest-section-title">
                       <h4 className="invest-texte">
                       Informations a propos de l'investissement
                       </h4>
                       { donneManquant === true && <p className="error">Des Données sont manquantes !!! </p>}
                   </div>
                   <form className="investor-form"  onSubmit= {handleFormSubmit} >
                   <FormRow classes={"One"}>
                      <InputSelect 
                       label="Selectionner un fond"
                       handleSelectChange = {(e)=>getFonds(e)}
                       inputs= 
                        {fonds.map(f => {
                       return( 
                           {
                           id:`${f._id}` ,
                           label: `${f.name}` ,
                           value: `${f.name}`
                           }
                       )})}  
                       />
                       
                   </FormRow>

                    <FormRow classes={"One"}>
                      <InputSelect 
                       label="Selectionner un utilisateur"
                       handleSelectChange = {(e)=>getUsers(e)}
                       inputs= 
                        {users.map(user => {
                       return( 
                           {
                           id:`${user._id}` ,
                           label: `${user.lastname} ${user.firstname}` ,
                           value: `${user.lastname} ${user.firstname}`
                           }
                        
                       )})}  
                       />
                       
                   </FormRow>

                  <FormRow classes={"One"}>
                       <InputNumber
                        label ='Montant investi'
                        value = {montant}
                        placeholder="Montant"
                        min = "50001"
                        max = "1000000"
                        handleInputChange = {(e)=>handleMontantValue(e)}
                       />
                   </FormRow>
                  <FormRow classes={"One"}>
                      <InputDate
                       label = {"Date de virement"}
                       value = {dateVirement}
                       min = "2022-12-01"
                       handleDateChange = {(e)=>handleDateChange(e)}  
                       />
                     </FormRow> 

                        <label className="label-doc"> Preuve de paiement </label>
                        <input
                        className="file"
                            type='file'
                            onChange={(e)=>{handleFileToUpload(e)}}
                             />
                          <p>{`formats acceptés : pdf, jpg, png < 2 Mo`} </p>
                        <Button
                            classes = '-tiny'
                            type="submit"
                            label={"Ajouter l'investissement"}
                            icon />

                    </form>
                 </div>        
             </div> 
         </div>
         </div>
         </>
    )
}
const mapStateToProps = (state) => ({
  token: state.persistedReducer.user.token,
});

const actions = {
  setTitle,
}
export default connect(mapStateToProps, actions)(AddHugeInvestor);
// export default AddHugeInvestor
