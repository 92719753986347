import Button from "./button"

const Card =({header, amount, text,secondAmount, secondText, btnlabel, btnDisable, action,affiliateLink, classes, amountclass})=>{
 return( <div className={`card ${classes ? classes : ""}`}>
        <div className="card-header">
          <label>{header}</label>
        </div>
        <div className = "card-body">
          <p className= {amountclass ?`-amount ${amountclass}` : `-amount`}>{amount}</p>
          <p className="-card-body-text" >{text} </p>
          <p className= {amountclass ?`-amount ${amountclass}` : `-amount`}>{secondAmount}</p>
          <p className="-card-body-text" >{secondText} </p>
          {btnlabel && 
          <Button
            label={btnlabel}
            disable={btnDisable}
            classes="-card-body-btn" 
            handleClick={action}/>}
            {affiliateLink && <div className="affiliate-link">{affiliateLink}</div>}
            </div>
      </div>)

}
export default Card