import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from "react-redux";
import { setTitle } from '../../redux/store';

import { useParams,Navigate } from 'react-router-dom';

import axios from 'axios'
import apiPath from '../../constants/apiPaths';

import FormSectionTitle from '../../components/formSectionTitle';
import Button from '../../components/button';
import { roundNumber } from '../../helpers/roundNumberFunc';


const CapiInvestementView = ()=>{

  const { user} = useSelector(state => state.persistedReducer);
  const headers = {
    headers: {
      "x-auth-token" : user.token,
    }
  }
  const params = useParams()
  const id = params.id
  const dispatch = useDispatch()
  

  useEffect(()=>{
    dispatch(setTitle("Capitalisation"))//!Fonctionne mais.... le Title en Dur ? 
    axios.get(`${apiPath}investment/${id}`,headers)
    .then((res)=>{
      setInvestment(res.data)
      axios.get(`${apiPath}fonds/${res.data.fond}`,headers)
      .then((res)=>{
        setFond(res.data)
        axios.get(`${apiPath}users/`,headers)
        .then ((res)=>{
          setUsers(res.data)
        })
      })
        .catch((err)=>{
          console.log(err)
        })
      .catch((err)=>{
        console.log(err)
      })
    })
    .catch((err)=>{
      console.log(err)
    })
  },[])
  const [users, setUsers] = useState([])
  const [investment, setInvestment] = useState(null)
  const [fond, setFond] = useState(null);
  const [bodyToSend, setBodyToSend] = useState({})
  const [file, setFile] = useState(null);
  const[redirect,setRedirect] = useState(false)
  const [error, setError] = useState(false)
  

  

  const handleFormSubmit = (e)=>{
    if(bodyToSend && file){
    e.preventDefault()
    let confirm = window.confirm("Êtes vous sûre de vouloir valider cet investissement ? ")
    console.log(e);
    const formData= new FormData()
    if(file !== null){
      formData.append("file",file)
    }
    
    formData.append("body",JSON.stringify(bodyToSend))

    console.log(formData)
    
    if(confirm){
    axios.patch(`${apiPath}investment/${id}`,formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'x-auth-token': user.token 
      }
    }).then((res)=>{
      console.log(res)
      setRedirect(true)
    }).catch((err)=>{
      console.log(err)
    })
    return;
  }
    
  }else{
    e.preventDefault()
    console.log("nothing")
    setError(true)
  }
  }
  const handleDispatchChange =(e)=>{
    const newInvestment = investment
    newInvestment.dateContrat = e.target.value
    const newBodyToSend = bodyToSend
    newBodyToSend.dateContrat = e.target.value
    setBodyToSend({...newInvestment})
    setInvestment({...newInvestment})
  }


  const handleObligationChange = (e) =>{
    if(!e.target.files[0]){
      setFile(null)
      const newBodyToSend = bodyToSend
      newBodyToSend.docUpload = false
      setBodyToSend({...newBodyToSend})
      return
    }
    setFile(e.target.files[0])
    const newBodyToSend = bodyToSend
    newBodyToSend.docUpload = true
    setBodyToSend({...newBodyToSend})
  }


  if(redirect) return <Navigate to="/dashboard/capital-valid-invests"/>

  return (
    <>
      {
        investment &&
       <div className=" -container-investement-big">
          <div className="_listUsers -investement-container">
            <FormSectionTitle
              title={`Investissement à valider par le notaire`}
              classes="-simple-2 _users_title"
              error={ error ?"Des données sont manquantes":""} />
              <div className=" -informations">
                <div className="invest-infos">
                <div> 
                  <p>Investisseur:</p> 
                  <label>{users.map((user)=>user._id === investment.user && user.lastname + " " + user.firstname)}</label>
                  </div>
                <div>
                  <p>Montant: </p>
                  <label>{roundNumber(investment.montant).toLocaleString('en-EU').replaceAll(',', ' ')} €</label>
                  </div>
                <div>
                  <p>Date du Paiement:</p>
                  <label> {new Date (investment.datePaiement).toLocaleDateString()}</label>
                  </div>
                <div> 
                  <p>fond:</p>
                  <label>{fond ? fond.name : ""}</label> 
                  </div>
                {/* <p>Status paiement Mollie: {investment.statusPayement === "paid" ? "payé" : investment.statusPayement}</p> */}
                </div>
              <form onSubmit={handleFormSubmit}>
                
                <label className="date-contrat">Renseigner la date de validation du contrat </label>
                <input className="input-contrat" type="date" min={new Date(investment.datePaiement).toLocaleDateString("fr-CA")}
                onChange={(e)=>handleDispatchChange(e)}/>
              {/* <FormRowCheckbox
                isActive={investment.dispatch.status}
                // error={isContacts.error}
                style={styleCheckBox}
                label={"Dispatcher les fonds"}
                handleCheckboxChange={(event) => handleDispatchChange(event)} /> */}
              <label className="label-doc"> Ajouter Le document notariale :</label>
              <input
              className="file"
                type='file'
                onChange={handleObligationChange} />
                <span>{`formats acceptés : pdf, jpg, png < 2 Mo`} </span>
              <Button
                type="submit"
                label={"Valider le paiement"}
                icon />
            </form>
              </div>
          </div>
        </div>
        
      }
    </> 
  )
}

export default CapiInvestementView