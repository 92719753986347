import IconChevronRight from "./icons/icon-chevron-right"
import IconPlus from "./icons/icon-plus"

const Button = ({classes, isLoading, label, icon,iconPlus, type, style ,handleClick, disable}) => {
  return (
    <div className={`btn ${classes ? classes : ""} ${disable ? "-disable" : ""} `} style={style ? style : {}} >
      {
        !disable
        ? <button onClick={handleClick} type={type}>
            {icon && !isLoading && <IconChevronRight />}
            {/* {iconPlus? "" : ""} */}
            {isLoading ? <span className="dual-spinner"></span> : label }
          </button>
        : <button type="button">{label}</button>
      }
    </div>
  )
}

export default Button

