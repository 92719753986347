import { Outlet, useParams } from "react-router-dom";
import SubNav from "../components/dashboard/subnav";

const EditUserLayout = () => {

  const { id } = useParams();

  const links = [
    {
      id: 1,
      link: `/dashboard/userEdit/personalInfos/${id}`,
      label: "Informations",
      isActive: false,
    },
    {
      id: 2,
      link: `/dashboard/userEdit/investorProfile/${id}`,
      label: "Profil d'investisseur",
      isActive: false,
    },
    {
      id: 3, 
      link: `/dashboard/userEdit/security/${id}`,
      label: "Sécurité",
      isActive: false,
    },
    {
      id: 4, 
      link: `/dashboard/userEdit/banque/${id}`,
      label: "Banque",
      isActive: false,
    },
    {
      id: 5, 
      link: `/dashboard/userEdit/filleul/${id}`,
      label: "Recommandations",
      isActive: false,
    },
    {
      id: 9, 
      link: `/dashboard/userEdit/apport-affaire/${id}`,
      label: "Apport d'affaires",
      isActive: false,
    },
    {
      id: 6, 
      link: `/dashboard/userEdit/fiscality/${id}`,
      label: "Fiscalité",
      isActive: false,
    },
    {
      id: 7, 
      link: `/dashboard/userEdit/documents/${id}`,
      label: "Documents",
      isActive: false,
    },
    {
      id: 8, 
      link: `/dashboard/userEdit/investments/${id}`,
      label: "Investissements",
      isActive: false,
    },
    {
      id: 10, 
      link: `/dashboard/userEdit/transaction/${id}`,
      label: "Transactions financières",
      isActive: false,
    },
   
  ];

  return (
    <div className="layout-dashboard-container edit">
      <SubNav
        links={links}
      />
      <Outlet />
    </div>
  )

}

export default EditUserLayout;