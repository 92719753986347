import React, { useState , useEffect } from 'react';
import axios from "axios";
import Tree from 'react-d3-tree';
import apiPath from "../../constants/apiPaths";
import { useDispatch, connect, useSelector } from 'react-redux';
import { setTitle } from "../../redux/store";
import { Link } from 'react-router-dom';

const TreeThree =()=> {

  const dispatch = useDispatch();
  const { user} = useSelector(state => state.persistedReducer);
  const headers = {headers: {"x-auth-token" : user.token}}
  const [data,setData] = useState({
    name: "Vizio",
    role: "Organisation",
    children : [
    ]
  });
  useEffect (()=>{
    dispatch(setTitle("Reseaux"));
    axios.get(`${apiPath}reseau/all`,headers)
    .then((res) => {
      console.log(res)
      setData(res.data)
    })
    .catch (err => {
      console.log(err);
    })
},[])

  // const [translate, containerRef] = useCenteredTree();
  const nodeSize = { x: 250, y: 200 };
  const foreignObjectProps = { width: nodeSize.x, height: nodeSize.y, x: 20 };
  const renderForeignObjectNode = ({
    nodeDatum,
    toggleNode,
    foreignObjectProps
  }) => (
    <g>
       
        <circle r={5}></circle>
      {/* `foreignObject` requires width & height to be explicitly set. */}
      <foreignObject {...foreignObjectProps}>
        <div style={{ borderRadius: "16px", backgroundColor: "white", fontSize:14}}>
          {/* <Link to={nodeDatum.id !== "Vizio" &&`/dashboard/userEdit/apport-affaire/${nodeDatum.id}`} style={{ textAlign: "center", textDecoration:"none", color:"black", fontWeight:"bold" }} onClick={toggleNode}>{nodeDatum.name}</Link> */}
          <span  style={{ textAlign: "center", textDecoration:"none", color:"black", fontWeight:"bold" }} onClick={toggleNode}>{nodeDatum.name}</span>
          {nodeDatum.children[0] &&<span  onClick={toggleNode}> {nodeDatum.__rd3t.collapsed ? " +" : " -"} </span>}
          <p style={{textAlign: "left" , padding: "10px"}}> 

            {nodeDatum.role} <br/> 
            TotalInvest : <span style={{color: "blue"}}>{nodeDatum.totalInvest} € </span><br />
            Directs : <span style={{color: "blue"}}>{nodeDatum.direct} € </span><br /> 
            Lévée réseau : <span style={{color: "blue"}}>{nodeDatum.indirect} €</span> <br />
            {/* Levée réseau + direct : <span style={{color: "blue"}}>{nodeDatum.indirect} €</span> */}
            {/* Levée réseau : <span>{indirect} €</span> */}
          </p>
          {nodeDatum.children && (<>
            {(<Link to={nodeDatum.id !== "Vizio" &&`/dashboard/userEdit/apport-affaire/${nodeDatum.id}`}>
            <button style={{ width: "80%" , margin : "0 0 5px 20px"}} >
              {/* {nodeDatum.__rd3t.collapsed ? "Déplier" : "Plier"} */}
              {"Voir le detail"}
            </button></Link>)}
            </>
          )}
        </div>
      </foreignObject>
    </g>
  );
  

  return (
    
    // `<Tree />` will fill width/height of its container; in this case `#treeWrapper`.
    <div id="treeWrapper" style={{ width: '100%', height: '100vh' ,backgroundColor: "#rgb(241 241 245)" }}>
      <Tree 
        data={data}
        onNodeClick={(e,node)=>{console.log(e,node)}}
        renderCustomNodeElement={(rd3tProps) =>
          renderForeignObjectNode({ ...rd3tProps, foreignObjectProps })
        }
        pathFunc="diagonal"
        orientation="vertical"
        nodeSize= {{ x: 300, y: 320}}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  token: state.persistedReducer.user.token,
});

const actions = {
  setTitle,
};

export default connect(mapStateToProps, actions)(TreeThree);

// export default TreeThree;