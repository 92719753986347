

import { Component } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useParams ,Link} from "react-router-dom";

import IconArrowColored from "../../../assets/svg/navbar-arrow-colored"
import InfosBar from "../../../components/infosBar";
import FormSectionTitle from "../../../components/formSectionTitle";
import apiPath from "../../../constants/apiPaths";
import Card from "../../../components/card";
import { roundNumber } from "../../../helpers/roundNumberFunc";

class EditUserApportAffaireDetails extends Component{
  constructor(props){
    super(props)
    this.state = {
      token : props.token,
      totalDeposerRealE : (0),
      totalDeposerCap:(0),
      usersFilleulList : [],
      totaleLevéeIndirecte : 0
    }
  }

  componentDidMount(){

    const { id } = this.props.params;
    const headers = {headers: {"x-auth-token": this.props.token}};
    axios
      .get(`${apiPath}investment/user/${id}`, headers)
      .then((res) => {
        // console.log(res.data)
        let benef = res.data.filter((i) => {
          return i.status === "validé";
        });
      //* retourne tout les benefice sur investissement real estate reçu
      let realEstate = benef.filter((user) => {
        return user.fromFond.fond.name === "Real-Estate";
      });
      let totalLevéRE = realEstate.map((i) => parseInt(i.fromFond.montant))
      .reduce((prev, next) => prev + next,0)
      //* retourne tout les benefice sur investissement capitalisation reçu
      let capitalisation = benef.filter((user) => {
        return user.fromFond.fond.name === "Capitalisation";
      });
      let totalLevéCap = capitalisation
      .map((i) => parseInt(i.fromFond.montant))
      .reduce((prev, next) => prev + next,0)
      this.setState({
        totalDeposerRealE : totalLevéRE ,
        totalDeposerCap: totalLevéCap
        })
    });


    axios.get(`${apiPath}reseau/parrain/${id}`,headers).then((res) => {
      console.log(res.data)
      let parrain = res.data.filter((user) => {
        return user.depth === 0;
      });
      let leveIndirect= parrain[0].totalLeveeArbre
        let users = res.data.filter((user) => {
            return user.depth === 1;
          });
          let filleule = users.map((i) => {
            return {
              id: i.filleule._id,
              firstname: i.filleule.firstname,
              lastname: i.filleule.lastname,
              role: i.filleule.role,
              realEstate: i.filleule.investments

                ? i.filleule.investments.filter(
                    (o) => o.fond.name === "Real-Estate" && o.status === "processed"
                  )
                : 0,
    
              capitalisation: i.filleule.investments
                ? i.filleule.investments.filter(
                    (o) =>
                      o.fond.name === "Capitalisation" && o.status === "processed"
                  )
                : 0,
            };
          });
          console.log(filleule.filter((i)=>i.role.includes("investisseur")))

          this.setState({
            // usersFilleulList : filleule.filter((i)=>i.role.includes("investisseur") ),
            usersFilleulList : filleule.filter((i)=>i.role.includes("investisseur") &&( i.realEstate.length !==0 || i.capitalisation.length !==0)),

            totaleLevéeIndirecte: leveIndirect
            }) })
    .catch(err => console.err(err));
  }


  getRandomColor = () => {
    const codes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, "A", "B", "C" , "D", "E", "F"];
    let hexaCode = "";
    for (let i=0; i<6; i++) hexaCode += codes[Math.floor(Math.random()*codes.length)];
    return `#${hexaCode}`;
  }

  render(){
    const { id } = this.props.params;
    console.log("levé indirect non fonctionnel",this.state.totaleLevéeIndirecte)

    return(
      <>
        <div className="information-container -apporAf">
            <div className="-title">
              <h4 className="-texte">Informations préalables</h4>
            </div>
            <div className="-information">
              <InfosBar
                classes="-info"
                content={
                  "Vous trouverez ci-dessous le détail de votre affiliation dans les différentes solutions de VIZIO CAPITAL. Vous trouverez également (d’ici quelques temps) le détail de l’affiliation qui ont été générées via votre développement."
                }
              />
            </div>
          </div>
          <div className="title-dicrect">{("Montant levé").toUpperCase()}</div>
          <div className="dashboard-container -card-container">
          <Card
              classes={"first-elmt"}
              header={"Total direct"}
              amount={`${roundNumber(this.state.totalDeposerRealE+this.state.totalDeposerCap)
                .toLocaleString("en-EU")
                .replaceAll(",", " ")} €`}
              button
              text={
                "L’ensemble des investissements de mes clients (Vizio Real estate & Capitalisation)."
              }
            />
            <Card
              classes={"first-elmt"}
              header={"Vizio Real estate"}
              amount={`${roundNumber(this.state.totalDeposerRealE)
                .toLocaleString("en-EU")
                .replaceAll(",", " ")} €`}
              button
              text={
                "L’ensemble des investissements de mes clients."
              }
            />
            <Card
              classes={"first-elmt"}
              header={"Capitalisation"}
              amount={`${roundNumber(this.state.totalDeposerCap)
                .toLocaleString("en-EU")
                .replaceAll(",", " ")} €`}
              button
              text={
                "L’ensemble des investissements de mes clients."
              }
            />
          </div>
          <div className="title-dicrect">{("Montant levé indirectement").toUpperCase()}</div>
          <div className="dashboard-container -card-container">
          <Card
              classes={"first-elmt"}
              header={"Total indirect"}
              amount={`${roundNumber(this.state.totaleLevéeIndirecte)
                .toLocaleString("en-EU")
                .replaceAll(",", " ")} €`}
              button
              text={
                "L’ensemble des investissements des clients indirects (Vizio Real Estate & Capitalisation)."
              }
            />
          </div>
          <div className="details-container">
            <div className="details-scontainer -no-padding">
              <FormSectionTitle
                title="Montant total déposé par filleuls"
                classes="-simple-2"
              />

              <div className="security-form-container">
                <div className="_details_container">
                  <div className="_details_header">
                    <span></span>
                    <span>NOM</span>
                    <span>PRÉNOM</span>
                    <span>REAL ESTATE </span>
                    <span>CAPITALISATION </span>
                    <span>TOTAL INVESTI</span>
                    <span></span>
                  </div>

                 { this.state.usersFilleulList&& this.state.usersFilleulList.length>0 && this.state.usersFilleulList.realEstate!==[] && this.state.usersFilleulList.capitalisation!==[]  ?
                  <ul className="_details_list">
                    {this.state.usersFilleulList.map((users) => {
                      return (
                        <li key={users.id} className="_details">
                          <div className="_picto">
                            <a
                              style={{ backgroundColor: this.getRandomColor() }}
                            >{`${users.lastname !=null?
                              users.lastname
                              .charAt(0)
                              .toUpperCase():"-"}${users.firstname !=null?
                              users.firstname
                              .charAt(0)
                              .toUpperCase():"-"}`}</a>
                          </div>
                          <div data-label="NOM :">{users.lastname !=null?users.lastname:"-"}</div>
                          <div data-label="PRÉNOM :">{users.firstname !=null?users.firstname:"-"}</div>
                          <div data-label="REAL ESTATE :">
                            {roundNumber(
                              users.realEstate.length !== 0
                                ? users.realEstate
                                    .map((i) => roundNumber(i.montant))
                                    .reduce((prev, next) => prev + next)
                                : 0
                            )
                              .toLocaleString("en-EU")
                              .replaceAll(",", " ")}{" "}
                            €
                          </div>
                          <div data-label="CAPITALISATION : ">
                            {roundNumber(
                              users.capitalisation.length !== 0
                                ? users.capitalisation
                                    .map((i) => roundNumber(i.montant))
                                    .reduce((prev, next) => prev + next)
                                : 0
                            )
                              .toLocaleString("en-EU")
                              .replaceAll(",", " ")}{" "}
                            €
                          </div>
                          <div data-label="TOTAL INVESTI :">
                            {roundNumber(
                              (users.capitalisation.length !== 0
                                ? users.capitalisation
                                    .map((i) => roundNumber(i.montant))
                                    .reduce((prev, next) => prev + next)
                                : 0) +
                                (users.realEstate.length !== 0
                                  ? users.realEstate
                                      .map((i) => roundNumber(i.montant))
                                      .reduce((prev, next) => prev + next)
                                  : 0)
                            )
                              .toLocaleString("en-EU")
                              .replaceAll(",", " ")}{" "}
                            €
                          </div>
                          <Link className="_icon" to={`/dashboard/userEdit/filleul/${id}`}>
                            <IconArrowColored />
                            <p>Voir la fiche</p>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                  :
                  <p className="error_details_list">Vos investisseurs n'ont pas encore investi.</p>
                  }
                </div>
              </div>
            </div>
          </div>
          
    </>
  )}
}

// Utilisé pour accéder aux hooks de react-rooter-v6 dans un composant type classe
export default (props) => {
  const params = useParams();
  const { token } = useSelector(state => state.persistedReducer.user);
  return <EditUserApportAffaireDetails params={params} token={token} {...props} />
}