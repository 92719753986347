import React, { useEffect, useState } from 'react'
import {  useSelector, useDispatch } from "react-redux";
import {  Navigate, useParams } from 'react-router-dom';
import { setTitle } from '../../redux/store';
import axios from 'axios'
import apiPath from '../../constants/apiPaths';
// import FormRowCheckbox from '../../components/formRowCheckbox'
import FormSectionTitle from '../../components/formSectionTitle';
import Button from '../../components/button';
import { roundNumber } from '../../helpers/roundNumberFunc';

const WithDrawValidationView = ()=>{

  const { user} = useSelector(state => state.persistedReducer);
  const headers = {
    headers: {
      "x-auth-token" : user.token,
    }
  }
  const dispatch = useDispatch()
  const params = useParams()
  const id = params.id
  

  useEffect(()=>{
    dispatch(setTitle("Retrait Affaires"))//!Fonctionne mais.... le Title en Dur ? 
    axios.get(`${apiPath}withdraw/retrait/${id}`,headers)
    .then((res)=>{
      setWithDraw(res.data)
      axios.get(`${apiPath}users/`,headers)
      .then((res)=>{
        setUsers(res.data)
      })
      .catch((err)=>{
         console.log(err)
       })
      })
     .catch((err)=>{
      console.log(err)
    })
  },[])
  const [users, setUsers] = useState([])
  const [withDraw, setWithDraw] = useState(null)

  const [dateRetrait, setDateRetrait] = useState({})
  const [file, setFile] = useState(null);
  const [fileComision, setFileComission] = useState(null)
  const[redirect,setRedirect] = useState(false)
  const [error, setError] = useState(false)



  
  

  const styleCheckBox = {
    marginBottom: 20,
    marginTop: 50
  }

  const handleFormSubmit = (e)=>{
    if(dateRetrait && file && fileComision){
    e.preventDefault()
    let confirm = window.confirm("Êtes vous sûre de vouloir confirmer ce retrait ? ")
    console.log(e);
    const formData= new FormData()

    const ficheComission = new FormData()
    if(file !== null){
      formData.append("file",file)
    }
    if(fileComision !== null){
      ficheComission.append("file",fileComision)
    }
    formData.append('body', JSON.stringify({date: dateRetrait,}))
    
   

    console.log(formData)
    
    if(confirm){
    axios.patch(`${apiPath}withdraw/${id}`,formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'x-auth-token': user.token 
      }
    }).then((res)=>{
      console.log("passage fiche comission")
      axios.patch(`${apiPath}withdraw/comission/${id}`,ficheComission, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-auth-token': user.token 
        }
      }).then((res)=>{
        console.log(res)
        setRedirect(true)
      })

    }).catch((err)=>{
      console.log(err)
    })
    return;
  }
    
  }else{
    e.preventDefault()
    console.log("nothing")
    setError(true)
  }
  }
  const handleDateChange =(e)=>{
    setDateRetrait(e.target.value)
  }


  const handleFileChange = (e) =>{
    if(!e.target.files[0]){
      setFile(null)
      
    }
    setFile(e.target.files[0])
    

 }
  const handleFileComissionChange = (e) =>{
    if(!e.target.files[0]){
      setFileComission(null)
      
    }
    setFileComission(e.target.files[0])
  }






if(redirect) return <Navigate to="/dashboard/valid-business-withdraw"/>

  return (
    
    <>
      {
        withDraw &&
        
       <div className=" -container-investement-big">
          <div className="_listUsers -investement-container">
         
            <FormSectionTitle
              title={`Retait à valider ` }
              classes="-simple-2 _users_title"
              error={ error ?"Des données sont manquantes":""} />
              
              <div className=" -informations">
                <div className="invest-infos">
                <div> 
                  <p>Investisseur:</p> 
                  <label>{users.map((user)=>user._id === withDraw.user && user.lastname + " " + user.firstname)}</label>
                  </div>
                <div>
                  <p>Montant: </p>
                  <label>{roundNumber(withDraw.amount).toLocaleString('en-EU').replaceAll(',', ' ')} €</label>
                  </div>
                <div>
                  <p>Date de demande de retrait:</p>
                  <label> {new Date (withDraw.date).toLocaleDateString()}</label>
                  </div>

                </div>
              <form onSubmit={handleFormSubmit}>
                
                <label className="date-contrat">Renseigner la date de validation du retrait </label>
                <input className="input-contrat" type="date"  min={new Date(withDraw.date).toLocaleDateString("fr-CA")}
                onChange={(e)=>handleDateChange(e)}/>
              {/* Uploader la fiche de commission */}
              <label className="label-doc"> Ajouter La Preuve :</label>
              <input
                className="file"
                type='file'
                onChange={handleFileChange} />

              <label className="label-doc"> Ajouter la fiche de commission :</label>
              <input
                className="file"
                type='file'
                onChange={handleFileComissionChange} />
                
                <span>{`formats acceptés : pdf, jpg, png < 2 Mo`} </span>
              
              <Button
                type="submit"
                label={"Valider le paiement"}
                icon
                 />
            </form>
              </div>
          </div>
        </div>
        
      }
    </> 
  )
}
export default WithDrawValidationView