
import { Outlet, useParams } from "react-router-dom";
import SubNav from "../components/dashboard/subnav";

const EditUserLayout = () => {
  const { id } = useParams();

  const links = [
    {
      id: 1,
      link: `/dashboard/list-contact/contact-personal-info/${id}`,
      label:"Information personnelles",
      isActive: false,
    },
    {
      id: 2,
      link: `/dashboard/list-contact/contact-social-network/${id}`,
      label:"Réseaux sociaux",
      isActive: false,
    },
    {
      id: 3, 
      link: `/dashboard/list-contact/contact-investment-interest/${id}`,
      label:"Intérêt du contact pour le projet Vizio",
      isActive: false,
    },
    {
      id: 4, 
      link: `/dashboard/list-contact/contact-notes/${id}`,
      label:"Notes et rendez-vous",
      isActive: false,
    }
   
  ];

  return (
    <div className="layout-contact-container edit-contact">
      <SubNav
        links={links}
      />
      <Outlet />
    </div>
  )

}

export default EditUserLayout;