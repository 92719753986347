import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userToEdit: false,
}
const userToEditSlice = createSlice({
  name: "userToEdit",
  initialState : initialState,
  reducers : {
    setUserToEdit: (state, action) => {
      state.userToEdit = action.payload;
    },
  }
})
export default userToEditSlice;