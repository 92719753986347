import React, { Component } from "react";
import axios from "axios";
import Button from "./button";
import InputText from "./inputText";
import regexConsts from "../constants/regex";
import Infos from "../components/infos";
import apiPath from "../constants/apiPaths";

class DoubleAuthForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      error: {
        status: false,
        message: "",
      },

      code: {
        value: "",
        error: false,
        regex: regexConsts.code2af,
      },
    };
  }

  // handleCodeChange = (event) => {
  //   const value = event.target.value.replace(/#/g, "").replace(/\s/g, "");

  //   if (
  //     value.length > 6 ||
  //     (!Number.isInteger(parseInt(event.nativeEvent.data)) &&
  //       event.nativeEvent.data !== null)
  //   )
  //     return;

  //   this.setState({
  //     code: {
  //       ...this.state.code,
  //       error: this.checkInput(value, this.state.code.regex) ? false : true,
  //       value: (value.slice(0, 3) + " " + value.slice(3, value.length)).trim(),
  //     },
  //   });
  // };

  // handleCodeSubmite = (event) => {
  //   event.preventDefault();

  //   if (this.state.isLoading) return;

  //   const { code } = this.state;
  //   const { token } = this.props;
  //   const value = code.value.replace(/\s/g, "");

  //   const codeError = this.checkInput(value, code.regex) ? false : true;
  //   const tokenError = !token || token.length <= 0 ? true : false;

  //   if (tokenError) {
  //     this.props.setToken(false);
  //     this.props.setUser(false);
  //     this.props.setHasGoogleAuth(false);
  //   } else if (codeError)
  //     this.setState({
  //       code: { ...code, error: true },
  //     });
  //   else {
  //     this.setState({
  //       isLoading: true,
  //       error: {
  //         status: false,
  //         message: "",
  //       },
  //     });

  //     axios
  //       .post(`${apiPath}auth/2fa/`, {
  //         code: value,
  //         token: token,
  //       })
  //       .then((res) => {
  //         const token2 = res.headers["x-auth-token"];

  //         this.setState({ token: token2 });
  //         this.props.setToken(token2);
  //         this.props.setUser(res.data);
  //         this.props.setLogedIn(true);
  //         // console.log("Set Global variables ")
  //       })
  //       .catch((err) => {
  //         if (err.response)
  //           this.setState({
  //             error: {
  //               status: true,
  //               message: err.response.data,
  //             },
  //           });
  //       })
  //       .finally(() => this.setState({ isLoading: false }));
  //   }
  // };

  // checkInput = (value, regex) => regex.test(value);

  render() {
    const { handleCodeChange, handleCodeSubmite, code } = this.props;
    return (
      <div>
        <form className="double-auth-form" onSubmit={handleCodeSubmite}>
          {this.state.error.status && (
            <Infos message={this.state.error.message} classes="error" />
          )}
          <h3 className="-title">Entrez vos identifiants de connexion</h3>
          <InputText
            value={code.value}
            classes="input code"
            type="text"
            label="Google authentificator"
            placeholder="### ###"
            marginBottom={10}
            // errorMessage="Le code n'est pas valide"
            // hasError={code.error}
            handleInputChange={handleCodeChange}
          />
          {/* <div className="-forget">
            <a href="#">Je n'ai plus mon authentificator</a>
          </div> */}
          <Button
            isLoading={this.state.isLoading}
            label="Connexion"
            marginTop={40}
            classes="btn"
          />
        </form>
      </div>
    );
  }
}

export default DoubleAuthForm;
