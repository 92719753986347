import React, { Component } from "react";
import percentageFiscalite from "../constants/percentageFicalite";

class fiscalitePercentage extends Component{
  constructor(props){
    super(props)
    this.state = {
      mapping: percentageFiscalite
    }
  }
  componentDidMount(){
    this.setState({load: "ok"})
    this.setState({ mapping: {"France":"15%","Belgique":"30%"}})
  }
  render(){
    return(
      <>
        {
          this.props.country &&
          <>
          {this.state.mapping[this.props.country]?
          <span className="-fiscality">l'impôt est de {this.state.mapping[this.props.country]}</span>:
          <span></span>
          }
          <br/>
          </>
        }
        {
          !this.props.country &&
          <>
            <span className="-fiscality">Pays de fiscalité non renseigné </span>
            <br/>
          </>
        }
      </>
    )
  }
}
export default fiscalitePercentage 