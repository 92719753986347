import React, {useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import apiPath from '../../constants/apiPaths';
import FormRow from '../../components/formRow';
import InputText from '../../components/inputText';
import FormRowCheckbox from '../../components/formRowCheckbox';
import InputSelect from '../../components/inputSelect';
import Button from '../../components/button';
import codes from '../../constants/codes';
import countries from '../../constants/countries';
import { setFeedback } from '../../redux/store';
import regexes from '../../constants/regex';


const ContactPersonnalInfoView = () => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.persistedReducer);
  const headers = {
    headers: {
      "x-auth-token": user.token,
    },
  };
  const { id } = useParams()
  const prospectSource = [
    {
      id: "Aucun",
      label: "Aucun",
      value: "Aucun",
    },
    {
      id: "Marché chaud",
      label: "Marché chaud",
      value: "Marché chaud",
    },
    {
      id: "Marché froid",
      label: "Marché froid",
      value: "Marché froid",
    },

  ]

  const prospectProvenance = [
    {
        id: "Aucun",
        label: "Aucun",
        value: "Aucun",
      },
    {
      id:  "Réseaux sociaux",
      label:  "Réseaux sociaux",
      value:  "Réseaux sociaux",
    },
    {
      id: "Recommandation",
      label: "Recommandation",
      value: "Recommandation",
    },
    {
      id: "Famille",
      label: "Famille",
      value: "Famille",
    },
    {
      id: "Amis",
      label: "Amis",
      value: "Amis",
    },
    {
      id: "Connaissance",
      label: "Connaissance",
      value: "Connaissance",
    },
    {
      id:"Personnalités",
      label:"Personnalités",
      value:"Personnalités",
    },
  ]

  const [pseudo, setPseudo] = useState('');
  const [pseudoExist, setPseudoExist] = useState('');
  const [nom, setNom] = useState('');
  // const [nomExist, setNomExist] = useState('');
  const [prenom, setPrenom] = useState('');
  // const [prenomExist, setPrenomExist] = useState('');
  const [denomination, setDenomination] = useState('');
  // const [denominationExist, setDenominationExist] = useState('');
  const [email, setEmail] = useState('');
  // const [emailExist, setEmailExist] = useState('');
  const [indicateur, setIndicateur] = useState('+33')
  // const [indicateurExist, setIndicateurExist] = useState('')
  const [telephone, setTelephone] = useState('');
  // const [telephoneExist, setTelephoneExist] = useState('');
  const [pays, setPays] = useState('France');
  const [ville, setVille] = useState('');
  const [codePostal, setCodePostal] = useState('');
  const [adresse, setAdresse] = useState('');
  const [metier, setMetier] = useState('');
  const [prosSource, setProsSource] = useState('');
  const [prosProvenance, setProsProvenance] = useState('');
  const [premierContact, setPremierContact] = useState("non");
  const [demandeAccompagnement, setDemandeAccompagnement] = useState("");
  const [isPro, setIsPro] = useState(false);

  const [emailErrorText, setEmailErrorText] = useState("");
  const [phoneErrorText, setPhoneErrorText] = useState("");




//   const headers = {
//     headers: {
//       "x-auth-token": token
//     }
//   }
  useEffect(() => {
    console.log(id)
    axios.get(`${apiPath}prospect/${id}`,headers).
      then((res) => {
        console.log("res Data here", res.data)
        setPseudo(res.data.pseudo)
        // console.log('pseudo :',res.data.pseudo)
        setNom(res.data.lastname)
        // console.log('nom :',res.data.lastname)
        setPrenom(res.data.firstname)
        // console.log('prenom :',res.data.firstname)
        setDenomination(res.data.denomination)
        setIsPro(res.data.isPro)
        // console.log('denomination :',res.data.denomination)
        setEmail(res.data.mail)
        // console.log('mail :',res.data.mail)
        setIndicateur(res.data.phoneIndicator)
        // console.log('indicateur :',res.data.phoneIndicator)
        setTelephone(res.data.phone)
        // console.log('phone :', res.data.phone)
        setPays(res.data.country)
        // console.log('pay :', res.data.country);
        setAdresse(res.data.address)
        // console.log('address :' ,res.data.address)
        setVille(res.data.city)
        // console.log('ville', res.data.city);
        setCodePostal(res.data.zipCode)
        // console.log(res.data.zipCode)
        setMetier(res.data.function)
        // console.log(res.data.function)
        setProsSource(res.data.source_prospect)
        // console.log(res.data.source_prospect);
        setProsProvenance(res.data.provenance_prospect)
        // console.log(res.data.provenance_prospect);
        setPremierContact(res.data.firstcontact)
        // console.log(res.data.firstcontact);
        setDemandeAccompagnement(res.data.accompaniement)
        // console.log(res.data.accompaniement);


      })
  }, [])


  const handlePseudo = (e) => {
    setPseudo(e.target.value)
  }
  const handleNom = (e) => {
    setNom(e.target.value)
  }
  const handlePrenom = (e) => {
    setPrenom(e.target.value)
  }
  const handleEmail = (e) => {
    setEmail(e.target.value)
    if (e.target.value === "") {
        setEmailErrorText("")
      }
      else if ( !regexes.email.test(e.target.value) ) {setEmailErrorText("La valeur saisie pour le champ email n'est pas correcte"); }
      else {setEmailErrorText("");
    }
  }
  const handleIndicateur = (e) => {
    setIndicateur(e.target.value)
  }
  const handleTelephone = (e) => {
    setTelephone(e.target.value)
    if (e.target.value === "") {
        setPhoneErrorText("")
      }
      else if ( e.target.value.length <9 || e.target.value.length >10 ) {setPhoneErrorText("La valeur saisie pour le champ téléphone doit être composer de 9 a 10 chiffres");}
      else {setPhoneErrorText("");
  
  }}
  const handlePays = (e) => {
    setPays(e.target.value)
  }
  const handleVille = (e) => {
    setVille(e.target.value)
  }
  const handleCodePostal = (e) => {
    setCodePostal(e.target.value)
  }
  const handleAdresse = (e) => {
    setAdresse(e.target.value)
  }
  const handleMetier = (e) => {
    setMetier(e.target.value)
  }
  const handleProsSource = (e) => {
    setProsSource(e.target.value)
    console.log(e.target.value)

  }
  const handleProsProvenance = (e) => {
    setProsProvenance(e.target.value)
    // console.log(e.target.value)

  }
  const handleCheckBoxchange = (event) => {
    setIsPro(event.target.checked);
  };
  const handleDenomination = (event) => {
    setDenomination(event.target.value);
  }
  console.log("i'm a pro", isPro)

  const onChangePremierContactValue = (e) => {
    setPremierContact(e.target.value)
    console.log(e.target.value)
  }
  const onChangeAssistanceValue = (e) => {
    setDemandeAccompagnement(e.target.value)
    console.log(e.target.value)
  }

  const HandleInfoPersoSubmit = (e) => {
    e.preventDefault();
    const body = {
      pseudo: pseudo,
      lastname: nom,
      firstname: prenom,
      isPro: isPro,
      mail: email,
      phoneIndicator: indicateur,
      phone: telephone,
      country: pays,
      city: ville,
      zipCode: codePostal,
      address: adresse,
      function: metier,
      source_prospect: prosSource,
      provenance_prospect: prosProvenance,
      firstcontact: premierContact,
      accompaniement: demandeAccompagnement
    }
    if (isPro === true) {
      body.denomination = denomination;
    }
    axios.patch(`${apiPath}prospect/personalInfo/${id}`, body,headers)
    .then((response) => {
        dispatch(setFeedback({ show: true, type: 'success', title: `Confirmation des modifications`, message: `Vos données ont bien été mises à jour` }));
        console.log(response);
        setTimeout(() => {
            dispatch(setFeedback({ show: false }));
          }, 5000);
  
      })
    .catch((error) => {
        console.log(error);
      if (error.response.status === 401) {
        console.log(error.response.data);
        dispatch(setFeedback({ show: true, type: 'error', title: `${"Erreur de saisie"}`, message: `${error.response.data}` }));
        setTimeout(() => {
          dispatch(setFeedback({ show: false }));
        }, 5000);
      }else{
        dispatch(setFeedback({ show: true, type: 'error', title: `${"Erreur réseaux"}`, message: `Une erreur est survenue lors de la récupération des données` }));
        setTimeout(() => {
          dispatch(setFeedback({ show: false }));
        }, 5000);
      }
      }
      )
  }




  return (
    <div className="layout-AA-container">
      <div className="-add-contact-container">
        {/* <div className="contact-info-container -apporAf">
            <div className="-title">
              <h4 className="-texte">{texts[lang].infoPrealableContactForm}</h4>
            </div>
            <div className="-information">

            <InfosBar
              classes="-error"
              content={
                texts[lang].infoFormContactContentOne
              }
            />
            <InfosBar
              classes="-info"
              content={
                texts[lang].infoFormContactContentTwo
              }
            />
            </div>
          </div> */}

        <form onSubmit={HandleInfoPersoSubmit}>
          <div className="contact-info-container -apporAf">
            <div className="-title">
              <h4 className="-texte">{"Information personnelles"}</h4>
            </div>

            <div className='contact-scontainer'>

              <FormRow classes={"-one"}>
                <InputText
                  required={false}

                  classes={"-two"}
                  label={"Pseudo"}
                  value={pseudo}
                  handleInputChange={(e) => handlePseudo(e)}
                />
              </FormRow>
              {/* C'est un Particulier */}


              <FormRow classes={"-two"}>
                <InputText
                  required={false}
                  classes={"-two"}
                  label={"Nom"}
                  value={nom}
                  handleInputChange={(e) => handleNom(e)}
                />
                <InputText
                  required={false}
                  classes={"-two"}
                  label={"Prénom"}
                  value={prenom}
                  handleInputChange={(e) => handlePrenom(e)}
                />

              </FormRow>
              <div className="-infp-checkbox">
                <FormRowCheckbox
                  label={"C'est un professionnel"}
                  handleCheckboxChange={(e) => handleCheckBoxchange(e)}
                  isActive={isPro} />
                {isPro &&
                  <InputText
                    required={false}
                    label={"Dénomination sociale"}
                    value={denomination}
                    handleInputChange={(e) => handleDenomination(e)}
                  />}
              </div>

              <FormRow classes={"-one"}>
                <InputText
                  classes={"-two"}
                  required={false}
                  label={"Email"}
                  value={email}
                  error={emailErrorText}
                  handleInputChange={(e) => { handleEmail(e) }}
                />
              </FormRow>
              <FormRow classes={"-two"}>
                <InputSelect
                  required={false}
                  label={"Indicatif téléphonique international"}
                  value={indicateur}
                  inputs={codes.map((i) => ({
                    id: `+${i.code}`,
                    label: `+${i.code} - (${i.label})`
                  }))}
                  handleSelectChange={(e) => handleIndicateur(e)}

                />
                <InputText
                  required={false}
                  classes={"-two"}
                  type="number"
                  label={"Numéro de téléphone"}
                  value={telephone}
                  error={phoneErrorText}
                  handleInputChange={(e) => { handleTelephone(e) }}
                />

              </FormRow>
              <FormRow classes={"-one"}>

                <InputText
                  required={false}
                  classes={"-two"}
                  label={"Adresse postale"}
                  value={adresse}
                  handleInputChange={(e) => { handleAdresse(e) }}
                />

              </FormRow>
              <FormRow classes={"-two"}>
                <InputText
                  required={false}
                  classes={"-two"}
                  label={"Code postal"}
                  value={codePostal}
                  handleInputChange={(e) => { handleCodePostal(e) }}
                />
                <InputText
                  required={false}
                  classes={"-two"}
                  label={"Ville"}
                  value={ville}
                  handleInputChange={(e) => { handleVille(e) }}
                />



              </FormRow>
              <FormRow classes={"-one"}>
                <InputSelect
                  required={false}
                  label={"Pays"}
                  value={pays}
                  inputs={countries.map((i) => (
                    {
                      id: i.label,
                      label: i.label,
                    }))}
                  handleSelectChange={(e) => { handlePays(e) }}
                />
              </FormRow>
              <FormRow classes={"-one"}>
                <InputText
                  classes={"-two"}
                  required={false}
                  label={"Métier"}
                  value={metier}
                  handleInputChange={(e) => { handleMetier(e) }}
                />


              </FormRow>
              <FormRow classes={"-two"}>
                <InputSelect
                  required={false}
                  label={"Source de prospection"}
                  inputs={prospectSource.map((n) => {
                    return { id: n.id, label: n.label };
                  })}
                  value={prosSource}
                  handleSelectChange={(e) => {
                    handleProsSource(e);
                  }}
                />
                <InputSelect
                  required={false}
                  label={"Provenance de la prospection"}
                  inputs={prospectProvenance.map((n) => {
                    return { id: n.id, label: n.label };
                  })}
                  value={prosProvenance}
                  handleSelectChange={(e) => {
                    handleProsProvenance(e);
                  }}
                />

              </FormRow>



              <FormRow classes={"-one"}>

                <div onChange={onChangePremierContactValue}>
                  <label>{"Premier contact"}</label>

                  <div className='radio-container'>
                    <div className='radio-input'>
                      <input id="rad8" type="radio" value={`Oui`} name="premierContact" checked={premierContact === `Oui`} /> <label for="rad8" >{"Oui"}</label>

                    </div>
                    <div className='radio-input'>
                      <input id="rad9" type="radio" value={`Non`} name="premierContact" checked={premierContact === `Non`} /> <label for="rad9" >{"Non"}</label>

                    </div>
                  </div>
                </div>
              </FormRow>

              <FormRow classes={"-one"}>


                <div onChange={onChangeAssistanceValue} >
                  <label>{"Demander un accompagnement de la société (demander de l'aide pour contacter ou présenter)"}</label>
                  <div className='radio-container'>
                    <div className='radio-input'>
                      <input id="rad10" type="radio" value={`Oui`} name="demandeAccompagnement" checked={demandeAccompagnement === `Oui`} /> <label for="rad10"> {"Oui"}</label>
                    </div>
                    <div className='radio-input'>        
                       <input id="rad11" type="radio" value={`Non`} name="demandeAccompagnement" checked={demandeAccompagnement === `Non`} /> <label for="rad11"> {"Non"} </label>
                    </div>
                  </div>
                </div>
              </FormRow>


            </div>

          </div>
          <div className="-add-contact-button">
            <Button
              classes={"-add-contact"}
              label={"Confirmer les modifications"}
              type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactPersonnalInfoView;