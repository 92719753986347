import React from "react";
import { Link, Navigate } from "react-router-dom";
import AuthForm from "../components/authForm";
import DoubleAuthForm from "../components/doubleAuthForm";
import { connect } from "react-redux";
import { setUser, setToken, setHasGoogleAuth } from "../redux/store";
import axios from "axios";
import apiPath from "../constants/apiPaths";

import logo from "../assets/img/logo/logo-authentication.svg";
import illustration from "../assets/img/illu-template-login.svg";

class AuthView extends React.Component {
  constructor(props) {
    super(props);

    

    this.state = {
      isLoading: false,
      // token: false,
      // user: false,
      hasGoogleAuth: false,
      logedIn: false,
      email: {
        value: "",
      },
      password: {
        value: "",
      },
      code: {
        value: "",
      },
      error: {
        status: false,
        message: "",
      },
    };
  }
  handleEmailChange = (e) => {
    this.setState({ email: { value: e.target.value } });
  };
  handlePasswordChange = (e) => {
    this.setState({ password: { value: e.target.value } });
  };
  handleAuthFormSumbmited = (e) => {
    e.preventDefault();
    const email = this.state.email;
    const password = this.state.password;
    axios
      .post(`${apiPath}auth/`, {
        email: email.value,
        password: password.value,
      })
      .then((res) => {

        const user = res.data;
        const token = res.headers["x-auth-token"];

        if (!user.role.includes("Administrator")) {
          this.setState({
            error: {
              status: true,
              message: "vous ne disposez pas des droits d'administration",
            },
          });
        }else if (!user.has_valided_email) {
          this.setState({
            error: {
              statue: true,
              message: "Vous devez valider votre adresse email ",
            },
          });
        }
        else if (user.has_google_auth) {
          this.setState({
            user,
            token,
            hasGoogleAuth: res.data.has_google_auth,
          });
        } else {
          this.props.setUser(user);
          this.props.setToken(token);
          this.setState({logedIn : true});
        }
      })
      .catch((err) => {
        this.setState({
          error:{
            status: true, 
            message: err.response.data
          }
        })
      })
      .finally(this.setState({ isLoading: false }));
  };
  handleCodeChange = (e) => {
    // console.log(e.target.value);
    this.setState({ code: { value: e.target.value } });
  };
  handleCodeSubmite = (e) => {
    e.preventDefault();

    const { token } = this.state;
    const { code } = this.state;
    const value = code.value.replace(/\s/g, "");
    console.log({ token }, code);

    axios
      .post(`${apiPath}auth/2fa/`, {
        code: value,
        token: token,
      })
      .then((res) => {
        console.log(this.props)
        const token2 = res.headers["x-auth-token"];
        this.props.setToken(token2);
        this.props.setUser(res.data);
        this.setState({logedIn : true});
       
      })
      .catch((err) => {
        if (err.response)
          this.setState({
            error: { statue: true, message: err.response.data },
          });
      });
  };
  setHasGoogleAuth = (hasGoogleAuth) => this.setState({ hasGoogleAuth });
  setLogedIn = (logedIn) => {
    this.setState({ logedIn });
    this.props.logedIn(logedIn);
  };
  componentDidMount = ()=>{
    if(this.props.user !==false || this.props.token !== false){
      this.props.setUser(false)
      this.props.setToken(false)
    }
  }

  render() {
    return (
      <>
      {this.state.logedIn &&  <Navigate to="/dashboard/usersList" replace={true}/>}
      <section className="login-view-container">
        <div className="-infos">
          <div className="-logo">
            <img src={logo} alt="logo vizio" />
            <p>L’investissement participatif</p>
          </div>
          <div className="-title">
            <h1>Hey, bienvenue sur le BackOffice Vizio&nbsp;!</h1>
            {/* {this.state.error.status && <p>{this.state.error.message}</p>} */}
          </div>
          <img
            className="-illustration"
            src={illustration}
            alt="Illustration building"
          />
        </div>

        <div className="-form">
          {/* <div className="-logo">
            <img src={logo} alt="logo vizio" />
            <p>L’investissement participatif</p>
          </div>
          <div className="-sup-title">
            <h1>Hey, bienvenue chez Vizio&nbsp;!</h1>
          </div> */}

          {!this.state.hasGoogleAuth ? (
            <AuthForm
              email={this.state.email}
              password={this.state.password}
              isLoading={this.state.isLoading}
              error={this.state.error}
              handleEmailChange={this.handleEmailChange}
              handlePasswordChange={this.handlePasswordChange}
              handleAuthFormSumbmited={this.handleAuthFormSumbmited}
            />
          ) : (
            <DoubleAuthForm
              isLoading={this.state.isLoading}
              token={this.state.token}
              code={this.state.code}
              handleCodeChange={this.handleCodeChange}
              handleCodeSubmite={this.handleCodeSubmite}
              // setToken={this.setToken}
              // setUser={this.setUser}
              // setLogedIn={this.setLogedIn}
              // setHasGoogleAuth={this.setHasGoogleAuth}
            />
          )}
        </div>
      </section>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.persistedReducer.user.user,
  token: state.persistedReducer.user.token
});
const actions = {
  setUser,
  setToken,
  setHasGoogleAuth,
};

export default connect(mapStateToProps, actions)(AuthView);
// export default AuthView;
