import React, { Component } from "react";
// import axios from "axios";
import Button from "./button";
import InputText from "./inputText";
import InputPassword from "./inputPassword";
import Infos from "./infos";
import regexConsts from "../constants/regex";
// import apiPath from "../constants/apiPaths";

class AuthForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      email: {
        value: "",
        error: false,
        regex: regexConsts.email,
      },
      password: {
        value: "",
        error: false,
        regex: regexConsts.password,
      },
    };
  }

  checkInput = (value, regex) => regex.test(value);

  render() {
    // console.log(this.props);
    const {
      email,
      password,
      handleEmailChange,
      handlePasswordChange,
      handleAuthFormSumbmited,
    } = this.props;
    return (
      <div>
        <form className="auth-form" onSubmit={handleAuthFormSumbmited}>
          {this.props.error.status && (
            <Infos message={this.props.error.message} classes="error" />
          )}
          <h3 className="-title">Entrez vos identifiants de connexion</h3>
          <InputText
            label="Adresse email"
            value={email.value}
            classes="input"
            // type="text"
            // placeholder=""
            // marginBottom={20}
            // errorMessage="L'e-mail rensigné n'est pas valide"
            // hasError={this.state.email.error}
            handleInputChange={handleEmailChange}
          />
          <InputPassword
            label="Mot de passe"
            value={password.value}
            classes="input password"
            // type="text"
            // placeholder=""
            // marginBottom={10}
            // errorMessage="Le mot de passe dois être reneigné"
            // hasError={this.state.password.error}
            handleInputChange={handlePasswordChange}
          />
          {/* <div className="-forget">
            <a href="#">Mot de passe oublié</a>
          </div> */}
          <Button
            isLoading={this.state.isLoading}
            label="Connexion"
            classes="btn"
            style={{marginTop:"40px"}}
            icon={true}
          />
        </form>
      </div>
    );
  }
}

// const mapStateToProps = (state) => ({
//   user: state.user,
// });
// const actions = {
//   setUser,
//   setToken,
//   setHasGoogleAuth,
// };

// export default connect(mapStateToProps, actions)(AuthForm);
export default AuthForm;
