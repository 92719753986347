import { createContext } from "react";

export const LoaderContext = createContext({
  isLoading:true,
  onSetIsLoading(isLoading){}
});

export const InstantNotifContext = createContext({
  showInstantNotif:false,
  typeInstantNotif:"",
  titleInstantNotif:"",
  messageInstantNotif:"",
  messagesInstantNotif:[],
  onSetShowInstantNotif(showInstantNotif){},
  onSetTypeInstantNotif(typeInstantNotif){},
  onSetTitleInstantNotif(titleInstantNotif){},
  onSetMessageInstantNotif(messageInstantNotif){},
  onSetMessagesInstantNotif(messagesInstantNotif){},
  onSetInstantNotif(showInstantNotif,typeInstantNotif,titleInstantNotif,messageInstantNotif,messagesInstantNotif){},
});
