import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import apiPath from "../../constants/apiPaths";

import IconSearch from "../icons/icon-search";
import IconLogout from "../icons/icon-logout";
import IconHamburger from "../icons/icon-hamburger";

const DashboardHeader = ({user, handleOpenNavbar}) => {

  const {title} = useSelector(state => state.notPersistedReducer.params);
  // console.log(title);

  return (
    <div className="dashboard-header">
      <div className="-left">
        <h2>{title}</h2>
      </div>
      <div className="-right">
        {/* <div className="-search"> */}
          {/* //!recherche */}
          {/* <div className="-inner">
            <div className="-icon">
              <IconSearch />
            </div>
            <input className="-input" type="text" placeholder="Recherche" />
          </div> */}
        {/* </div> */}
        <div className="-profile">

          <div className="-icon" onClick={() => {
            // dispatch(setRedirect({
            //   status: true,
            //   link: "/connexion",
            //   params: [{name: "email", value: user.email}],
            //   deconnexion: true,
            // }));
            window.location.assign("/");
          }}>
            <IconLogout />
          </div>

          <Link className="-link" to={`/dashboard/userEdit/personalInfos/${user.id}`}>
            <h3 className="-name">{user.firstname + " " + user.lastname}</h3>
            <div className="-img">
              {
                user.avatar
                ? <img src={apiPath + "api/users/avatar/" + user.avatar} alt="" ></img>
                : <p>{`${user.lastname.charAt(0).toUpperCase()}${user.firstname.charAt(0).toUpperCase()}`}</p>
              }
            </div>
          </Link>

          <button className="-hamburger" onClick={handleOpenNavbar}>
            <IconHamburger />
          </button>

        </div>
      </div>
    </div>
  )
}

export default DashboardHeader;