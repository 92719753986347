import { createSlice } from "@reduxjs/toolkit";

var initialState = {
  user: false,
  token: false,

  lastName: {
    value: "ghiles 2",
    error: false,
  },

  firstName: {
    value: "rabah 2",
    error: false,
  },

  date: {
    value: "2000-02-01",
    error: false,
  },

  nativeCountry: {
    value: "DZ",
    error: false,
  },

  telNum: {
    value: "0651512183",
    error: false,
  },

  residenceCountry: {
    value: "US",
    error: false,
  },

  postalAddress: {
    value: "2C place de l'union europ",
    error: false,
  },
  
  city: {
    value: "massy 2",
    error: false,
  },
  postalCode: {
    value: "14450",
    error: false,
  },

  isContacts: {
    value: true,
    error: false,
  },
  contacts: [
    {
      _id: "mlsnffmlzenfze",
      firstName: "Fatima",
      lastName: "Ghiles",
      email: "alighiles@gmail.com",
      address: "Ouazlekfnlkznelkfnlkzefndhia centre",
      zipCode: "15450",
      city: "Ouadhia",
      country: "DZ",
      phone: "0224948485",
    },
  ],

  isProfessionnel: {
    value: true,
    error: false,
  },
  socialDenomination: {
    value: "rghiles prodlzkenlkezlkuction",
    error: false,
  },
  companyNumber: {
    value: "00556545",
    error: false,
  },
  headquartersAdress: {
    value: "Paris 15eme je ne sais pas",
    error: false,
  },
  companyPostalCode: {
    value: "75001",
    error: false,
  },
  municipality: {
    value: "Paris",
    error: false,
  },
  companyCountry: {
    value: "FR",
    error: false,
  },
};

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },

    setHasGoogleAuth: (state, action) => {
      state.action = action.payload;
    },

    editFieldProfile: (state, action) => {
      state[action.payload.name] = action.payload.field;
    },

    addContactProfile: (state, action) => {
      state.contacts.push(action.payload);
    },

    editContactProfile: (state, action) => {
      if (state.contacts.length <= 0) state.contacts.push(action.payload);
      else
        state.contacts = state.contacts.map((contact) =>
          contact._id === action.payload._id ? action.payload : contact
        );
    },
    deleteContactProfile: (state, action) => {
      state.contacts = state.contacts.filter(
        (contact) => contact._id !== action.payload
      );
    },
  },
});

export default userSlice;
