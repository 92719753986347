import axios from "axios";
import apiPath from "../../constants/apiPaths";
import Card from "../../components/card";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setTitle } from "../../redux/store";
import { connect } from "react-redux";

import FormSectionTitle from "../../components/formSectionTitle";
import { Link } from "react-router-dom";
import IconArrowColored from "../../assets/svg/navbar-arrow-colored";
import InvestementView from "./InvestmentView";
import { roundNumber } from "../../helpers/roundNumberFunc";

const ValidInvestView = () => {
  const { user } = useSelector((state) => state.persistedReducer);
  const headers = {
    headers: {
      "x-auth-token": user.token,
    },
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTitle("Real-Estate")); //Trouver moyen de recuperer le title directement
    axios
      .get(`${apiPath}investment/`, headers)
      .then((res) => {
        // console.log(res.data);
        setInvestments(res.data);
        axios
          .get(`${apiPath}fonds/`, headers)
          .then((res) => {
            // console.log(res.data)
            setFond(res.data);
            let fond = res.data.find((fond) => fond.name === "Real-Estate");
            setFondRealEstate(fond);
            axios
              .get(`${apiPath}users/`, headers)
              .then((res) => {
                console.log(res.data)
                setUsers(res.data);
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [investments, setInvestments] = useState(null);
  const [users, setUsers] = useState([]);
  const [fonds, setFond] = useState([]);
  const [fondRealEstate, setFondRealEstate] = useState([]);

  return (
    <>
      <div className="_listUsersContainer">
        <div className="_listUsers">
          <FormSectionTitle
            title="Liste des investissements validés"
            classes="-simple-2 _users_title"
          />
          <div className="_users_container ">
            <div className=" -invest -valid">
              <span>INVESTISSEUR</span>
              <span>MONTANT</span>
              <span>SOCIÉTÉ</span>
              <span>DATE DE PAIEMENT</span>
              <span>DATE DU CONTRAT</span>
              <span>STATUT</span>
            </div>

            <ul className="_users_list ">
              {investments &&
                investments.map((investment) => {
                  return (
                    <>
                      {investment.status === "processed" &&
                        investment.fond === fondRealEstate._id && (
                          <li
                            key={investment.id}
                            className=" -investement -active "
                          >
                            <div data-label="UTILISATEUR :">
                              {users.map((user) =>
                                user._id === investment.user
                                  ? user.firstname + " " + user.lastname
                                  : ""
                              )}
                            </div>
                            <div data-label="MONTANT :">
                              {roundNumber(investment.montant)
                                .toLocaleString("en-EU")
                                .replaceAll(",", " ")}
                              €
                            </div>
                            <div data-label="SOCIÉTÉ :">
                              {fonds.map(
                                (fond) =>
                                  fond._id === investment.fond && fond.name
                              )}
                            </div>
                            <div data-label="DATE PAIEMENT :">
                              {new Date(
                                investment.datePaiement
                              ).toLocaleDateString()}
                            </div>
                            <div data-label="DATE CONTRAT :">
                              {new Date(
                                investment.dateContrat
                              ).toLocaleDateString()}
                            </div>
                            <div
                              className={
                                investment.status === "processed"
                                  ? "-succes"
                                  : "-waiting"
                              }
                              data-label="STATUT :"
                            >
                              {investment.status === "processed"
                                ? "validé"
                                : "à traiter"}
                            </div>
                            <Link className="_icon" 
                            to={`/dashboard/userEdit/personalInfos/${investment.user}`} >
                              <IconArrowColored />
                              <p>Voir la fiche</p>
                            </Link>
                          </li>
                        )}
                    </>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  token: state.persistedReducer.user.token,
});

const actions = {
  setTitle,
};

export default connect(mapStateToProps, actions) (ValidInvestView);
