import { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { setFeedback } from '../redux/store';
// import { useContext } from "react";
// import { InstantNotifContext } from "../context/Context";

const FeedBack = (props) => {

  const {feedback} = useSelector(state => state.notPersistedReducer.params);
  const getClasse = (type) => {
    if ( type === "error" ) return "-error";
    else if ( type === "warning" ) return "-warning";
    else return "";
  }
  
  useEffect(()=>{
    setTimeout(()=>{
      props.setFeedback({
        show:false,
        type:"",
        title:"",
        message:"",
        messages:[],
      })
    },10000)
  },[])

  return (
    <>
      <div className={`feedback-container ${feedback.show ? "-active" : ""} ${getClasse(feedback.type)}`}>
        {feedback.title && <h2>{feedback.title}</h2>}
        {
          feedback.message.length > 0 && <p>{feedback.message}</p>
        }
        {
          feedback.messages.length > 0 &&
          <ul>
            {
              feedback.messages.map( (message, index) => <li key={index}>{message}</li> )
            }
          </ul>
        }
      </div>
    </>

  )

}
const mapStateToProps = state => ({
  params: state.notPersistedReducer.params,
});
const actions = {
  setFeedback,
}
export default connect(mapStateToProps,actions)(FeedBack);