import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import FormSectionTitle from '../../components/formSectionTitle';
import InputSelect from '../../components/inputSelect';
import IconArrowColored from "../../assets/svg/navbar-arrow-colored";
import axios from 'axios';
import { useSelector } from 'react-redux';
import apiPath from '../../constants/apiPaths';
import InputText from '../../components/inputText';
import Button from '../../components/button';

const ListContact = () => {

  const { user } = useSelector(state => state.persistedReducer);
  var headers = { headers: { "x-auth-token": user.token } }


  const [contacts, setContacts] = useState([]);
  const [contactNameChange, setContactNameChange] = useState('');
  const [userNameChange, setUserNameChange] = useState('');


// get title of the search bar that has the input that changes
  const getTitle = ()=>{
    if (contactNameChange.length > 0) return "Recherche par contact";
    if (userNameChange.length > 0) return "Recherche par utilisateur";
    else{
      return "Tout les contacts";
    }
  }


  // const getRandomColor = () => {
  //     const codes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, "A", "B", "C" , "D", "E", "F"];
  //     let hexaCode = "";
  //     for (let i=0; i<6; i++) hexaCode += codes[Math.floor(Math.random()*codes.length)];
  //     return `#${hexaCode}`;
  //   }
  const colors = ['red', 'green', 'blue', 'purple', 'orange', 'pink', 'cyan', 'magenta', 'teal', 'lime', 'olive', 'maroon', 'navy', 'brown', 'black', 'gray', 'silver', 'gold', 'tan', 'beige', 'salmon', 'plum', 'peach'];
  let currentIndex = 0;

  const getNextColor = () => {
    const nextColor = colors[currentIndex];
    currentIndex = (currentIndex + 1) % colors.length;
    return nextColor;
  }


  const handleContactNameChange = (e) => {
    setContactNameChange(e.target.value);
  }
  const handleUserNameChange = (e) => {
    setUserNameChange(e.target.value);
  }

  const handleContactSearchSubmit = (e) => {
    e.preventDefault();

    const names = contactNameChange.split(' ');
    let pseudo = '';
    let firstname = '';
    let lastname = '';

    if (names.length > 1) {
      pseudo = names[0];
      lastname = names[1];
      firstname = names[2];
    } else {
      pseudo = contactNameChange;
      firstname = contactNameChange;
      lastname = contactNameChange;
    }

    axios.get(`${apiPath}prospect/contact/search`, {
      params: {
        pseudo,
        firstname,
        lastname,
      }
    })
      .then(res => {
        console.log(res.data);
        setContacts(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };


  const handleUserSearchSubmit = (e) => {
    e.preventDefault();

    const names = userNameChange.split(' ');
    let firstname = '';
    let lastname = '';

    if (names.length > 1) {
      lastname = names[0];
      firstname = names[1];
    } else {
      lastname = userNameChange;
      firstname = userNameChange;
    }

    axios.get(`${apiPath}prospect/userContact/search`, {
      params: {
        firstname,
        lastname,
      }
    })
      .then(res => {
        console.log("Data", res.data);
        const data = res.data;
        const flatData = data.flat();

        const newArray = [];

        for (const item of flatData) {
          newArray.push(item);
        }
        setContacts(newArray);

      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {

    axios.get(`${apiPath}prospect/`, headers)
      .then(res => {
        // console.log(res.data);
        setContacts(res.data);
      }
      )
      .catch(err => {
        console.log(err);
      }
      )
  }, []);


  useEffect(() => {
    if (userNameChange === '') {

    axios.get(`${apiPath}prospect/`, headers)
      .then(res => {
        // console.log(res.data);
        setContacts(res.data);
      }
      )
      .catch(err => {
        console.log(err);
      }
      )}
  }, [userNameChange]);

  useEffect(() => {
    if (contactNameChange === '') {
    axios.get(`${apiPath}prospect/`, headers)
      .then(res => {
        // console.log(res.data);
        setContacts(res.data);
      }
      )
      .catch(err => {
        console.log(err);
      }
      )}
  }, [contactNameChange]);


  return (
    <div>
      <div className="_listContactContainer">
        <div className="_header">
          <div className="-input-wrapper">
            <form onSubmit={handleContactSearchSubmit} className="form-search">
              <InputText
                classes={"search"}
                value={contactNameChange}
                handleInputChange={(e) => { handleContactNameChange(e) }}
                handleInputClick = {() => {setUserNameChange('')}}
                placeholder={"Recherche par contact"}

              />
              <Button
                classes={"btn-search"}
                label={"Ok"}
                type={"submit"}
              />

            </form>
            <form onSubmit={handleUserSearchSubmit} className="form-search">
              <InputText
                classes={"search"}
                value={userNameChange}
                handleInputChange={(e) => { handleUserNameChange(e) }}
                handleInputClick = {() => {setContactNameChange('')}} 
                placeholder={"Recherche par utilisateur"}

              />
              <Button
                classes={"btn-search"}
                label={"Ok"}
                type={"submit"}
              />


            </form>

            {/* <p>Filtrer par :</p>
            <InputSelect
          classes="select-style-2"
          value={selectedUserId}
          required={false}
          handleSelectChange={handleUserChange}
          inputs={[{ id: '', label: 'Tout les contacts' }].concat(usersList.map((user) => {
            return { id: user._id, label: `${user.firstname} ${user.lastname}` };
          }))}
        /> */}

          </div>
        </div>

        <div className="_listContact">
          <FormSectionTitle
            title={getTitle()}
            classes="-simple-2 _contacts_title"
          />

          <div className="_contacts_container">
            <div className="_contacts_header">
              <span></span>
              <span>NOM</span>
              <span>PRÉNOM</span>
              <span>PSEUDO</span>
              <span>TÉLÉPHONE</span>
              <span>EMAIL</span>
              <span>STATUT D'INVESTISSEMENT</span>
              <span>STATUT D'AFFAIRES</span>
              <span>RÉFÉRENT</span>
              <span></span>
            </div>

            <h4 className="_contacts_mb_title">
              {getTitle()}
            </h4>
            <ul className="_contacts_list">
              {contacts.map((contact) => {
                return (
                  <li key={contact._id ? contact._id : contact.id} className="_contact">
                    <div className="_picto">
                      <Link
                        to={`/dashboard/list-contact/contact-personal-info/${contact._id ? contact._id : contact.id}`}
                        style={{ backgroundColor: getNextColor() }}
                        onClick={() =>
                          this.props.setTitle(
                            `Utilisateur : ${contact.lastname} ${contact.firstname}`
                          )
                        }
                      >
                        {/* //<img src={apiPath + "users/avatar/" + user.avatar} alt="user profile image" /> Si l'utilisateur à une photo stocké sur le serveur de visio */}
                        {user.avatar ? (
                          <img
                            src={contact.avatar}
                            alt={`${contact.firstname} ${contact.lastname} avatar`}
                          /> // Si l'image provien d'un site externe
                        ) : (
                          `${(contact.lastname !== "" ) ?
                            contact.lastname
                              .charAt(0)
                              .toUpperCase() : "-"}${(contact.firstname !== "" ) ?
                                contact.firstname
                                  .charAt(0)
                                  .toUpperCase() : "-"}`
                        )}
                      </Link>
                    </div>
                    <div data-label="Nom :">{(contact.lastname === null || contact.lastname === "") ? "Non renseigné" : contact.lastname}</div>
                    <div data-label="Prénom :">{(contact.firstname === null || contact.firstname === "") ? "Non renseigné" : contact.firstname}</div>
                    <div data-label="Pseudo :">{(contact.pseudo === null || contact.pseudo === "") ? "Non renseigné" : contact.pseudo}</div>
                    <div data-label="Téléphone :">{(contact.phone === null || contact.phone === "") ? "Non renseigné" : contact.phone}</div>
                    <div data-label="Email :" className="_email">
                      {contact.mail === null || contact.mail === "" ? "Non renseigné" : contact.mail}
                    </div>
                    <div data-label="Statut d'affaires :" className={contact.business.businessStatus==="Validé"?"-succes":contact.business.businessStatus==="A refusé"?"-failed":contact.business.businessStatus==="En attente"?"-waiting":""} >
                      {contact.business.businessStatus === null || contact.business.businessStatus === "" ? "Non renseigné" : contact.business.businessStatus}
                    </div>
                    <div data-label="Statut d'investissement :" className={contact.investor.investStatus==="Validé"?"-succes":contact.investor.investStatus==="A refusé"?"-failed":contact.investor.investStatus==="En attente"?"-waiting":""}> 
                      {contact.investor.investStatus === null || contact.investor.investStatus === "" ? "Non renseigné" : contact.investor.investStatus}
                    </div>
                    <div data-label="Référent :">
                      {(contact.user.lastname === null || contact.user.lastname === "" ? "" : contact.user.lastname) + " " + (contact.user.firstname === null || contact.user.firstname === "" ? "" : contact.user.firstname)}
                    </div>

                    <Link
                      className="_icon"
                      to={`/dashboard/list-contact/contact-personal-info/${contact._id ? contact._id : contact.id}`}
                      onClick={() =>
                        this.props.setTitle(
                          `Utilisateur : ${contact.lastname} ${contact.firstname}`
                        )
                      }
                    >
                      <IconArrowColored />
                      <p>Voir la fiche</p>
                    </Link>
                  </li>
                );
              })}
            </ul>

          </div>
        </div>


      </div>
    </div>
  );
};

export default ListContact;


