import { Component } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";


import FormSectionTitle from "../../components/formSectionTitle";
import InfosBar from "../../components/infosBar";



import apiPath from "../../constants/apiPaths";

class EditUserBanqueView extends Component{
  constructor(props){
    super(props)
    this.state = {
      token : props.token,
      userReseau : [],
      
    }
  }

    
  componentDidMount(){

    const { id } = this.props.params;
    const headers = {headers: {"x-auth-token": this.props.token}};

    axios.get(`${apiPath}reseau/parrain/${id}`, headers)
    .then(res => {
      console.log(res.data)
      const users =  res.data.filter((user)=>{
        return user.depth === 1;
    })
    console.log(users)
    // console.log(users)
      this.setState({
        userReseau : users ,
      })


    })
    .catch(err => console.err(err));

  }
  getRandomColor = () => {
    const codes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, "A", "B", "C" , "D", "E", "F"];
    let hexaCode = "";
    for (let i=0; i<6; i++) hexaCode += codes[Math.floor(Math.random()*codes.length)];
    return `#${hexaCode}`;
  }


  render(){
    return(
      <>



<div className="dashboard-container ">
        <div className="dashboard-scontainer ">
        <FormSectionTitle
              title="Liste des recommandations"
              classes="-simple-3 "
            />
           
        <div className="security-form-container">
        <div className="_reseau_container">
          <div className="_reseau_header"> 
                <span></span>    
                <span>NOM</span>
                <span>PRÉNOM</span>
                <span>TÉLÉPHONE </span>
                <span>EMAIL </span>
                <span>RÔLE </span>
          </div>

          <ul className="_reseau_list">
            {this.state.userReseau?.map((users)=>{
              return <li key={users.filleule !=null ? users.filleule._id :null} className="_reseau">     
                <div className="_picto"> 
                <a
                style={{backgroundColor:this.getRandomColor()}}
                >{`${users.filleule !=null? users.filleule?.lastname.charAt(0).toUpperCase():"-"}${users.filleule !=null ? users.filleule?.firstname.charAt(0).toUpperCase():"-"}`}</a></div>
                <div data-label="NOM :">{users.filleule?.lastname ===null?"": users.filleule?.lastname}</div>
                <div data-label="PRÉNOM :">{users.filleule?.firstname?users.filleule?.firstname:""}</div>
                <div data-label="TÉLÉPHONE :">{users.filleule?.phone ?users.filleule?.phone:"" }</div>
                <div data-label="EMAIL :">{users.filleule?.email?users.filleule?.email:""}</div>
                <div data-label="RÔLE :">{users.filleule?.role[0]==="User" ? "Utilisateur" : ""}</div>
              </li>
            })}
          </ul>
      </div>
    </div>         
        </div>
      </div>
    
       
    </>
  )}
}

// Utilisé pour accéder aux hooks de react-rooter-v6 dans un composant type classe
export default (props) => {
  const params = useParams();
  const { token } = useSelector(state => state.persistedReducer.user);
  return <EditUserBanqueView params={params} token={token} {...props} />
}