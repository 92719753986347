import { Component, useState } from "react";
import axios from "axios";
import { useSelector,useDispatch } from "react-redux";
import { setTitle } from "../../../redux/store";

import { useParams ,Navigate } from "react-router-dom";


import InfosBar from "../../../components/infosBar";
import FormSectionTitle from "../../../components/formSectionTitle";
import apiPath from "../../../constants/apiPaths";
import Card from "../../../components/card";
import CardAA from "../../../components/cardAA";
import { roundNumber } from "../../../helpers/roundNumberFunc";

class EditUserApportAffaire extends Component{
  constructor(props){
    super(props)
    this.state = {
      token : props.token,
      levéDirectTotal : (0),
      benefaccumulé:(0),
      benefaccumuléCapital:(0),
      montantRetirer:(0),
      montantRetirerCapital:(0),
      redirectDetails:false,
      redirectToTransac:false,
      userCommisionList : [],
      user: false
    }
  }

  
  componentDidMount(){

    const { id } = this.props.params;
    const headers = {headers: {"x-auth-token": this.props.token}};

    axios.get(`${apiPath}fonds/business`, headers)
    .then((res)=>{
      let fondsBusiness = res.data
      let fondAACapital = (fondsBusiness.filter(i=>i.name==="Apport-Affaire-Capitalisation"))[0]
      
      let fondAAReal= fondsBusiness.filter(i=>i.name==="Apport-Affaire-Real-Estate")[0]
    axios
      .get(`${apiPath}investment/user/${id}`, headers)
      .then((res) => {
        let benef = res.data.filter((i) => {
          return i.status === "validé" && i.fond ===fondAAReal._id;
        });
        let benefCapital = res.data.filter((i) => {
          return i.status === "validé" &&  i.fond ===fondAACapital._id;
        });
        let benefTotal = benef.map((b) => parseInt(b.montant))
          .reduce((prev, next) => prev + next,0)
        this.setState({
            userCommisionList : benef.concat(benefCapital),
            benefaccumulé: benefTotal
          })

          let benefTotalCapital = benefCapital.map((b) => parseInt(b.montant))
          .reduce((prev, next) => prev + next,0)
        this.setState({
            benefaccumuléCapital: benefTotalCapital
          })
    
      });

    axios.get(`${apiPath}reseau/parrain/${id}`, headers).then((res) => {
      
      const users = res.data.filter((user) => {
        return user.depth === 0;
      });
      // console.log(users)
      let levéDir =
        users
          .map((i) => parseInt(i.totalLeveeReseau))
          
        this.setState({
            levéDirectTotal : levéDir
          })

          
    })
    axios.get(`${apiPath}withdraw/${id}` , headers)
    .then((res)=>{
      if(res.data.length < 1) return;
      const totalwithdraw = res.data.map((i)=> i.status === "processed" &&  i.compte === "Apport-Affaire-Real-Estate" &&  parseInt(i.amount)).reduce((prev,next)=>prev+next)
      this.setState({
        montantRetirer : totalwithdraw
      })
      const totalwithdrawCapital = res.data.map((i)=> i.status === "processed" && i.compte === "Apport-Affaire-Capitalisation" &&  parseInt(i.amount)).reduce((prev,next)=>prev+next)
      this.setState({
        montantRetirerCapital : totalwithdrawCapital
      })
    })
    .catch(err => console.log(err));
    })
    .catch(err => console.log(err));
  }
  
   

   handleRedirectToTransac = () => {
    this.setState({
        redirectToTransac : true
      })
  };

   handleRedirectDetails = () => {
    this.setState({
        redirectDetails : true
      })
  };
 
 
  


  getRandomColor = () => {
    const codes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, "A", "B", "C" , "D", "E", "F"];
    let hexaCode = "";
    for (let i=0; i<6; i++) hexaCode += codes[Math.floor(Math.random()*codes.length)];
    return `#${hexaCode}`;
  }

  render(){
    const { id } = this.props.params;
    console.log(this.state.montantRetirer)
    var benefDispo = this.state.benefaccumulé - this.state.montantRetirer
    var benefDispoCapital = this.state.benefaccumuléCapital - this.state.montantRetirerCapital
    if (this.state.redirectToTransac) 
    return <Navigate to={`/dashboard/userEdit/transaction/${id}`} replace={true} />

    
    if (this.state.redirectDetails)
    return <Navigate to={`/dashboard/userEdit/detail-apport-affaire/${id}`} replace={true} />;


    return(
      <>
              <div className="-big-card-container">

        <div className="information-container -apporAf">
            <div className="-title">
              <h4 className="-texte">Informations préalables</h4>
            </div>
            <div className="-information">
              <InfosBar
                classes="-info"
                content={
                  "Lorsque grâce à votre intervention, un investissement est réalisé dans nos projets pour un minimum de 500 EUR, nous octroyons une indemnité unique pour vous remercier de cet apport. Suivant votre statut fiscal et social, une facture pourra être demandée avant tout paiement ou celui-ci s’accompagnera de l’émission d’une fiche fiscale ad hoc (disponible dans « Documents »)."
                }
              />
              <InfosBar
                classes="-error"
                content={
                  "Lorsque vous devenez apporteur d’affaires, vous signez un contrat dans lequel toutes les conditions sont détaillées. Vous pouvez également contacter Vizio pour toute question à ce sujet via l’adresse mail suivante : marketing@viziocapital.com."
                }
              />
              <InfosBar
                classes="-error"
                content={
                  "La loi ne vous autorise pas à offrir nos produits ou services directement à des tiers sans un agrément spécifique d’intermédiaire mais uniquement à les mettre en contact avec nous. Vous n’êtes donc pas autorisé à offrir nos solutions ni à utiliser les informations disponibles sur la Plateforme, sauf accord préalable de Vizio."
                }
              />
            </div>
          </div>
          <div className="dashboard-container -card-container">
            <Card
              classes={"first-elmt"}
              header={"Montant levé"}
              amount={`${roundNumber(this.state.levéDirectTotal)
                .toLocaleString("en-EU")
                .replaceAll(",", " ")} €`}
              button
              text={"Total des dépôts de mes recommandations dans Vizio"}
              btnlabel={"voir le détail"}
              action={this.handleRedirectDetails}
            />

            <CardAA
             classes={"-apport"}
              header={"Mes indemnités disponibles"}
              amount={`${roundNumber(benefDispo).toLocaleString("en-EU")
              .replaceAll(",", " ")} €`}
              amount2 = {`${roundNumber(benefDispoCapital)
                .toLocaleString("en-EU")
                .replaceAll(",", " ")} €`} //!benefDispo deuxiemme societé
              text2 ={"Générés grâce à mon montant levé dans "}
              society1={" Vizio Real Estate"}
              society2={" Vizio Capitalisation"}
              button
              text={"Générés grâce à mon montant levé dans"}
            //   btnDisable={benefice > 500 && benefDispo > 0 ? false : true}
            //   btnlabel={"Retirer"}
            //   action={handleRedirect}
            />

            <CardAA
              header={"Total indemnités"}
              amount={`${roundNumber(this.state.benefaccumulé)
                .toLocaleString("en-EU")
                .replaceAll(",", " ")} €`}
                amount2={`${roundNumber(this.state.benefaccumuléCapital)
                  .toLocaleString("en-EU")
                  .replaceAll(",", " ")} €`}//!benef deuxiemme societé
                text2={"Généres sur l’ensemble de mon montant levé dans"}
                society1={" Vizio Real Estate"}
                society2={" Vizio Capitalisation"}
              button
              text={"Généres sur l’ensemble de mon montant levé dans"}
              // btnlabel={"Historique de retrait"}
              // action={this.handleRedirectToTransac}
            />
          </div>
          <div className="affaire-container">
            <div className="affaire-scontainer -no-padding">
              <FormSectionTitle
                title="Détail de mes indemnités"
                classes="-simple-2"
              />

              <div className="security-form-container">
                <div className="_affaire_container">
                  <div className="_affaire_header">
                    <span></span>
                    <span>NOM</span>
                    <span>PRÉNOM</span>
                    <span>DATE </span>
                    <span>INDEMENITÉS</span>
                    <span>STATUT</span>
                    <span>INVESTISSEMENT</span>
                    <span></span>
                  </div>

                  <ul className="_affaire_list">
                  {this.state.userCommisionList?.map((fil, id) => {
                      return (
                        <li key={id} className="_affaire">
                          <div className="_picto">
                            <a style={{ backgroundColor: this.getRandomColor() }}>
                              {`${fil.filleul !=null ? fil.filleul.lastname
                                .charAt(0)
                                .toUpperCase():""}${fil.filleul !=null?
                                  fil.filleul.firstname
                                .charAt(0)
                                .toUpperCase():"-"}`}
                            </a>
                          </div>
                          <div data-label="NOM :">{fil.filleul !=null ? fil.filleul.lastname:"-"}</div>
                          <div data-label="PRÉNOM :">
                            {fil.filleul !=null? fil.filleul.firstname:"-"}
                          </div>
                          <div data-label="DATE :">
                            {new Date(fil.dateContrat).toLocaleDateString()}
                          </div>
                          <div data-label="INDEMENITÉS :">
                            {" "}
                            {roundNumber(fil.montant)
                              .toLocaleString("en-EU")
                              .replaceAll(",", " ")}{" "}
                            €
                          </div>
                          <div data-label="STATUT : " className="-succes">
                            validé
                          </div>
                          <div data-label="INFORMATION :">
                            {fil.fromFond.fond.name}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              </div>
            </div>
          </div>
    </>
  )}
}

// Utilisé pour accéder aux hooks de react-rooter-v6 dans un composant type classe
export default (props) => {
  const params = useParams();
  const { token} = useSelector(state => state.persistedReducer.user);
  const headers = {headers: {"x-auth-token": token}};
 const dispatch = useDispatch()
  axios.get(`${apiPath}users/${params.id}`, headers) //? Pour set le tiltle en composant class
  .then(res => {
    dispatch(setTitle(`Utilisateur: ${res.data.lastname} ${res.data.firstname}`))
    })
return <EditUserApportAffaire params={params} token={token} {...props} />
}