import InputDate from "../components/inputDate"
import FormRow from "../components/formRow"
import InputSelect from "../components/inputSelect"
import Button from "../components/button"
import axios from 'axios';
import React,{ useState,useEffect } from "react";
import apiPath from "../constants/apiPaths";
import { Navigate, useNavigate } from "react-router-dom";
import { setTitle} from '../redux/store';
import { useSelector, useDispatch } from "react-redux"
const AddDocumentView = ()=>{

  const {user} = useSelector (state=> state.persistedReducer)

  var headers = {headers: {"x-auth-token" : user.token}}

  const inputDocType = [
    {id:"CAA", name:"Contrat apporteur d’affaires"},
    {id:"CC", name:"Contrat commercial"},
    {id:"FC", name:"Fiche de commission"},
    {id:"FACTURE", name:"Facture"},
    {id:"CPRLV", name:"Contrat pré-levée "},
    {id:"FF", name:"Fiche fiscale"},
    {id:"PPAY", name:"Preuve de paiment"},
    {id:"AVC", name:"Attestation vizio capitalisation"},
    {id:"AVR", name:"Attestation vizio real estate"}
  ]
  // console.log(headers)

  const [users,setUsers] = useState([])
  const [userId,setUserId] = useState(null)
  const [docType, setDocType] = useState(inputDocType[0].id)
  const [date,setDate] = useState("")
  const [file, setFile] = useState(null)
  const [donneManquant,setDonneManquant] = useState(false)
  const [redirect,setRedirect] = useState(false)

  const dispatch = useDispatch()



  // const { user} = useSelector(state => state.persistedReducer);
  // console.log(user);

  useEffect (()=>{
        
    dispatch(setTitle("Ajouter un document"))

    axios.get(`${apiPath}users/`, headers)
    .then((res)=>{
      // console.log(res.data);
        setUsers(res.data)
        setUserId(res.data[0]._id)
      }).catch (err => {
          console.log(err);
          })  
},[])

  


  const inputUsers = users.map((i)=>{
    return {
      id:i._id,
      name:i.lastname + " " + i.firstname
    }
  })
 

  

  // setDocType(inputDocType[0].id)
    
  
const handleSelectUser = (e)=>{
setUserId(e.target.value)
}

const handleSelectDoc = (e)=>{
  setDocType(e.target.value);
}

const handleFile = (e)=>{
  if(!e.target.files[0]){
    setFile(null)
    return
  }
  setFile(e.target.files[0])
}

const handleDateChange = (e)=>{
  setDate(e.target.value)
}
const handleFormSubmit = (e)=>{
  e.preventDefault()

  const formData = new FormData()

  //setting des headers : 
 

  headers ={...headers, "x-auth-token" : user.token}
  headers ={...headers, "userid":userId ? userId : users[0]._id}
  headers ={...headers, "doctype":docType ?docType:""}
  headers ={...headers, "Content-type":"multipart/form-data"}

  // console.log({headers})

  const body = {
    user: userId ? userId : users[0]._id,
    date : date,
    doc : docType,
            
  }
  //preparation du body : 
  formData.append('body',JSON.stringify(
    {
      user: userId ? userId : users[0]._id,
      date : date ? date :"",
      doc : docType ? docType : "",
              
    }
  ))
  //si fichier, on l'ajoute ... 
  if(file){    
    formData.append('file',file)
    // console.log(file)
  }

  if(userId === null || date===null || docType===null || file===null){
    setDonneManquant (true)
  } else{
  
    let result =  window.confirm("êtes vous sur de vouloir continuer")
    if(result === true){
      setDonneManquant(false)

  axios.post(`${apiPath}docupload`,formData,{
    headers: {
      "Content-Type": "multipart/form-data",
      "x-auth-token" : user.token,
      doctype :docType ? docType:"",
      "userid":userId ? userId : users[0]._id
    }
  })
  .then((res)=>{
    setRedirect(true)
  })
}
}




}

if (redirect) return <Navigate to={`/dashboard/userEdit/documents/${userId}`}/>

return(
  <div className="layout-dashboard-container">
    <div className="dashboard-container">
      <div className="dashboard-scontainer">
              <form className="investor-form -doc"  onSubmit= {handleFormSubmit} >
              <div className="section-title">
                  <h4 className="-texte">
                    Ajout du document
                  </h4>

                  { donneManquant === true && <p className="error">Des Données sont manquantes !!! </p>}
                </div>
                  <FormRow classes={"-two"}>
                      <InputSelect 
                        label="Selectionner un utilisateur"
                        
                        inputs=  {inputUsers.map(f => {
                          return( 
                              {
                              id:`${f.id}` ,
                              label: `${f.name}` ,
                              value: `${f.name}`
                              }
                          )})} 
                          handleSelectChange = {(e)=>handleSelectUser(e)}
                        />
                      <InputSelect 
                        label="Selectionner le type de document"
                        handleSelectChange = {(e)=>handleSelectDoc(e)}
                        inputs=  {inputDocType.map(f => {
                          return( 
                              {
                              id:`${f.id}` ,
                              label: `${f.name}` ,
                              value: `${f.name}`
                              }
                          )})}

                        />
                      <InputDate
                            label="Entrez la date"
                            value = {date}
                            
                            handleDateChange = {(e)=>handleDateChange(e)} 
                            
                            // handleInputChange={handleEmailChange}
                          />
                    
                  
              </FormRow>
                  <label className="label-doc"> Ajouter le document </label>
                    <input
                    className="file"
                        type='file'
                        onChange={(e)=>{handleFile(e)}}
                          />
                    <p>{`formats acceptés : pdf, jpg, png < 2 Mo`} </p>
                    <Button
                        classes = '-tiny'
                        type="submit"
                        label={"Ajouter le document"}
                        icon />
                </form>
      </div>
    </div>
  </div>
  )

}
export default AddDocumentView