import IconArrowDropdown from "./icons/icon-dropdown";

const InputText = (props) => {

  const {
    classes,
    value,
    type,
    label,
    indication,
    handleDateChange,
    error,
    min,
    max
  } = props;

  const required = props.required === false ? false : true;

  return (
    <label className={`input ${classes ? classes : ""} ${error ? "-error" : "" }`}>
      { required ? <span className="-required">{label}</span> : <span>{label}</span> }
      <div className="-select-wrapper">
        {/* <div className="-icon">
        <IconArrowDropdown />
        </div> */}
      <input
        type={type ? type : "date"}
        value={value ? value : ""}
        min={min ? min : ""}
        max={max ? max : ""}
        onChange={handleDateChange}
      />
      </div>
      {indication && <p className={indication ? "-indication" : "" }>{indication}</p>}
      {error && <p className={error ? "-error" : ""}>{error}</p>}
    </label>
  )
}

export default InputText;