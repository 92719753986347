import { useState, useEffect } from "react";

import { Link, useLocation, useNavigate } from "react-router-dom";

import InputSelect from "../inputSelect";

const SubNav = ({links}) => {

  const [menu, setMenu] = useState([...links]);
  const [value, setValue] = useState("");
  const location = useLocation();
  const navigate = useNavigate();


  useEffect(() => {
    const newMenu = menu.map(item => item.link === location.pathname ? {...item, isActive: true} : {...item, isActive: false});
    setMenu(newMenu);
    setValue(location.pathname);
  }, [location])



  return (
    <div className="sub-navbar-container">
      <ul className="sub-navbar">
        {
          menu.map(item => <li key={item.id}>
            <Link 
            className={item.isActive ? "-active" : ""} 
            to={item.link}>{item.label}
          
            </Link>
            </li>)
        }
      </ul>

      <InputSelect
        classes="select-style-2"
        value={value}
        handleSelectChange={(e) => navigate(e.target.value, true)}
        required={false}
        inputs={menu.map(item => {
          return {
            id:item.link,
            label:item.label
          }
        })}
      />

    </div>
  )

}
 
export default SubNav;