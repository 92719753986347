import React, { useState, useEffect } from "react";
import axios from "axios";
import { Navigate, useParams } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";
import { setTitle } from '../../redux/store';

import FormRowCheckbox from "../../components/formRowCheckbox";
import InputText from "../../components/inputText";

import Button from "../../components/button";


import apiPath from "../../constants/apiPaths";
import FormRow from "../../components/formRow";

const RequestView = ()=>{

  const { user} = useSelector(state => state.persistedReducer);
  const headers = {
    headers: {
      "x-auth-token" : user.token,
    }
  }
  const dispatch = useDispatch()
  const params = useParams()
  const id = params.id 

useEffect(() => {
  dispatch(setTitle("Demandes"))
  axios.get(`${apiPath}request/${id}`,headers)
  .then((res)=>{
    setReqForm(res.data.requestForm)
  })
 
}, []);

  const [reqForm, setReqForm]=useState({})
  // console.log(reqForm)

  const [redirectToValid, setRedirectToValid] = useState(false)
  const [redirectToList, setRedirectToList] = useState(false)

  const [langueParler, setLangueParler] = useState([
    {
      id: 1,
      label: "Français",
      value: false,
    },
    {
      id: 2,
      label: "Anglais",
      value: false,
    },
    {
      id: 3,
      label: "Néerlandais",
      value: false,
    },
    {
      id: 4,
      label: "Allemand",
      value: false,
    },
    {
      id: 5,
      label: "Italien",
      value: false,
    },
    {
      id: 6,
      label: "Espagnol",
      value: false,
    },
    {
      id: 7,
      label: "Chinois",
      value: false,
    },
  ]);
  const [passionsValue, setPassionsValue] = useState([
    {
      id: 1,
      label: "Sport",
      value: false,
    },
    {
      id: 2,
      label: "Musique",
      value: false,
    },
    {
      id: 3,
      label: "Fêtes",
      value: false,
    },
    {
      id: 4,
      label: "Voyage",
      value: false,
    },
    {
      id: 5,
      label: "Lecture",
      value: false,
    },
    {
      id: 6,
      label: "Formations",
      value: false,
    },
    {
      id: 7,
      label: "Rire",
      value: false,
    },
    {
      id: 8,
      label: "Spectacle",
      value: false,
    },
    {
      id: 9,
      label: "Cinéma/ Films",
      value: false,
    },
    {
      id: 10,
      label: "Networking",
      value: false,
    },
    {
      id: 11,
      label: "Autres",
      value: false,
    },
  ]);


  const  handleConfirmRequest = (e,value)=>{

    e.preventDefault();
    const body ={
      status: value === 1 ? "approved" : "disapproved"
    }
    let confirm = window.confirm("Confirmez-vous cette action ? ")
    if(confirm){
    axios.patch(`${apiPath}request/${id}`,body,headers)
    .then((res)=>{
    if(res.data){
      setRedirectToValid(true)
    }
    else{
      setRedirectToList(true)
    }
    })
    .catch((err)=>{
      console.log(err)
    })
  }

    // if(value===1){
    //   setRedirectToValid(true)
    // }else{
    //   setRedirectToList(true)
    // }
  }
  
  if (redirectToValid) return <Navigate to={"/request/valid-requests"} replace={true}/>
  if (redirectToList) return <Navigate to={"/request"} replace={true}/>
  return (
    <>
        
      <div className="layout-profile-container">
        <div className="dashboard-container">
          <div className="dashboard-scontainer">
          
          {/* { donneManquant === true && <p className="error">Des Données sont manquantes !!! </p>} */}

            <form className="form-container" >
      
              <div className="form-section-title " >
                <p>Formulaire commercial</p>

              </div>

              <FormRow classes="-two">
                <InputText
                  label={"niveau d'études"}
                
                  value={reqForm.userNivEtude}
                 
                />
                <InputText
                  label={"Profession actuelle"}
                  value={reqForm.userProf}
                  
                  max={100}
                />
              </FormRow>

              <FormRow classes="-two">
                <InputText
                  label={"Permis de conduire"}
                  
                  value={reqForm.userPermis}
                  
                />
                <InputText
                  label={"Véhicule"}
                 
                  value={reqForm.userVehicule}
                 
                />
              </FormRow>

              <FormRow classes="-two">
                <InputText
                  label={
                    "J’ai déjà suivi une formation dans le monde de la finance ?"
                  }
                  
                  value={reqForm.userFormation}

                
                />
                { (
                 reqForm.userFormationSuivis !== "" && <InputText
                    label={"Si oui, précisez : "}
                    value={reqForm.userFormationSuivis}
                    
                    max={200}
                  />
                )}

                <InputText
                  label={"Puis-je consacrer du temps à la formation ?"}
                  
                  value={reqForm.userTempsFormation}
                 
                />
                {
                  <InputText
                    label={"Si oui, précisez combien de temps (+/-) : "}
                    value={reqForm.userCbTempsFormation}
                   
                   
                  />
               }
              </FormRow>
              <FormRow classes="-One">
                <InputText
                  label={
                    " Combien de temps par semaine puis-je consacrer à mon activité d’apporteurs d’affaires chez Vizio Capital ?                   "
                  }
                 
                  value={reqForm.userCbTimeVizioWeek}
                 
                />
              </FormRow>
              <FormRow classes="-One">
                <div className="-checkbox">
                  <p> Je parle correctement les langues suivantes :</p>
                  {langueParler.map((n) => {
                    return (
                      <FormRowCheckbox
                        isActive={(reqForm.userLangueParler)?(reqForm.userLangueParler).includes(n.label):true}
                        label={n.label}
                        
                      />
                    );
                  })}
                </div>
              </FormRow>
              <FormRow classes="-One">
                <InputText
                  label={"Mes motivations sont orientées sur :"}
                  value={reqForm.userMotivation}
                 
                  max={500}
                />
              </FormRow>
              <FormRow classes="-two">
                <InputText
                  label={"Le statut d’entrepreneur indépendant m’intéresse :"}
                  
                  value={reqForm.userEntStatus}
                  
                />
                <InputText
                  label={"Mon statut préféré :"}
                  
                  value={reqForm.userPrefStatus}
                 
                />
              </FormRow>
              {(
                reqForm.userAutreStatus!== ""&&<FormRow classes="-One">
                  <InputText
                    label={"Si autre, précisez le status que vous préféré "}
                    value={reqForm.userAutreStatus}
                   
                    max={100}
                  />
                </FormRow>
              )}
              <FormRow classes="-One">
                <InputText
                  label={
                    "Quelles ressources puis-je apporter à Vizio Capital ?"
                  }
                  value={reqForm.userRessourceApporter}
                  
                  max={200}
                />
              </FormRow>
              <FormRow classes="-One">
                <div className="-checkbox">
                  <p>Mes passions :</p>
                  {passionsValue.map((n) => {
                    return (
                      <FormRowCheckbox
                        label={n.label}
                        isActive={reqForm.userPassion?reqForm.userPassion.includes(n.label):true}
                       
                      />
                    );
                  })}
                </div>
              </FormRow>
              <FormRow classes="-One">
                <InputText
                  label={
                    "Ce que je souhaite transmettre à mes enfants / proches : "
                  }
                  
                  value={reqForm.userTransmettre}
                  
                />
              </FormRow>
              { (
                reqForm.userAutreTransm!== ""&&<FormRow classes="-One">
                  <InputText
                    label={"Si autre, précisez le status que vous préféré "}
                    value={reqForm.userAutreTransm}
                   
                    max={100}
                  />
                </FormRow>
              )}

              <FormRow classes="-One">
                <InputText
                  label={"Quel est mon tempérament ?"}
                  
                  value={reqForm.userTemperament}
                  
                />
              </FormRow>
              { (
                reqForm.userAutreTemper !== ""&&<FormRow classes="-One">
                  <InputText
                    label={"Si autre, précisez le status que vous préféré "}
                    value={reqForm.userAutreTemper}
                    
                    max={100}
                  />
                </FormRow>
              )}

              <FormRow classes="-One">
                <InputText
                  label={
                    "Si je devais changer ou apporter quelque chose à ce monde, ce serait :"
                  }
                  value={reqForm.userChangement}
                 
                />
              </FormRow>
              <FormRow classes="-One">
                <InputText
                  label={
                    "Qu’est ce qui pourrait m’effrayer ou me déplaire dans ce genre d’activité :"
                  }
                  
                  value={reqForm.userDeplaire}
                  
                />
              </FormRow>
              {(
                reqForm.userAutreDeplaire !== ""&& <FormRow classes="-One">
                  <InputText
                    label={"Si autre, précisez le status que vous préféré "}
                    value={reqForm.userAutreDeplaire}
                    
                    max={100}
                  />
                </FormRow>
              )}
              <div className="form-footer">           
              <Button
              label={"Accepter en tant que commercial"}
              // classes = '-small'
              type="submit"
              icon 
              handleClick={(e)=>handleConfirmRequest(e,1)}
              />
              </div> 
              <div className="cancelbtn"><button onClick={(e)=>handleConfirmRequest(e,2)}>Refuser la demande</button></div>
              

            </form>
          </div>
        </div>
      </div>
    </>
  );
}
export default RequestView