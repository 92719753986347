import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  //Page title
  title: "",

  //instant notif 
  feedback: {
    show: false,
    type: "",
    title: "",
    message: "",
    messages: []
  },
}
const paramsSlice = createSlice({
  name: "params",
  initialState : initialState,
  reducers : {
    //title
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    //instant notif
    setFeedback: (state, action) => {
      if ( action.payload ) {
        state.feedback = {
          show: action.payload?.show || false,
          type: action.payload?.type || "",
          title: action.payload?.title || "",
          message: action.payload?.message || "",
          messages: action.payload?.messages || [],
        }
      }else {
        state.feedback = {
          ...state.feedback,
          show: false,
        }
      }
    },
  }
})
export default paramsSlice;