export default [
    {id: "AF", label: "Afghanistan"},
    {id: "ZA", label: "Afrique du Sud"},
    {id: "AL", label: "Albanie"},
    {id: "DZ", label: "Algérie"},
    {id: "DE", label: "Allemagne"},
    {id: "AD", label: "Andorre"},
    {id: "AO", label: "Angola"},
    {id: "AI", label: "Anguilla"},
    {id: "AQ", label: "Antarctique"},
    {id: "AG", label: "Antigua-et-Barbuda"},
    {id: "AN", label: "Antilles néerlandaises"},
    {id: "SA", label: "Arabie saoudite"},
    {id: "AR", label: "Argentine"},
    {id: "AM", label: "Arménie"},
    {id: "AW", label: "Aruba"},
    {id: "AU", label: "Australie"},
    {id: "AT", label: "Autriche"},
    {id: "AZ", label: "Azerbaïdjan"},
    {id: "BS", label: "Bahamas"},
    {id: "BH", label: "Bahreïn"},
    {id: "BD", label: "Bangladesh"},
    {id: "BB", label: "Barbade"},
    {id: "BY", label: "Bélarus"},
    {id: "BE", label: "Belgique"},
    {id: "BZ", label: "Belize"},
    {id: "BJ", label: "Bénin"},
    {id: "BM", label: "Bermudes"},
    {id: "BT", label: "Bhoutan"},
    {id: "BO", label: "Bolivie"},
    {id: "BA", label: "Bosnie-Herzégovine"},
    {id: "BW", label: "Botswana"},
    {id: "BR", label: "Brésil"},
    {id: "BN", label: "Brunéi Darussalam"},
    {id: "BG", label: "Bulgarie"},
    {id: "BF", label: "Burkina Faso"},
    {id: "BI", label: "Burundi"},
    {id: "KH", label: "Cambodge"},
    {id: "CM", label: "Cameroun"},
    {id: "CA", label: "Canada"},
    {id: "CV", label: "Cap-Vert"},
    {id: "EA", label: "Ceuta et Melilla"},
    {id: "CL", label: "Chili"},
    {id: "CN", label: "Chine"},
    {id: "CY", label: "Chypre"},
    {id: "CO", label: "Colombie"},
    {id: "KM", label: "Comores"},
    {id: "CG", label: "Congo-Brazzaville"},
    {id: "KP", label: "Corée du Nord"},
    {id: "KR", label: "Corée du Sud"},
    {id: "CR", label: "Costa Rica"},
    {id: "CI", label: "Côte d’Ivoire"},
    {id: "HR", label: "Croatie"},
    {id: "CU", label: "Cuba"},
    {id: "DK", label: "Danemark"},
    {id: "DG", label: "Diego Garcia"},
    {id: "DJ", label: "Djibouti"},
    {id: "DM", label: "Dominique"},
    {id: "EG", label: "Égypte"},
    {id: "SV", label: "El Salvador"},
    {id: "AE", label: "Émirats arabes unis"},
    {id: "EC", label: "Équateur"},
    {id: "ER", label: "Érythrée"},
    {id: "ES", label: "Espagne"},
    {id: "EE", label: "Estonie"},
    {id: "VA", label: "État de la Cité du Vatican"},
    {id: "FM", label: "États fédérés de Micronésie"},
    {id: "US", label: "États-Unis"},
    {id: "ET", label: "Éthiopie"},
    {id: "FJ", label: "idj"},
    {id: "FI", label: "Finlande"},
    {id: "FR", label: "France"},
    {id: "GA", label: "Gabon"},
    {id: "GM", label: "Gambie"},
    {id: "GE", label: "Géorgie"},
    {id: "GS", label: "Géorgie du Sud et les îles Sandwich du Sud"},
    {id: "GH", label: "Ghana"},
    {id: "GI", label: "Gibraltar"},
    {id: "GR", label: "Grèce"},
    {id: "GD", label: "Grenade"},
    {id: "GL", label: "Groenland"},
    {id: "GP", label: "Guadeloupe"},
    {id: "GU", label: "Guam"},
    {id: "GT", label: "Guatemala"},
    {id: "GG", label: "Guernesey"},
    {id: "GN", label: "Guinée"},
    {id: "GQ", label: "Guinée équatoriale"},
    {id: "GW", label: "Guinée-Bissau"},
    {id: "GY", label: "Guyana"},
    {id: "GF", label: "Guyane française"},
    {id: "HT", label: "Haïti"},
    {id: "HN", label: "Honduras"},
    {id: "HU", label: "Hongrie"},
    {id: "BV", label: "Île Bouvet"},
    {id: "CX", label: "Île Christmas"},
    {id: "CP", label: "Île Clipperton"},
    {id: "AC", label: "Île de l'Ascension"},
    {id: "IM", label: "Île de Man"},
    {id: "NF", label: "Île Norfolk"},
    {id: "AX", label: "Îles Åland"},
    {id: "KY", label: "Îles Caïmans"},
    {id: "IC", label: "Îles Canaries"},
    {id: "CC", label: "Îles Cocos - Keeling"},
    {id: "CK", label: "Îles Cook"},
    {id: "FO", label: "Îles Féroé"},
    {id: "HM", label: "Îles Heard et MacDonald"},
    {id: "FK", label: "Îles Malouines"},
    {id: "MP", label: "Îles Mariannes du Nord"},
    {id: "MH", label: "Îles Marshall"},
    {id: "UM", label: "Îles Mineures Éloignées des États-Unis"},
    {id: "SB", label: "Îles Salomon"},
    {id: "TC", label: "Îles Turks et Caïques"},
    {id: "VG", label: "Îles Vierges britanniques"},
    {id: "VI", label: "Îles Vierges des États-Unis"},
    {id: "IN", label: "Inde"},
    {id: "IDN", label: "Indonésie"},
    {id: "IQ", label: "Irak"},
    {id: "IR", label: "Iran"},
    {id: "IE", label: "Irlande"},
    {id: "IS", label: "Islande"},
    {id: "IL", label: "Israël"},
    {id: "IT", label: "Italie"},
    {id: "JM", label: "Jamaïque"},
    {id: "JP", label: "Japon"},
    {id: "JE", label: "Jersey"},
    {id: "JO", label: "Jordanie"},
    {id: "KZ", label: "Kazakhstan"},
    {id: "KE", label: "Kenya"},
    {id: "KG", label: "Kirghizistan"},
    {id: "KI", label: "Kiribati"},
    {id: "KW", label: "Koweït"},
    {id: "LA", label: "Laos"},
    {id: "LS", label: "Lesotho"},
    {id: "LV", label: "Lettonie"},
    {id: "LB", label: "Liban"},
    {id: "LR", label: "Libéria"},
    {id: "LY", label: "Libye"},
    {id: "LI", label: "Liechtenstein"},
    {id: "LT", label: "Lituanie"},
    {id: "LU", label: "Luxembourg"},
    {id: "MK", label: "Macédoine"},
    {id: "MG", label: "Madagascar"},
    {id: "MY", label: "Malaisie"},
    {id: "MW", label: "Malawi"},
    {id: "MV", label: "Maldives"},
    {id: "ML", label: "Mali"},
    {id: "MT", label: "Malte"},
    {id: "MA", label: "Maroc"},
    {id: "MQ", label: "Martinique"},
    {id: "MU", label: "Maurice"},
    {id: "MR", label: "Mauritanie"},
    {id: "YT", label: "Mayotte"},
    {id: "MX", label: "Mexique"},
    {id: "MD", label: "Moldavie"},
    {id: "MC", label: "Monaco"},
    {id: "MN", label: "Mongolie"},
    {id: "ME", label: "Monténégro"},
    {id: "MS", label: "Montserrat"},
    {id: "MZ", label: "Mozambique"},
    {id: "MM", label: "Myanmar"},
    {id: "NA", label: "Namibie"},
    {id: "NR", label: "Nauru"},
    {id: "NP", label: "Népal"},
    {id: "NI", label: "Nicaragua"},
    {id: "NE", label: "Niger"},
    {id: "NG", label: "Nigéria"},
    {id: "NU", label: "Niue"},
    {id: "NO", label: "Norvège"},
    {id: "NC", label: "Nouvelle-Calédonie"},
    {id: "NZ", label: "Nouvelle-Zélande"},
    {id: "OM", label: "Oman"},
    {id: "UG", label: "Ouganda"},
    {id: "UZ", label: "Ouzbékistan"},
    {id: "PK", label: "Pakistan"},
    {id: "PW", label: "Palaos"},
    {id: "PA", label: "Panama"},
    {id: "PG", label: "Papouasie-Nouvelle-Guinée"},
    {id: "PY", label: "Paraguay"},
    {id: "NL", label: "Pays-Bas"},
    {id: "PE", label: "Pérou"},
    {id: "PH", label: "Philippines"},
    {id: "PN", label: "Pitcairn"},
    {id: "PL", label: "Pologne"},
    {id: "PF", label: "Polynésie française"},
    {id: "PR", label: "Porto Rico"},
    {id: "PT", label: "Portugal"},
    {id: "QA", label: "Qatar"},
    {id: "HK", label: "R.A.S. chinoise de Hong Kong"},
    {id: "MO", label: "R.A.S. chinoise de Macao"},
    {id: "QO", label: "régions éloignées de l’Océanie"},
    {id: "CF", label: "République centrafricaine"},
    {id: "CD", label: "République démocratique du Congo"},
    {id: "DO", label: "République dominicaine"},
    {id: "CZ", label: "République tchèque"},
    {id: "RE", label: "Réunion"},
    {id: "RO", label: "Roumanie"},
    {id: "GB", label: "Royaume-Uni"},
    {id: "RU", label: "Russie"},
    {id: "RW", label: "Rwanda"},
    {id: "EH", label: "Sahara ocidenta" },
    {id: "BL", label: "Saint-Barthélémy"},
    {id: "KN", label: "Saint-Kitts-et-Nevis"},
    {id: "SM", label: "Saint-Marin"},
    {id: "MF", label: "Saint-Martin"},
    {id: "PM", label: "Saint-Pierre-et-Miquelon"},
    {id: "VC", label: "Saint-Vincent-et-les Grenadines"},
    {id: "SH", label: "Sainte-Hélène"},
    {id: "LC", label: "Sainte-Lucie"},
    {id: "WS", label: "Samoa"},
    {id: "AS", label: "Samoa américaines"},
    {id: "ST", label: "Sao Tomé-et-Principe"},
    {id: "SN", label: "Sénégal"},
    {id: "RS", label: "Serbie"},
    {id: "CS", label: "Serbie-et-Monténégro"},
    {id: "SC", label: "Seychelles"},
    {id: "SL", label: "Sierra Leone"},
    {id: "SG", label: "Singapour"},
    {id: "SK", label: "Slovaquie"},
    {id: "SI", label: "Slovénie"},
    {id: "SO", label: "Somalie"},
    {id: "SD", label: "Soudan"},
    {id: "LK", label: "Sri Lanka"},
    {id: "SE", label: "Suède"},
    {id: "CH", label: "Suisse"},
    {id: "SR", label: "Surilabel"},
    {id: "SJ", label: "Svalbard et Île Jan Mayen"},
    {id: "SZ", label: "Swaziland"},
    {id: "SY", label: "Syrie"},
    {id: "TJ", label: "Tadjikistan"},
    {id: "TW", label: "Taïwan"},
    {id: "TZ", label: "Tanzanie"},
    {id: "TD", label: "Tchad"},
    {id: "TF", label: "Terres australes françaises"},
    {id: "IO", label: "Territoire britannique de l'océan Indien"},
    {id: "PS", label: "Territoire palestinien"},
    {id: "TH", label: "Thaïlande"},
    {id: "TL", label: "Timor oriental"},
    {id: "TG", label: "Togo"},
    {id: "TK", label: "Tokelau"},
    {id: "TO", label: "Tonga"},
    {id: "TT", label: "Trinité-et-Tobago"},
    {id: "TA", label: "Tristan da Cunha"},
    {id: "TN", label: "Tunisie"},
    {id: "TM", label: "Turkménistan"},
    {id: "TR", label: "Turquie"},
    {id: "TV", label: "Tuvalu"},
    {id: "UA", label: "Ukraine"},
    {id: "EU", label: "Union européenne"},
    {id: "UY", label: "Uruguay"},
    {id: "VU", label: "Vanuatu"},
    {id: "VE", label: "Venezuela"},
    {id: "VN", label: "Viêt Nam"},
    {id: "WF", label: "Wallis-et-Futuna"},
    {id: "YE", label: "Yémen"},
    {id: "ZM", label: "Zambie"},
    {id: "ZW", label: "Zimbabwe"}
  ]