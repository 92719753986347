import { Component } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FormSectionTitle from '../../components/formSectionTitle';

import apiPath from "../../constants/apiPaths";
import { roundNumber } from "../../helpers/roundNumberFunc";

class EditUserTransactionsView extends Component{
  constructor(props){
    super(props)
    this.state = {
      token : props.token,
      payments : []
    }
  }



  componentDidMount(){

    const { id } = this.props.params;
    const headers = {headers: {"x-auth-token": this.props.token}};

    axios.get(`${apiPath}payment/mollie/user/${id}`, headers)
    .then(res => {
      var pays = res.data.filter(pay=>pay.status !== "open")
      console.log(pays)
     

      axios.get(`${apiPath}withdraw/${id}`, headers)
      .then(res=>{
        // console.log(res.data)
        var withdraws = res.data
        let operations = pays.concat(withdraws)
        // console.log(operations)

        this.setState({
          payments : operations.sort((a,b)=>(
            new Date(b.date) - new Date(a.date) 
         )) ,
        })
      })
      .catch((err)=>{
        console.log(err)
      })

    


    })
    .catch(err => console.err(err));

  }
 

  render(){
   
    // console.log(this.state.payments)
    return(

      <div className="dashboard-container ">
        <div className="dashboard-scontainer ">
        <FormSectionTitle
              title="Toutes les transactions financières de l'utilisateur"
              classes="-simple-3 "
            />
           
        <div className="security-form-container">
        <div className="_transac_container">
          <div className="_transac_header">
            
            <span>TYPE D'OPERATION</span>
            <span>DATE</span>
            <span>MONTANT </span>
            <span>FOND </span>
            <span>STATUT</span>
            <span>INFORMATION</span>
          </div>

          <ul className="_transac_list">
            {this.state.payments.map((operation)=>{
              return <li key={operation.id} className="_transaction">
                <div data-label="OPERATION :">{operation.facture ? "Retrait de compte":"Dépôt"}</div>
                <div data-label="DATE :">{ new Date (operation.date).toLocaleDateString()}</div>
                <div data-label="MONTANT :">{`${operation.facture ? roundNumber(operation.amount).toLocaleString('en-EU').replaceAll(',', ' ') : roundNumber(operation.amount.value).toLocaleString('en-EU').replaceAll(',', ' ')} €`}</div>
                <div data-label="MONTANT :">{operation.fond.name}</div>
                <div data-label="STATUS : "  
                className={(operation.status === "paid" || operation.status === "processed")  ? "-succes" : operation.status === "open" ? "-waiting" : "-failed"}>
                {operation.status === "paid" ||operation.status === "processed" ? "Validé":operation.status === "open" ? "En cours " : "Refusé"}
                </div>
                 <div data-label="INFORMATION :">{operation.status === "processed" ? "Votre retrait a été validé" : (operation.facture && operation.status === "open") ? "Votre demande  de retrait a été envoyé" : operation.status === "paid" ?"Votre paiement a été validé" : operation.status==="open" ? "Votre paiement a bien  été pris en compte" : (operation.fature && operation.status !== "open")||operation.status !== "processed" ? "votre retrait a été refusé" : "Votre paiement va été refusé" }</div>  
                 </li>
            })}
          </ul>
      </div>
    </div>         
        </div>
      </div>
    )
  }
}

// Utilisé pour accéder aux hooks de react-rooter-v6 dans un composant type classe
export default (props) => {
  const params = useParams();
  const { token } = useSelector(state => state.persistedReducer.user);
  return <EditUserTransactionsView params={params} token={token} {...props} />
}