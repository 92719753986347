import React, { Component } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";


import FormRowCheckbox from "../../components/formRowCheckbox";
import InputText from "../../components/inputText";

import Button from "../../components/button";


import apiPath from "../../constants/apiPaths";


class EditUserInvestorProfileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: props.token,
      userToEdit: {},
      contactToEdit: [],
      curEmail: "",
      birthday: "",
      contactAdd: false,
      hadContact: false,
      investQuizz: [],
      quizz:[],
      total:false,
      currentStep:0
    };
  }

  componentDidMount() {

    const { id } = this.props.params;
    const headers = {headers: {"x-auth-token": this.props.token}};

    axios.get(`${apiPath}users/${id}`, headers)
    .then(res => {
      const user = res.data;
      // console.log(user)
      this.setState({
        userToEdit: user,
        investQuizz: user.investQuizz,
        total: user.note,
        quizz:user.quizz,
        contactToEdit: user.personeToContact && user.personeToContact !== "" ? user.personeToContact[0] : false,
        contactAdd: user.personeToContact && user.personeToContact.length > 0 ? true : false,
        hadContact: user.personeToContact && user.personeToContact.length > 0 ? true : false,
        curEmail: user.email,
        // birthday: user.dateOfBirth ? this.generateDate(user.dateOfBirth) : this.generateDate(),
        bodyToSend: {},
      })
    })
    .catch(err => console.err(err));
  }
  // findCurrentStep = () => {
  //    return 
  //    };
  
  incrementStep = () => {
    let step = this.state.currentStep;
    if (step < this.state.investQuizz.length - 1) {
      step++;
      this.setState({ currentStep: step });
     
    }
    // console.log(this.state.currentStep)
  };
  decrementStep = () => {
    let step = this.state.currentStep;
    if (step >= 1) {
      step--;
      this.setState({ currentStep: step });
      }
  };
  render() {
    
    // const step = this.state.investQuizz.find((step)=> step.id === this.state.currentStep)
    // console.log(this.state.quizz)
    
    
    return (
      
      <div className="dashboard-container">
       {this.state.quizz === null && this.state.investQuizz === null  ? 
        <div className="inv-container dashboard-scontainer">
          <div className="section-title">
              <h4 className="-texte">Informations</h4>
          </div>
          <div className="form-container" >
          <p className="sub-title">Questionnaires non completés par l'utilisateur</p>
          </div>
        </div>
       : 
        <div className="inv-container dashboard-scontainer">
        <div className="section-title">
              <h4 className="-texte">Le quizz de prémière connexion</h4>
        </div>
        <div className="form-container" >
            <p className="sub-title">Questions/Reponses</p>
            {this.state.quizz.map((question)=> (
              1<=question.id<=5 && question.options ?
              <InputText
              label={question.question}
              value={question.value}
              />
              :
              1<=question.id<=5 && question.choices ?
              <>
              <p className="inv-title label-checkbox">{question.question}</p>

            <div >{question.choices.map((choice,index)=>
              <FormRowCheckbox
              label={choice}
              isActive={question.value.includes(choice)} />
              )}
            </div> 
              </>
              :
              ""
            ))}
        </div>
        </div>}
        {this.state.investQuizz !== null  && <div className="inv-container dashboard-scontainer">
        <div className="section-title">
              <h4 className="-texte">Questionnaire d'investissement</h4>
            </div>
            
            <div className="section-profile">
              <p className="sub-title">Profil déterminé par le questionnaire</p>
              <h1 className="-profil">
                {
                this.state.total <= 5 ? "Défensif"
                : 
                this.state.total<=10 && this.state.total >= 6 ? "Modéré" 
                : 
                "Agressif"
                }  
              </h1>
            </div>

            <div className="form-container">
              <p className="sub-title">Questions/Reponses</p>
              {this.state.investQuizz.map((o)=>o.id === this.state.currentStep && 
              o.questions.map((i)=> 
              (i.select &&
              <InputText
              label={i.label}
              value={i.value}/> )||

              (i.input &&<InputText
              label={i.label}
              value={i.value}/> )||

              (i.select_if &&<InputText
              label={i.label}
              value={i.value}/> ) ||

              (i.select_point &&<InputText
              label={i.label}
              value={i.value}/> )||
              (i.radio && 
              <div>
              <p className="inv-title">{i.par_label}</p>
              {<p className="inv-title">{i.label}</p>}
              {i.choices.map((choice)=>
              <>
              <input className="inv-radio"type="radio" key={choice.id} id={choice.id} name={i.label} checked={choice.status} value={choice.label} />
              {choice.label}
              </>)}
              {i.status && <InputText
              label={i.sublabel}
              value={i.value}/>
              }
              </div>) ||
              (i.radio_if &&  
              <>
              {<p className="inv-title">{i.label}</p>}
              {i.choices.map((choice)=>
              <>
              <input className="inv-radio"type="radio" key={choice.id} id={choice.id} name={i.label} checked={i.value.includes(choice.label)} value={choice.label} />
              {choice.label}
              </>)}
              </>) ||
              (i.checkbox && 
              <>
              <p className="inv-title label-checkbox">{i.label}</p>
              <div className="inv-checkbox">{i.choices.map((value,index)=>
              <div key={index}>
              <FormRowCheckbox
              label={value}
              isActive={i.value.includes(value)}
              /></div>)
              }

              {i.value.includes("Autres")&&
              <InputText
              label={"Précisez"}
              value={i.subvalue}/>
              }
              
              </div>
              </>) || 
            (i.checkbox_fin && 
              <>
              <p className="inv-title label-checkbox">{i.label}</p>
              <div className="inv-checkbox">{i.choices.map((value,index)=>
              <div key={index}>
              <FormRowCheckbox
              label={value}
              isActive={i.value.includes(value)}
              /></div>)
              }
              {i.value.includes("Investissement autre (noter si autre)")&&
              <InputText
              label={"Précisez"}
              value={i.subvalue}/>
              }</div>
              </>)||
              (i.checkbox_immo && 
                <>
                <p className="inv-title label-checkbox">{i.label}</p>
                <div className="inv-checkbox">{i.choices.map((value,index)=>
                <div key={index}>
                <FormRowCheckbox
                label={value}
                isActive={i.value.includes(value)}
                /></div>)
                }</div>
                </>)
              )

              )}
              <div className={`form-footer ${this.state.currentStep < 1 ? "-first-step" : ""}`}>
              <Button
                isLoading={false}
                label={"Precedent"}
                classes="btn -left -grey"
                icon
                type="button"
                handleClick={this.decrementStep}/>
                {this.state.currentStep < this.state.investQuizz.length -1 &&
                <Button
                  isLoading={false}
                  label={"Suivant"}
                  classes="button"
                  icon
                  type="button"
                  // handleClick={this.decrementStep}
                  handleClick={this.incrementStep}
                />}
               </div>
            </div>
        </div>}
      </div>
    )
  }

}
export default (props) => {
  const params = useParams();
  const { token } = useSelector(state => state.persistedReducer.user);
  return <EditUserInvestorProfileView params={params} token={token} {...props} />
}