import React, { Component } from 'react'
import axios from 'axios'
import apiPath from '../constants/apiPaths';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from 'react';
import { useState } from 'react';
import FormSectionTitle from '../components/formSectionTitle';
import { Link } from 'react-router-dom';
import IconArrowColored from '../assets/svg/navbar-arrow-colored';
import { roundNumber } from '../helpers/roundNumberFunc';

const InvestementsListView = ()=>{

  const { user} = useSelector(state => state.persistedReducer);
  const headers = {
    headers: {
      "x-auth-token" : user.token,
    }
  }

  useEffect(()=>{
    axios.get(`${apiPath}investment/`,headers)
    .then((res)=>{
      console.log(res.data)
       setInvestments(res.data)
       axios.get(`${apiPath}fonds/`,headers)
      .then((res)=>{
        // console.log(res.data)
        setFond(res.data)
        axios.get(`${apiPath}users/`, headers)
        .then((res) => {
          setUsers(res.data)
        })
        .catch((err)=>{
         console.log(err)
       })
      })
      .catch((err)=>{
        console.log(err)
      })
     
    })
    .catch((err)=>{
      console.log(err)
    })
  },[])

  const [investments, setInvestments] = useState(null)
  const [users, setUsers] = useState([])
  const [fonds, setFond] = useState([])



  return (
    <>
    <div className="_listUsersContainer">
        <div className="_listUsers">
          
        <FormSectionTitle
            title="liste des investissements"
            classes="-simple-2 _users_title"
          />
          <div className="_users_container ">

          <div className=" -invest">
                  <span>UTILISATEUR</span>
                  <span>MONTANT</span>
                  <span>FOND</span>
                  <span>DATE</span>
                  <span>STATUS</span>
            </div>
             
      <ul className="_users_list">
      { investments && investments.map(investment=>{
          return (
            <><li key={investment.id} className=" -investement">
              
              <div data-label="UTILISATEUR :">
              {users.map((user)=>user._id === investment.user ? user.firstname + " " + user.lastname :"") }
              </div>
              <div data-label="MONTANT :">{roundNumber(investment.montant).toLocaleString('en-EU').replaceAll(',', ' ')}€</div>
              <div data-label="FOND :">{fonds.map((fond)=>fond._id === investment.fond && fond.name )}</div>
              <div data-label="DATE :">{investment.datePaiement}</div>
              <div className={investment.status === "traited" ? "-succes": "-waiting"} data-label="STATUS :">
                {investment.status === "traited" ? "validé": "ouvert"}
              </div>
              <Link className="_icon"
                to={`/dashboard/investment/${investment._id}`}
               >
                <IconArrowColored/>
                <p>Voir la fiche</p>
              </Link>

              </li>
              
              </>
            
          ) 
        })
      }
      </ul>
      </div>
        </div>
    </div>
    </> 
  )
}

export default InvestementsListView