export default () => (
  <svg viewBox="0 0 32 27">
      <g id="-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Inscription2-particulier1" transform="translate(-247.000000, -404.000000)">
              <g id="Group-2" transform="translate(247.000000, 404.000000)">
                  <g id="ic_Edit" transform="translate(9.039900, 3.641389)" fill="#3DD598">
                      <path d="M4,0 L13.5316251,0 C14.1251847,0 14.68808,0.26364602 15.0680676,0.719631201 L19.5364426,6.08168114 C19.8359676,6.44111114 20,6.89417693 20,7.36204994 L20,18 C20,20.209139 18.209139,22 16,22 L4,22 C1.790861,22 0,20.209139 0,18 L0,4 C0,1.790861 1.790861,0 4,0 Z M18,18 L18,8 L15,8 C13.8954305,8 13,7.1045695 13,6 L13,2 L4,2 C2.8954305,2 2,2.8954305 2,4 L2,18 C2,19.1045695 2.8954305,20 4,20 L16,20 C17.1045695,20 18,19.1045695 18,18 Z M16.8649584,6 L15,3.76204994 L15,6 L16.8649584,6 Z M13.75,15 C14.4403559,15 15,15.4477153 15,16 C15,16.5522847 14.4403559,17 13.75,17 L6.25,17 C5.55964406,17 5,16.5522847 5,16 C5,15.4477153 5.55964406,15 6.25,15 L13.75,15 Z M8.28814048,8.94576857 C10.1041303,8.94576857 11.576281,10.4179192 11.576281,12.233909 C11.576281,12.875304 10.7559206,12.9552314 9.69469109,12.9632062 L8.817397,12.9634746 C8.69656838,12.9638962 8.57476059,12.9649479 8.45264949,12.9672006 L8.45264949,12.9672006 L8.08876357,12.9756316 C6.51376921,13.0176478 5,13.1051797 5,12.233909 C5,10.4179192 6.47215064,8.94576857 8.28814048,8.94576857 Z M8.28814048,5 C9.37773438,5 10.2610248,5.88329038 10.2610248,6.97288429 C10.2610248,8.06247819 9.37773438,8.94576857 8.28814048,8.94576857 C7.19854657,8.94576857 6.31525619,8.06247819 6.31525619,6.97288429 C6.31525619,5.88329038 7.19854657,5 8.28814048,5 Z" id="Shape"></path>
                  </g>
                  <g id="Group">
                      <circle id="Oval" fill="#3DD598" cx="7" cy="7" r="7"></circle>
                      <g id="ic_Edit" transform="translate(3.666667, 4.499993)" fill="#FFFFFF">
                          <path d="M0.709814703,1.65500845 C0.546283049,1.49310728 0.282467619,1.49442905 0.120566447,1.6579607 C-0.0413347243,1.82149236 -0.0400129573,2.08530779 0.123518696,2.24720896 L2.55074658,4.65023631 C2.72210649,4.81988769 3.00125895,4.80910131 3.1590169,4.62673289 L6.56512235,0.689270203 C6.71567343,0.51523295 6.69663412,0.252102122 6.52259687,0.101551048 C6.34855961,-0.0490000259 6.08542878,-0.029960716 5.93487771,0.144076537 L2.82033796,3.74448887 L0.709814703,1.65500845 Z" id="Shape"></path>
                      </g>
                  </g>
              </g>
          </g>
      </g>
  </svg>
)