import React, { Component } from 'react';
import {connect} from 'react-redux';
import { setUser, setToken } from '../redux/store';

class DashboardView extends Component {
 
  render() { 
    return (
      <div>
        <p>
          Veuillez utiliser le menu sur la gauche pour choisir une action
          ...{" "}
          </p> 
      </div>
    );
  }
}
 
const mapStateToProps = (state) => ({
  // user: state.user.user,
  // token: state.user.token,
  user: state.persistedReducer.user.user,
  token: state.persistedReducer.user.token,

  // userToEdit : state.userToEdit
});

const actions = {
  setUser,
  setToken,
  // setUserToEdit,
}

export default connect(mapStateToProps, actions)(DashboardView);