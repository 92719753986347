import React, {useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import apiPath from '../../constants/apiPaths';
import FormRow from '../../components/formRow';
import InputText from '../../components/inputText';
import Button from '../../components/button';
import { setFeedback } from '../../redux/store';





const ContactSocialNetwork = () => {

  const dispatch = useDispatch();
  const { id } = useParams()

  const { user } = useSelector((state) => state.persistedReducer);
  const headers = {
    headers: {
      "x-auth-token": user.token,
    },
  };

  const [facebook, setFacebook] = useState('')
  const [linkedin, setLinkedin] = useState('')
  const [telegram, setTelegram] = useState('')
  const [instagram, setInstagram] = useState('')
  const [tiktok, setTiktok] = useState('')





  useEffect(() => {
    axios.get(`${apiPath}prospect/${id}`, headers).
      then(res => {
        setFacebook(res.data.social_network.facebook)
        console.log(res.data.social_network.facebook)
        setInstagram(res.data.social_network.instagram)
        setLinkedin(res.data.social_network.linkedin)
        setTiktok(res.data.social_network.tiktok)
        setTelegram(res.data.social_network.telegram)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  const handleFacebookCHange = (e) => {
    setFacebook(e.target.value)
  }
  const handleInstagramCHange = (e) => {
    setInstagram(e.target.value)
  }
  const handleTiktokCHange = (e) => {
    setTiktok(e.target.value)
  }
  const handleTelegramCHange = (e) => {
    setTelegram(e.target.value)
  }
  const handleLinkedinCHange = (e) => {
    setLinkedin(e.target.value)
  }

  const HandleSocialNetworkSubmit = (e) => {
    e.preventDefault()
    const body = {
      social_network: {
        facebook: facebook ? facebook : "",
        instagram: instagram ? instagram : "",
        tiktok: tiktok ? tiktok : "",
        telegram: telegram ? telegram : "",
        linkedin: linkedin ? linkedin : "",
      }
    }
    axios.patch(`${apiPath}prospect/socialNetwork/${id}`, body, headers)
    .then((response) => {
        dispatch(setFeedback({ show: true, type: 'success', title: `Confirmation des modifications`, message: `Vos données ont bien été mises à jour` }));
      console.log(response);
      setTimeout(() => {
        dispatch(setFeedback({ show: false }));
      }, 2000);
    }
      )
      .catch((error) => {
        console.log(error);
        dispatch(setFeedback({ show: true, type: 'error', title: `Error`, message: `Message d'erreur` }));
        setTimeout(() => {
          dispatch(setFeedback({ show: false }));
        }, 2000);

      }
      )
  }

  return (
    <div>
      <div className="layout-AA-container">
        <div className="-add-contact-container">
          {/* <div className="contact-info-container -apporAf">
            <div className="-title">
              <h4 className="-texte">{texts[lang].infoPrealableContactForm}</h4>
            </div>
            <div className="-information">

            <InfosBar
              classes="-error"
              content={
                texts[lang].infoFormContactContentOne
              }
            />
            <InfosBar
              classes="-info"
              content={
                texts[lang].infoFormContactContentTwo
              }
            />
            </div>
          </div> */}

          <form onSubmit={HandleSocialNetworkSubmit}>

            <div className="contact-info-container -apporAf">
              <div className="-title">
                <h4 className="-texte">{"Réseaux sociaux"}</h4>
              </div>

              <div className='contact-scontainer'>

                <FormRow classes={"-two"}>
                  <InputText
                  required = {false}
                    classes={"-two"}
                    label={"Facebook"}
                    value={facebook}
                    handleInputChange={(e) => handleFacebookCHange(e)}
                  />
                  <InputText
                  required = {false}
                    classes={"-two"}
                    label={"Instagram"}
                    value={instagram}
                    handleInputChange={(e) => handleInstagramCHange(e)}
                  />
                </FormRow>
                <FormRow classes={"-two"}>
                  <InputText
                  required = {false}
                    classes={"-two"}
                    label={"Tiktok"}
                    value={tiktok}
                    handleInputChange={(e) => handleTiktokCHange(e)}
                  />
                  <InputText
                  required = {false}
                    classes={"-two"}
                    label={"Telegram"}
                    value={telegram}
                    handleInputChange={(e) => handleTelegramCHange(e)}

                  />
                </FormRow>
                <FormRow classes={"-one"}>
                  <InputText
                  required = {false}
                    classes={"-two"}
                    label={"Linkedin"}
                    value={linkedin}
                    handleInputChange={(e) => handleLinkedinCHange(e)}
                  />

                </FormRow>


              </div>
            </div>
            <div className="-add-contact-button">
              <Button
                classes={"-add-contact"}
                label={"Confirmer les modifications"}
                type="submit"
              />
            </div>        
          </form>
        </div>
      </div>       
     </div>
  );
};

export default ContactSocialNetwork;    