import { Component } from "react";
import axios from "axios";
import QRCodeRender from "../../components/qrcode"

import apiPath from "../../constants/apiPaths";

import Button from "../../components/button";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

class EditUserSecurityView extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      token: props.token,
      userToEdit: {},
      curEmail: "",
      hasGoogleAuth: false,
      hasChangedGoogleAuth: false,
    }
  }

  componentDidMount() {

    const { id } = this.props.params;
    const headers = {headers: {"x-auth-token": this.props.token}};

    axios.get(`${apiPath}users/${id}`, headers)
    .then(res => {
      const user = res.data;
      this.setState({
        userToEdit: user,
        curEmail: user.email,
        hasGoogleAuth: user.has_google_auth,
      })
    })
    .catch(err => console.err(err));

  }

  handlePasswordReset = (e) => {
    this.setState({ wantResetPassword: true })
  }

  handlePasswordResetConfirm = (e) => {
    this.setState({ wantResetPassword: false })
    const headers = {
      headers: {
        "x-auth-token": this.state.token,
      }
    }
    axios.put(`${apiPath}resetPassword/email/${this.state.userToEdit.email}`, null, headers)
      .then(res => {
        console.log(res)
        this.setState({ message: "un email de confirmation a été envoyer a l'utilisateur." })
        this.setState({ wantResetPassword: false })
      })
      .catch(err => { console.log(err) })
  }

  handlePasswordResetAbort = (e) => {
    this.setState({ wantResetPassword: false })
  }

  handleGoogleAuthChange = (e) => {
    this.setState({ hasGoogleAuth: e.target.checked })
    this.setState({ hasChangedGoogleAuth: this.state.hasChangedGoogleAuth?false:true })
  }

  handleGoogleAuthChangeConfirm = (e) => {
    console.log(this.state.userToEdit.id)
    this.setState({ hasChangedGoogleAuth: false })
    const headers = {headers: {"x-auth-token": this.state.token}}

    if (this.state.hasGoogleAuth === false) {
      axios.patch(`${apiPath}users/${this.state.userToEdit.id}`, { has_google_auth: false }, headers)
        .then(res => {
          console.log(res)
        }).catch(err => { console.log(err) })
    }
    if (this.state.hasGoogleAuth === true) {
      axios.patch(`${apiPath}users/${this.state.userToEdit.id}`, { has_google_auth: true }, headers)
        .then(res => {
          console.log(res)
          axios.get(`${apiPath}users/${this.state.userToEdit.id}`, headers)
            .then(res => {
              this.setState({ userToEdit: { ...this.state.userToEdit, hasGoogleAuth: true } })
              this.setState({ userToEdit: { ...this.state.userToEdit, google_auth_key: res.data.google_auth_key } })
            }).catch(err => { console.log(err) })
        }).catch(err => { console.log(err) })
    }
  }

  handleGoogleAuthChangeAbort = (e) => {
    this.setState({ hasChangedGoogleAuth: false })
    this.setState({hasGoogleAuth: !this.state.hasGoogleAuth})
  }

  render() {

    return (
      <div className="dashboard-container">

        <div className="dashboard-scontainer">
          <div className="section-title">
            <h4 className="-texte">Google Authenticator</h4>
          </div>
          <input
            style={{ "marginRight": "20px" }}
            type="checkbox"
            checked={this.state.hasGoogleAuth}
            onChange={this.handleGoogleAuthChange}
          />
          <span>Activer Google Authenticator</span>
          <div style={{ "display": "flex" }}>
            {
              this.state.hasGoogleAuth === true && this.state.hasChangedGoogleAuth === false
              && <QRCodeRender keyProp={this.state.userToEdit.google_auth_key} email={this.state.userToEdit.email} />  
            }
          </div>
          {
            this.state.hasChangedGoogleAuth
            && <span>Voulez-vous confirmer cette action ?</span>
          }
          {
            this.state.hasChangedGoogleAuth
            &&  <div style={{display:"inline-flex"}}>
                  <Button type="button" label="Oui" classes="-small -sub" style={{marginLeft:"30px"}} handleClick={this.handleGoogleAuthChangeConfirm} />
                  <Button type="button" label="Non" classes="-small -sub -grey" handleClick={this.handleGoogleAuthChangeAbort} />
                </div>
          }
        </div>

        <div className="dashboard-scontainer">

          <div className="section-title">
            <h4 className="-texte">Mot de passe</h4>
          </div>
          {
            this.state.message
            && <span>{this.state.message}</span>
          }
          <Button type="button" label="Réinitialiser le mot de passe" classes="-small" handleClick={this.handlePasswordReset} />
          {
            this.state.wantResetPassword === true
            && <span>Confirmer la Réinitialisation du mot de passe ? </span>
          }
          {
            this.state.wantResetPassword === true
            && <div style={{display:"inline-flex"}}>
                <Button type="button" label="Oui" style={{marginLeft:"30px"}} classes="-small -sub" handleClick={this.handlePasswordResetConfirm} />
                <Button type="button" label="Non" classes="-small -sub -grey" handleClick={this.handlePasswordResetAbort} />
              </div>
          }
          
        </div>

      </div>
    )
  }
}

// Utilisé pour accéder aux hooks de react-rooter-v6 dans un composant type classe
export default (props) => {
  const params = useParams();
  const { token } = useSelector(state => state.persistedReducer.user);
  return <EditUserSecurityView params={params} token={token} {...props} />
}